import '../../styles/project/projects.css'
import anime from 'animejs'
import Projectlist_card from './projectlist_card'
import ProjectList from './projectList'
import axiosInstance from '../../services/axiosInstance'
import { useEffect, useState } from 'react'
import { forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TestomonialAndContact from '../homepage/section8'
import Footer from '../../components/footer/footer'
import { changeLoaderState } from '../../actions/lngactions'
import { devapiUrl } from '../../constants/devapi'
import { getLanguage } from '../../constants/urls'
import { langCodes } from '../../constants/country'
import Notfound from '../NotFound/Notfound'

const Projects = forwardRef((props, ref) => {
  let bool = false
  const projectdata = useSelector((state) => state)
  const dispatch = useDispatch()
  const langcode = projectdata.lang
  const [data, Setdata] = useState([])
  const [isNotFound, setIsNotFound] = useState(false)

  const animation = () => {
    anime.timeline({ loop: false }).add({
      targets: '.projectlist-sec-1-wrap',
      translateY: [100, 0],
      easing: 'easeOutExpo',
      duration: 5400,
      delay: 100,
    })

    anime.timeline({ loop: false }).add({
      targets: '.projectlist-sec-1-heading span',
      translateY: [200, 0],
      easing: 'easeOutExpo',
      duration: 5400,
    })
  }
  useEffect(() => {
    dispatch(changeLoaderState(true))

    let param = window.location.href.split('/')
    let url = param[param.length - 1]

    if (url === 'projects') document.title = 'SIDE - Projects'
    if (ref != null) ref.current = 1
    window.scrollTo({ top: 0, left: 0 })
  }, [])

  useEffect(() => {
    getprojectdata(getLanguage())
  }, [langcode])
  const getprojectdata = (lang) => {
    axiosInstance.get(`${devapiUrl}${lang}/side-pages/projects`).then((res) => {
      Setdata(res.data.projects)
      dispatch(changeLoaderState(false))
      if (bool === false) animation()
      bool = true
    })
  }
  useEffect(() => {
    let param = window.location.href.split('/')
    if (param.length === 5) {
      if (!langCodes.includes(param[3]) && param[3] !== '') {
        setIsNotFound(true)
      } else {
        setIsNotFound(false)
      }
    }
  }, [])
  // if (isNotFound) {
  //   return <Notfound />
  // }

  return (
    <>
      {
        <div className="projects-list-page">
          <span className="leftshadow"></span>
          <section className="projectlist-sec-1">
          <div className="banner_leftborder border_lines"></div>
            <div className="project-heading-container container">
          
           
              <div className="projectlist-sec-1-heading first">
                <span className=".my-text firstline">work</span>
                &nbsp;
                <span className=".my-text firstline mob_display">that</span>
                <div className="projectlist-sec-1-subheading desc_display">
                  <div className="projectlist-sec-1-wrap">
                    <div style={{ overflow: 'hidden' }}>
                      <span className="line"></span>
                    </div>

                    <p>
                      {data.project_static_content_topsection
                        ? data.project_static_content_topsection
                            .project_static_content_sub_heading
                        : 'Every genre, every platform, every language. Your vision, brought to life.'}
                    </p>
                  </div>
                </div>
              </div>

              <div className="projectlist-sec-1-heading desk_display">
                <span className=".my-text secondline">
                  {data.project_static_content_topsection
                    ? data.project_static_content_topsection
                        .project_static_content_heading.that_speaks
                    : 'that speaks'}
                </span>
              </div>
              <div className="projectlist-sec-1-heading mob_display">
                <span className=".my-text secondline">speak for</span>
              </div>

              <div className="projectlist-sec-1-heading last desk_display">
                <span className=".my-text lastline">
                  {data.project_static_content_topsection
                    ? data.project_static_content_topsection
                        .project_static_content_heading
                        .project_static_content_for_itself
                    : 'for itself'}
                </span>
              </div>

              <div className="projectlist-sec-1-heading last mob_display">
                <span className=".my-text lastline">itself</span>
              </div>
              <div className="projectlist-sec-1-subheading mbl_display">
                <div className="projectlist-sec-1-wrap">
                  <span className="line"></span>

                  <p>
                    {data.project_static_content_topsection
                      ? data.project_static_content_topsection
                          .project_static_content_sub_heading
                      : 'Every genre, every platform, every language. Your vision, brought to life.'}
                  </p>
                </div>
              </div>
            </div>
            {/* Don't remove the comment or clean it based on marketing team feedback commented it */}
            {/* <div className="trial-1">
              <img
                src={
                  data.project_static_content_topsection
                    ? data.project_static_content_topsection
                        .project_left_side_image
                    : '/temp_img/logo-ptw-side.svg'
                }
                alt=""
              />
            </div> */}
            {/* <div className="trial-2">
              <img
                src={
                  data.project_static_content_topsection
                    ? data.project_static_content_topsection
                        .project_right_side_image
                    : '/temp_img/logo-ptw-side-right.svg'
                }
                alt=""
              />
            </div> */}
          </section>
          <div className='feature_sideline2 d-lg-none'>
              <div className="sidelinecontent-2">
                <span className="filldot-1"></span>
                <p>
                  01{' '}
                  {data.project_static_content_topsection
                    ? data.project_static_content_topsection
                        .project_static_content_featured_projects
                    : 'FEATURED PROJECTS'}
                </p>
                <span className="line-1"></span>
                <span className="outlinedot-1"></span>
                {/* <span className="outlinedot-1"></span>  */}
              </div>
              </div>
          {<ProjectList data={data} />}
          <Projectlist_card data={data} />
          <TestomonialAndContact />
          <Footer />
        </div>
      }
    </>
  )
})

export default Projects
