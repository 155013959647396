import { useEffect, useState } from 'react'
import { clientUrl, getLanguage } from '../../constants/urls'
import { useSelector } from 'react-redux'
import { devapiUrl } from '../../constants/devapi'
import axiosInstance from '../../services/axiosInstance'

let language = getLanguage()

const Footer = () => {
  let langcode = useSelector((state) => state.lang)
  const [footerData, setFooterData] = useState(null)
  useEffect(() => {
    language = getLanguage()
    getfooterdata(language)
  }, [langcode])

  const getfooterdata = (lang) => {
    axiosInstance
      .get(`${devapiUrl}${lang}/side-pages/footer`)
      .then((res) => {
        setFooterData(res.data.footer)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div className="footer">
      <div className="sideicon left">
        <img src="/audio/img/side_logo.svg" alt="" />
      </div>
      <div className="sideicon right">
        <img src="/audio/img/side_logo.svg" alt="" />
      </div>
      <div className="footerlinks">
        <div className="leftpart">
          <label htmlFor="" className="">
            {footerData ? footerData.footer_connect : 'connect'}
          </label>
          <div style={{ display: 'flex', gap: '1rem' }} className="links">
            <h6>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  footerData
                    ? footerData.footer_social_media_links.footer_x_link
                    : 'https://twitter.com/SideGlobal'
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40"
                  height="40"
                  viewBox="0 0 50 50"
                  style={{ fill: '#FFFFFF' }}
                >
                  <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
                </svg>

                {/* {footdata ? footdata.footer_social_media_links.footer_x : 'X'} */}
              </a>
            </h6>
            <h6 style={{ marginTop: '0.1rem' }}>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  footerData
                    ? footerData.footer_social_media_links.footer_instagram_link
                    : 'https://www.instagram.com/sideglobal/'
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="37"
                  style={{ fill: '#FFFFFF' }}
                  height="37"
                  viewBox="0 0 26 26"
                >
                  <path d="M 7.546875 0 C 3.390625 0 0 3.390625 0 7.546875 L 0 18.453125 C 0 22.609375 3.390625 26 7.546875 26 L 18.453125 26 C 22.609375 26 26 22.609375 26 18.453125 L 26 7.546875 C 26 3.390625 22.609375 0 18.453125 0 Z M 7.546875 2 L 18.453125 2 C 21.527344 2 24 4.46875 24 7.546875 L 24 18.453125 C 24 21.527344 21.53125 24 18.453125 24 L 7.546875 24 C 4.472656 24 2 21.53125 2 18.453125 L 2 7.546875 C 2 4.472656 4.46875 2 7.546875 2 Z M 20.5 4 C 19.671875 4 19 4.671875 19 5.5 C 19 6.328125 19.671875 7 20.5 7 C 21.328125 7 22 6.328125 22 5.5 C 22 4.671875 21.328125 4 20.5 4 Z M 13 6 C 9.144531 6 6 9.144531 6 13 C 6 16.855469 9.144531 20 13 20 C 16.855469 20 20 16.855469 20 13 C 20 9.144531 16.855469 6 13 6 Z M 13 8 C 15.773438 8 18 10.226563 18 13 C 18 15.773438 15.773438 18 13 18 C 10.226563 18 8 15.773438 8 13 C 8 10.226563 10.226563 8 13 8 Z"></path>
                </svg>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="47"
                  height="47"
                  style={{ fill: '#FFFFFF' }}
                  viewBox="0 0 48 48"
                >
                  <path d="M 16.5 5 C 10.159 5 5 10.159 5 16.5 L 5 31.5 C 5 37.841 10.159 43 16.5 43 L 31.5 43 C 37.841 43 43 37.841 43 31.5 L 43 16.5 C 43 10.159 37.841 5 31.5 5 L 16.5 5 z M 34 12 C 35.105 12 36 12.895 36 14 C 36 15.104 35.105 16 34 16 C 32.895 16 32 15.104 32 14 C 32 12.895 32.895 12 34 12 z M 24 14 C 29.514 14 34 18.486 34 24 C 34 29.514 29.514 34 24 34 C 18.486 34 14 29.514 14 24 C 14 18.486 18.486 14 24 14 z M 24 17 A 7 7 0 1 0 24 31 A 7 7 0 1 0 24 17 z"></path>
                </svg> */}
              </a>
            </h6>
            <h6 style={{ marginTop: '0.15rem' }}>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/side-audio"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="35"
                  height="35"
                  style={{ fill: '#FFFFFF' }}
                  viewBox="0 0 26 26"
                >
                  <path d="M 21.125 0 L 4.875 0 C 2.183594 0 0 2.183594 0 4.875 L 0 21.125 C 0 23.816406 2.183594 26 4.875 26 L 21.125 26 C 23.816406 26 26 23.816406 26 21.125 L 26 4.875 C 26 2.183594 23.816406 0 21.125 0 Z M 8.039063 22.070313 L 4 22.070313 L 3.976563 9.976563 L 8.015625 9.976563 Z M 5.917969 8.394531 L 5.894531 8.394531 C 4.574219 8.394531 3.722656 7.484375 3.722656 6.351563 C 3.722656 5.191406 4.601563 4.3125 5.945313 4.3125 C 7.289063 4.3125 8.113281 5.191406 8.140625 6.351563 C 8.140625 7.484375 7.285156 8.394531 5.917969 8.394531 Z M 22.042969 22.070313 L 17.96875 22.070313 L 17.96875 15.5 C 17.96875 13.910156 17.546875 12.828125 16.125 12.828125 C 15.039063 12.828125 14.453125 13.558594 14.171875 14.265625 C 14.066406 14.519531 14.039063 14.867188 14.039063 15.222656 L 14.039063 22.070313 L 9.945313 22.070313 L 9.921875 9.976563 L 14.015625 9.976563 L 14.039063 11.683594 C 14.5625 10.875 15.433594 9.730469 17.519531 9.730469 C 20.105469 9.730469 22.039063 11.417969 22.039063 15.046875 L 22.039063 22.070313 Z"></path>
                </svg>
              </a>
              {/* <a
                target="_blank"
                rel="noreferrer"
                href={
                  footerData
                    ? footerData.footer_social_media_links.footer_linkedin_link
                    : 'https://www.linkedin.com/company/side-audio'
                }
              >
                {footerData
                  ? footerData.footer_social_media_links.footer_linkedin
                  : 'linkedin'}
              </a> */}
            </h6>
            <h6 style={{ marginTop: '-0.55rem' }}>
              <a target="_blank" href="https://www.youtube.com/@sideglobal2237">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="55"
                  height="57"
                  style={{ fill: '#FFFFFF' }}
                  viewBox="0 0 50 50"
                >
                  <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
                </svg>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40"
                  height="40"
                  style={{ fill: '#FFFFFF' }}
                  viewBox="0 0 50 50"
                >
                  <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
                </svg>{' '} */}
              </a>
              {/* <a target="_blank" href="https://www.youtube.com/@sideglobal2237">
                Youtube
              </a> */}
            </h6>
          </div>

          <div style={{ display: 'none' }} className="mobilelinks links">
            <div className="topLine">
              <h6>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    footerData
                      ? footerData.footer_social_media_links.footer_x_link
                      : 'https://twitter.com/SideGlobal'
                  }
                >
                  {/* {footerData
                    ? footerData.footer_social_media_links.footer_x
                    : 'X'} */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="40"
                    height="40"
                    viewBox="0 0 50 50"
                    style={{ fill: '#FFFFFF' }}
                  >
                    <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
                  </svg>
                </a>
              </h6>
              <h6>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    footerData
                      ? footerData.footer_social_media_links
                          .footer_linkedin_link
                      : 'https://www.linkedin.com/company/side-audio'
                  }
                >
                  {/* {footerData
                    ? footerData.footer_social_media_links.footer_linkedin
                    : 'linkedin'} */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="40"
                    height="40"
                    style={{ fill: '#FFFFFF' }}
                    viewBox="0 0 26 26"
                  >
                    <path d="M 21.125 0 L 4.875 0 C 2.183594 0 0 2.183594 0 4.875 L 0 21.125 C 0 23.816406 2.183594 26 4.875 26 L 21.125 26 C 23.816406 26 26 23.816406 26 21.125 L 26 4.875 C 26 2.183594 23.816406 0 21.125 0 Z M 8.039063 22.070313 L 4 22.070313 L 3.976563 9.976563 L 8.015625 9.976563 Z M 5.917969 8.394531 L 5.894531 8.394531 C 4.574219 8.394531 3.722656 7.484375 3.722656 6.351563 C 3.722656 5.191406 4.601563 4.3125 5.945313 4.3125 C 7.289063 4.3125 8.113281 5.191406 8.140625 6.351563 C 8.140625 7.484375 7.285156 8.394531 5.917969 8.394531 Z M 22.042969 22.070313 L 17.96875 22.070313 L 17.96875 15.5 C 17.96875 13.910156 17.546875 12.828125 16.125 12.828125 C 15.039063 12.828125 14.453125 13.558594 14.171875 14.265625 C 14.066406 14.519531 14.039063 14.867188 14.039063 15.222656 L 14.039063 22.070313 L 9.945313 22.070313 L 9.921875 9.976563 L 14.015625 9.976563 L 14.039063 11.683594 C 14.5625 10.875 15.433594 9.730469 17.519531 9.730469 C 20.105469 9.730469 22.039063 11.417969 22.039063 15.046875 L 22.039063 22.070313 Z"></path>
                  </svg>
                </a>
              </h6>
            </div>
            <div className="topLine">
              <h6>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    footerData
                      ? footerData.footer_social_media_links
                          .footer_instagram_link
                      : 'https://www.instagram.com/sideglobal/'
                  }
                >
                  {/* {footerData
                  ? footerData.footer_social_media_links.footer_instagram
                  : 'instagram'} */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="40"
                    height="40"
                    style={{ fill: '#FFFFFF' }}
                    viewBox="0 0 48 48"
                  >
                    <path d="M 16.5 5 C 10.159 5 5 10.159 5 16.5 L 5 31.5 C 5 37.841 10.159 43 16.5 43 L 31.5 43 C 37.841 43 43 37.841 43 31.5 L 43 16.5 C 43 10.159 37.841 5 31.5 5 L 16.5 5 z M 34 12 C 35.105 12 36 12.895 36 14 C 36 15.104 35.105 16 34 16 C 32.895 16 32 15.104 32 14 C 32 12.895 32.895 12 34 12 z M 24 14 C 29.514 14 34 18.486 34 24 C 34 29.514 29.514 34 24 34 C 18.486 34 14 29.514 14 24 C 14 18.486 18.486 14 24 14 z M 24 17 A 7 7 0 1 0 24 31 A 7 7 0 1 0 24 17 z"></path>
                  </svg>
                </a>
              </h6>
              <h6>
                <a
                  target="_blank"
                  href={
                    footerData
                      ? footerData.footer_social_media_links.footer_youtube_link
                      : 'https://www.youtube.com/@sideglobal2237'
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="40"
                    height="40"
                    style={{ fill: '#FFFFFF' }}
                    viewBox="0 0 50 50"
                  >
                    <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
                  </svg>{' '}
                </a>
                {/* <a target="_blank" href="https://www.youtube.com/@sideglobal2237">
                Youtube
              </a> */}
              </h6>
            </div>
          </div>

          <div className="mailto">
            <label htmlFor="" className="">
              {footerData ? footerData.footer_get_in_touch : 'get in touch'}
            </label>
            <h4>
              <a
                className=""
                href={`mailto:${footerData ? footerData.footer_email : "info@sideglobal.com'"}`}
              >
                {footerData ? footerData.footer_email : 'info@sideglobal.com'}
              </a>
            </h4>
          </div>
        </div>
        <div className="rightpart">
          <div className="footernav">
            <h6>
              <a
                // target="_blank"
                rel="noreferrer"
                href={
                  language === 'en'
                    ? clientUrl.about
                    : `/${language}${clientUrl.about}`
                }
                style={{ cursor: 'pointer' }}
              >
                {footerData
                  ? footerData.bottom_menu_links.footer_menu_about
                  : 'About'}
              </a>
            </h6>
            <h6>
              <a
                style={{ cursor: 'pointer' }}
                // target="_blank"
                rel="noreferrer"
                href={
                  language === 'en'
                    ? clientUrl.services
                    : `/${language}${clientUrl.services}`
                }
              >
                {footerData
                  ? footerData.bottom_menu_links.footer_menu_services
                  : 'Services'}
              </a>
            </h6>
            <h6>
              <a
                // target="_blank"
                rel="noreferrer"
                href={
                  language === 'en'
                    ? clientUrl.projects
                    : `/${language}${clientUrl.projects}`
                }
                style={{ cursor: 'pointer' }}
              >
                {footerData
                  ? footerData.bottom_menu_links.footer_menu_projects
                  : 'Projects'}
              </a>
            </h6>
            <h6>
              <a
                // target="_blank"
                rel="noreferrer"
                href={
                  language === 'en'
                    ? clientUrl.news
                    : `/${language}${clientUrl.news}`
                }
                style={{ cursor: 'pointer' }}
              >
                {footerData
                  ? footerData.bottom_menu_links.footer_menu_news
                  : 'News'}
              </a>
            </h6>
            <h6>
              <a
                // target="_blank"
                rel="noreferrer"
                href={
                  language === 'en'
                    ? clientUrl.contactus
                    : `/${language}${clientUrl.contactus}`
                }
                style={{ cursor: 'pointer' }}
              >
                {footerData
                  ? footerData.bottom_menu_links.footer_menu_contact
                  : 'Contact'}
              </a>
            </h6>
          </div>
        </div>
      </div>
      <div className="footerbottom">
        <div className="leftpart">
          <p>
            {footerData
              ? footerData.bottom_footer_content1
              : 'BRINGING YOUR STORY TO THE WORLD.'}
          </p>
          <p>
            <a
              href={
                langcode === 'en'
                  ? 'https://www.ptw.com/'
                  : `https://www.ptw.com/${langcode}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {footerData
                ? footerData.bottom_footer_content2
                : 'Part of the PTW family of brands'}
            </a>
          </p>
        </div>
        <div className="rightpart">
          <p>
            <a
              style={{ cursor: 'pointer' }}
              target="_blank"
              rel="noreferrer"
              href={
                language === 'en'
                  ? 'https://www.ptw.com/pdf/ptw_privacy_policy.pdf'
                  : `https://www.ptw.com/${language}/pdf/ptw_privacy_policy.pdf`
              }
              className="footer-aryan"
            >
              {footerData
                ? footerData.footer_menu_privacy_policy
                : 'Privacy policy'}
            </a>{' '}
            <a
              style={{ cursor: 'pointer' }}
              target="_blank"
              rel="noreferrer"
              href={
                language === 'en'
                  ? 'https://www.ptw.com/pdf/ptw_new_website_terms_of_usage_2018.pdf'
                  : `https://www.ptw.com/${language}/pdf/ptw_new_website_terms_of_usage_2018.pdf`
              }
              className="footer-aryan"
            >
              {' '}
              {footerData
                ? footerData.footer_menu_terms_conditions
                : 'Terms & conditions'}
            </a>{' '}
            ©{' '}
            {footerData
              ? footerData.footer_copy_right_year
              : `${new Date().getFullYear()}`}{' '}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
