import { useEffect, useState, useRef, Fragment } from 'react'
import '../../styles/Teams/teams.css'
import TestomonialAndContact from '../homepage/section8'
import Footer from '../../components/footer/footer'
import anime from 'animejs'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import axiosInstance from '../../services/axiosInstance'
import { devapiUrl } from '../../constants/devapi'
import SliderButtons from '../Buttons/SliderButtons'
import { useDispatch } from 'react-redux'
import { changeLoaderState } from '../../actions/lngactions'
import Notfound from '../NotFound/Notfound'
import { getLanguage } from '../../constants/urls'

let location = null
const Teams = () => {
  let bool = false
  const loadingState = useSelector((state) => state.loadingState)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [idx, setIdx] = useState(0)
  const [teamidx, setteamidx] = useState(0)
  const tryref = useRef(null)
  const [teamdata, Setteamdata] = useState([])
  const [studio, Setstudio] = useState([])
  const imageRef = useRef(null)
  const serviceRef = useRef(null)
  const viewteamRef = useRef(null)
  const listref = useRef(null)
  const listref2 = useRef(null)
  const location1 = useLocation()
  const [servicedata, Setservicedata] = useState([])
  const [isNotFound, setIsNotFound] = useState(false)
  let langcode = useSelector((state) => state.lang)
  let count1 = 0
  let count2 = 0
  const langCode1 = ['zh-chs', 'zh-cht', 'ja', 'ko', 'fr-ca']

  const geturl = () => {
    let param = location1.pathname.split('/')
    let url = param[param.length - 1]
    // console.log({ param })
    return url
  }

  const getteamdata = async (lang, url) => {

    let array = []
    await axiosInstance
      .get(`${devapiUrl}${lang}/aboutteams/${url}`)
      .then((res) => {
        //   Setaboutdata(res.data.about)
        setIsNotFound(false)
        Setteamdata(res.data)
        let teamStudio = res.data.team_studio_images
        location = teamStudio.length
        if (teamStudio.length > 0) {
          res.data.team_studio_images.forEach((data, index) => {
            array.push(data.studio_image_420x570)
            Setstudio(array)
            Setservicedata(res.data.team_details_services)
            // console.log('jj',serviceRef.current.textContent)
            if (tryref.current != null)
              tryref.current.textContent = res.data.teamscontent.about_services
            if (viewteamRef.current != null)
              viewteamRef.current.textContent =
                res.data.teamscontent.about_view_the_whole_team
            if (tryref.current != null)
              tryref.current.innerHTML = tryref.current.textContent.replace(
                /\S/g,
                "<span className='tryletter'>$&</span>",
              )
            if (viewteamRef.current != null)
              viewteamRef.current.innerHTML =
                viewteamRef.current.textContent.replace(
                  /\S/g,
                  "<span className='tryletter1'>$&</span>",
                )
            // if(serviceRef.current!=null)
            // serviceRef.current.innerHTML = serviceRef.current.textContent.replace(/\S/g, "<span className='tryletter'>$&</span>");
          })
        }
        // Setteamloading(true)
 
        if (bool === false) {
          setTimeout(() => {
            maintitle_animation()
            text_animation()
          }, 500)
        }
        bool = true
        dispatch(changeLoaderState(false))
      })
      .catch((err) => {
        console.error('err is api', err)
        if (err?.response?.status === 404) {
          setIsNotFound(true)
        }
        dispatch(changeLoaderState(false))
      })
  }

  useEffect(() => {
    let head1 = document.querySelector('.team-left')
    if (head1 != null) head1.style.opacity = 0
    dispatch(changeLoaderState(true))
    document.title = 'SIDE - Teams'

    window.scrollTo({ top: 0, left: 0 })
  }, [])

  useEffect(() => {
    let head1 = document.querySelector('.team-left')
    if (head1 != null) head1.style.opacity = 0
    // bool = false
if(imageRef.current!=null)
{
  imageRef.current.style.opacity=0;
}
    dispatch(changeLoaderState(true))
    getteamdata(getLanguage(), geturl())
  }, [location1.pathname])

  const handleNextProjectChange = () => {
    anime({
      targets: imageRef.current,
      translateX: [0, 0],
      scale: [0.5, 1],
      // delay: anime.stagger(30),
      duration: 2000,
      easing: 'easeOutQuart',
    })

    if (location) {
      setIdx((idx) => {
        if (idx === location - 1) {
          setTimeout(function () {
            setteamidx(0)
          }, 100)
          return 0
        } else {
          setTimeout(function () {
            setteamidx(0)
          }, 100)
          return idx + 1
        }
      })
    }
  }
  const handlePrevProjectChange = () => {
    anime({
      targets: imageRef.current,
      translateX: [0, 0],
      scale: [0.5, 1],
      // delay: anime.stagger(30),
      duration: 2000,
      easing: 'easeOutQuart',
    })
    if (location) {
      setIdx((idx) => {
        if (idx === 0) {
          setteamidx(0)
          return location - 1
        } else {
          setteamidx(0)
          return idx - 1
        }
      })
    }
  }
  const slide_to_idx = (i, index) => {
    for (let j = 0; j < studio.length; j++) {
      if (studio[j] === i) {
        setIdx(j)
      }
    }
    anime({
      targets: imageRef.current,
      translateX: [0, 0],
      scale: [0.5, 1],
      // delay: anime.stagger(30),
      duration: 2000,
      easing: 'easeOutQuart',
    })
  }
  const getLang1 = () => {
    let param = location1.pathname.split('/')
    for (let i = 0; i < param.length; i++) {
      if (langCode1.includes(param[i])) return param[i]
    }
    return ''
  }
  const handlearrow = (url) => {
    setteamidx(0)
    // Setteamloading(false)
    // bool = false
    setIdx(0)
    // var url1=url.toLowerCase();
    // url1=url1.trim(url1)
    count1 = 1
    count2 = 1
    let lang = getLang1()
    if (lang === '') {
      navigate(`/audio/teams/${url}`)
    } else {
      navigate(`/${lang}/audio/teams/${url}`)
    }
    // dispatch(changeLoaderState(true))
    // getteamdata(langcode, url)
  }

  const filteredlocation = []
  var i = 0
  if (idx === studio.length - 1) {
    i = 0
  } else {
    i = idx + 1
  }
  for (let count = 0; count < studio.length - 1; count++) {
    if (i === studio.length) i = 0
    filteredlocation.push(studio[i])
    i = i + 1
  }

  const text_animation = () => {
    if (loadingState === false) {
      count2 = count2 + 1
      console.log('it happen')
      // if(count2==2){
      anime({
        targets: imageRef.current,
        opacity: [0, 1],
        duration: 500,
        easing: 'easeOutQuart',
      })
      anime({
        targets: imageRef.current,
        translateX: [0, 0],
        scale: [0.5, 1],
        // delay: anime.stagger(30),
        duration: 2000,
        easing: 'easeOutQuart',
      })
      anime({
        targets: '.tryletter',
        translateY: [45, 0],
        easing: 'linear',
        duration: 1000,
        //stagger:anime.stagger(50),
        delay: (el, i) => 50 * i,
        easing: 'easeOutQuart',
      })
      anime({
        targets: '.tryletter2',
        translateY: [45, 0],
        easing: 'linear',
        duration: 1000,
        //stagger:anime.stagger(50),
        delay: (el, i) => 50 * i,
        easing: 'easeOutQuart',
      })
      anime({
        targets: '.tryletter1',
        translateY: [45, 0],
        easing: 'linear',
        duration: 1000,
        //stagger:anime.stagger(50),
        delay: (el, i) => 50 * i,
        easing: 'easeOutQuart',
      })
      //    anime({
      //     targets:'.tryletter1',
      //             translateY:[45, 0],
      //             easing:'linear',
      //             duration: 1000,
      //             //stagger:anime.stagger(50),
      //             delay: (el, i) => 50 * i,
      //             easing: 'easeOutQuart',
      //         })

      // anime({
      //     targets: '.tryletter',
      //     translateY: [45, 0],
      //     easing:'linear',
      //     duration: 1000,
      //     //stagger:anime.stagger(50),
      //     delay: (el, i) => 50 * i,
      //     easing: 'easeOutQuart',
      // });

      // anime({
      //     targets: chars3,
      //     translateY: [45, 0],
      //     easing:'linear',
      //     duration: 1000,
      //     //stagger:anime.stagger(50),
      //     delay: (el, i) => 50 * i,
      //     easing: 'easeOutQuart',
      // });
      // anime({
      //     targets: chars4,
      //     translateY: [45, 0],
      //     easing:'linear',
      //     duration: 1000,
      //     //stagger:anime.stagger(50),
      //     delay: (el, i) => 50 * i,
      //     easing: 'easeOutQuart',
      // });
    }
    // }
  }
  const maintitle_animation = () => {
    let head1 = document.querySelector('.team-left')
    if (head1 != null) head1.style.opacity = 0
    if (loadingState === false) count1 = count1 + 1
    // if(count1==2){
    if (head1 != null) head1.style.opacity = 1
    anime
      .timeline({ loop: false })
      .add({
        targets: '.Team-heading .Teamtag-1',
        translateY: [200, 0],
        easing: 'easeOutExpo',
        duration: 3400,
      })
      .add({
        targets: '.Teamtag-1',
        opacity: 1,
        duration: 100,
        easing: 'easeOutExpo',
        delay: 10,
        loop: false,
      })
    anime
      .timeline({ loop: false })
      .add({
        targets: '.content',
        opacity: 0,
        duration: 1000,
      })
      .add({
        targets: '.content ',
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 5400,
      })
    anime
      .timeline({ loop: false })
      .add({
        targets: ' .subcontent',
        opacity: 0,
        duration: 1000,
      })
      .add({
        targets: ' .subcontent',
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 5400,
      })
    // }
  }
  const rightswipe = () => {
    if (teamidx === teamdata.team_user_profile.length - 1) setteamidx(0)
    else setteamidx(teamidx + 1)
    //right swipe
    anime({
      targets: '.trial-pin-1',
      translateX: [15, 35],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.trial-pin-2',
      translateX: [0, 15],
      opacity: [1, 0.3],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.trial-pin-3',
      translateX: [0],
      duration: 1500,
      easing: 'easeOutSine',
    })

    anime({
      targets: '.crousal-pin',
      translateX: [-15, 8],
      opacity: [1, 0.3],
      // scale:[1,1.5],
      duration: 1500,
      easing: 'easeOutSine',
    })

    anime({
      targets: '.pin-x0',
      translateX: [-15, 8],
      scale: [1, 0.5],
      opacity: 1,
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.pin-x2',
      translateX: [-15, 11],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.pin-x1',
      translateX: [-15, 8],
      scale: [1, 1.2],
      opacity: [1],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.pin-y1',
      translateX: [-15, 8],
      scale: [1, 1.4],
      opacity: [1, 0.1],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.pin-y0',
      translateX: [-15, 8],
      scale: [1, 0.6],
      opacity: [1],
      duration: 1500,
      easing: 'easeOutSine',
    })
  }

  const leftswipe = () => {
    if (teamidx === 0) setteamidx(teamdata.team_user_profile.length - 1)
    else setteamidx(teamidx - 1)
    //left swipe

    anime({
      targets: '.crousal-pin',
      translateX: [15, -11],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.pin-y',
      translateX: [15, -11],
      opacity: [1],
      scale: [1, 1.2],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.pin-y0',
      translateX: [15, -13],

      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.pin-y1',
      translateX: [15, -11],
      scale: [1, 0.6],
      opacity: [1],
      //  opacity:[1,0.4],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.pin-y2',
      translateX: [15, -11],
      scale: [1, 0.8],
      opacity: [1, 0.3],
      //  opacity:[1,0.4],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.trial-pin-1',
      translateX: [10, -15],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.trial-pin-3',
      translateX: [10, -15],
      opacity: [1, 0.1],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.trial-pin-2',
      translateX: [0],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.pin-x2',
      translateX: [15, -11],
      scale: [1, 0.6],
      opacity: [1],
      //  opacity:[1,0.4],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.pin-x0',
      translateX: [15, -11],
      opacity: [1, 0.1],
      scale: [1, 1.7],
      duration: 1500,
      easing: 'easeOutSine',
    })
    anime({
      targets: '.pin-x1',
      translateX: [15, -11],
      opacity: [1, 0.3],

      duration: 1500,
      easing: 'easeOutSine',
    })
  }
  const handleback = () => {
    // Setaboutloading(false)
    const language = getLang1()
    if (language === '') navigate('/audio/about')
    else {
      navigate(`/${language}/audio/about`)
    }
  }
  const handleteam = () => {
    let ele = document.getElementById('teamsection-container')
    if (ele != null) {
      const headerOffset = 80

      const elementPosition = ele.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }
  const freeze = (e, index) => {
    const cursor = document.querySelector(`.cursor-team${index}`)
    let x, y
    if (index === 2) {
      x = e.pageX - window.pageXOffset + 40
      y = e.pageY - window.pageYOffset - 50
    } else {
      x = e.pageX - window.pageXOffset - 40
      y = e.pageY - window.pageYOffset - 50
    }

    if (cursor != null) {
      if (cursor.style.display === 'none') {
        cursor.style.display = 'block'
        cursor.style.left = `${x}px`
        cursor.style.top = `${y}px`
      }
    }
  }

  const unfreeze = (e, index) => {
    const cursor = document.querySelector(`.cursor-team${index}`)

    if (cursor != null) {
      cursor.style.display = 'none'
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      // console.log(window.scrollY)

      let curs = document.querySelectorAll('#cursor-team')
      if (curs != null) {
        for (const e of curs) {
          if (e != null) e.style.display = 'none'
        }
      }
    })
  }, [])
  if (isNotFound) {
    return <Notfound />
  }
  return (
    <>
      <div className="teamsExpand">
        <div className="topSection">
          <span className="leftshadow"></span>
          <div onClick={(e) => handleback()} className="backtoabout">
            <svg
              className="me-2"
              width="21"
              height="15"
              viewBox="0 0 21 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.4697 7.50195L20.4697 8.00195L19.9697 8.00195L2.17691 8.00195L7.32328 13.1483L7.67683 13.5019L6.96973 14.209L6.61617 13.8554L0.616173 7.85543L0.262619 7.50188L0.616172 7.14832L6.61617 1.14832L6.96973 0.794771L7.67683 1.50188L7.32328 1.85543L2.17676 7.00195L19.4697 7.00195L19.4697 3.20898L19.4697 2.70898L20.4697 2.70898L20.4697 3.20898L20.4697 7.50195Z"
                fill="white"
              />
            </svg>
            <p>
              {teamdata
                ? teamdata?.teamscontent?.back_to_about
                : 'back to About'}
            </p>
          </div>
          <div className="row mt-3">
            <div className="col-lg-5 team-left Team-heading leftContent">
              <div className="Team-heading ">
                <h1 className="Teamtag-1">
                  {teamdata ? teamdata?.team_location : 'Capetwon'}
                </h1>
              </div>
              <p style={{ opacity: 0 }} className="content d-lg-block d-none">
                {teamdata ? teamdata?.team_description : ''}
              </p>
              <p
                style={{ opacity: 0 }}
                className="subcontent d-lg-block d-none"
              >
                {teamdata ? teamdata?.team_description_para2 : ''}
              </p>
              <div ref={listref2} className="servicesList d-lg-block d-none">
                <span className="line"></span>
                <div className="try1" style={{ overflow: 'hidden' }}>
                  <h5 ref={tryref}>
                    {teamdata
                      ? teamdata?.teamscontent?.about_services
                      : 'Services'}
                  </h5>
                </div>
                <span
                  ref={serviceRef}
                  style={{ overflow: 'hidden', display: 'inline-block' }}
                >
                  {servicedata?.length > 0
                    ? servicedata.map((service, i) => {
                        return (
                          <p
                            key={i}
                            style={{ display: 'inline-block' }}
                            className="service-wrap"
                          >
                            {service.length > 0
                              ? service.split('').map((item, index) => {
                                  return (
                                    <span
                                      key={index}
                                      style={{ display: 'inline-block' }}
                                      className="tryletter"
                                    >
                                      {item === ' ' ? <>&nbsp;</> : item}
                                    </span>
                                  )
                                })
                              : ''}
                          </p>
                        )
                      })
                    : ''}
                </span>
                <a
                  className="viewall"
                  onClick={() => {
                    handleteam()
                  }}
                >
                  <span
                    ref={viewteamRef}
                    style={{ display: 'inline-block', overflow: 'hidden' }}
                  >
                    {' '}
                    {teamdata
                      ? teamdata?.teamscontent?.about_view_the_whole_team
                      : 'View the whole team1'}
                  </span>

                  <img
                    className="icon"
                    src="/audio/temp_img/bottom-arrow.svg"
                    alt="bottom-arrow"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="d-flex align-items-center justify-content-between teams_slider">
                <div className="teamlist-sec-2-subsec-2">
                  <img ref={imageRef} src={studio[idx]} alt="" />
                </div>
                {filteredlocation?.length > 0
                  ? filteredlocation.map((i, idx) => {
                      return (
                        <div
                          key={idx}
                          onClick={() => {
                            slide_to_idx(i, idx)
                          }}
                          className="teamlist-sec-2-child"
                        >
                          <img src={i} alt="" />
                        </div>
                      )
                    })
                  : ''}
                <div className="side-arrow">
                  <div
                    onClick={() => handlePrevProjectChange()}
                    className="side-left arrow-circle"
                  >
                    <span className="bg_fill"></span>
                    <span className="bg_fill"></span>
                    <img src="/audio/temp_img/left-arrow.svg" alt="side"></img>
                  </div>

                  <p>{idx < 10 ? `0${idx + 1}` : idx + 1}</p>

                  <div
                    onClick={() => handleNextProjectChange()}
                    className="side-right arrow-circle"
                  >
                    <span className="bg_fill"></span>
                    <span className="bg_fill"></span>
                    <img src="/audio/temp_img/Union.svg" alt="side" />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-lg-none d-block">
              <p className="content d-lg-none d-block">
                {/* {selectedlocation.location_content} */}
              </p>
              <p className="subcontent d-lg-none d-block">
                {/* {selectedlocation.location_subcontent} */}
              </p>
              {/* <div className="d-flex justify-content-center"> */}
              <div ref={listref} className="servicesList d-lg-none d-block">
                <span className="line"></span>
                <h5>
                  {teamdata
                    ? teamdata?.teamscontent?.about_services
                    : 'Services'}
                </h5>
                {/* {(selectedlocation?.services || []).map((service, i) => {
                  return (
                    <p key={i} className="service-wrap">
                      {service}
                    </p>
                  )
                })} */}
                {servicedata?.length > 0
                  ? servicedata.map((service, i) => {
                      return (
                        <p
                          key={i}
                          style={{ display: 'inline-block' }}
                          className="service-wrap"
                        >
                          {service.length > 0
                            ? service.split('').map((item, index) => {
                                return (
                                  <span
                                    key={index}
                                    style={{ display: 'inline-block' }}
                                    className="tryletter2"
                                  >
                                    {item === ' ' ? <>&nbsp;</> : item}
                                  </span>
                                )
                              })
                            : ''}
                        </p>
                      )
                    })
                  : ''}
                {/* <p>Production Management</p>
                                <p>Casting</p>
                                <p>Localization</p>
                                <p>Performance Capture</p>
                                <p>Studio Recording</p> */}
                <a
                  style={{ overflow: 'hidden' }}
                  onClick={() => {
                    handleteam()
                  }}
                  className="viewall"
                >
                  {teamdata
                    ? teamdata?.teamscontent?.about_view_the_whole_team
                    : 'View the whole team1'}
                  <img
                    className="icon"
                    src="/audio/temp_img/bottom-arrow.svg"
                    alt="bottom-arrow"
                  />
                </a>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div className="col d-none d-lg-block mt-4">
            <div className="horizontal_border mt-4"></div>
          </div>
        </div>

        <div id="teamsection" className="wholeTeamSection">
          <div className="container" id="teamsection-container">
            {teamdata?.team_user_profile?.length > 0
              ? teamdata?.team_user_profile.map((data, i) => {
                  return (
                    <Fragment key={i}>
                      {i === 0 ? (
                        <>
                          <div className="teamCard" key={i}>
                            <div className="hoverImg">
                              <img
                                className="frontImg"
                                alt="side"
                                // src={'../img/teams/team4.png'}
                                src={
                                  teamdata?.team_user_profile[teamidx]
                                    .profile_professional_image === false
                                    ? '../img/teams/team4.png'
                                    : teamdata?.team_user_profile[teamidx]
                                        .profile_professional_image
                                }
                              />
                              <img
                                className="backImg"
                                alt="side"
                                // src={'../img/teams/team4.png'}
                                src={
                                  teamdata?.team_user_profile[teamidx]
                                    .profile_chilly_image === false
                                    ? '../img/teams/team4.png'
                                    : teamdata?.team_user_profile[teamidx]
                                        .profile_chilly_image
                                }
                              />
                            </div>
                            <div className="d-lg-block d-flex flex-row-reverse justify-content-between">
                              <span>
                                {
                                  teamdata?.team_user_profile[teamidx]
                                    .department
                                }
                              </span>
                              <p>
                                {teamdata?.team_user_profile[teamidx].job_title}
                              </p>
                            </div>
                            <h6>
                              {teamdata?.team_user_profile[teamidx].user_name}
                            </h6>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="teamCard d-lg-block d-none">
                            <div className="hoverImg">
                              <img
                                className="frontImg"
                                alt="side"
                                src={
                                  data.profile_professional_image === false
                                    ? '../img/teams/team4.png'
                                    : data.profile_professional_image
                                }
                                // src={'../img/teams/team4.png'}
                                // src={data.profile_professional_image===true?"../img/teams/team4.png": data.profile_professional_image}
                              />
                              <img
                                className="backImg"
                                alt="side"
                                // src={'../img/teams/team4.png'}
                                src={
                                  data.profile_chilly_image === false
                                    ? '../img/teams/team4.png'
                                    : data.profile_chilly_image
                                }
                              />
                            </div>
                            <span>{data.department}</span>
                            <p>{data.job_title}</p>
                            <h6>{data.user_name}</h6>
                          </div>
                        </>
                      )}
                    </Fragment>
                  )
                })
              : ''}
          </div>

          <div className="d-lg-none d-block">
            <div className="mbl_profileslider">
              <div className="arrows swiper_arrows">
                <SliderButtons
                  className={'prevarrow'}
                  onClick={(e) => leftswipe(e)}
                  width={16}
                  height={11}
                  path={
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.8945 5.9999L15.8945 5.4999L15.3945 5.4999L2.60523 5.4999L6.17191 1.93322L6.52547 1.57966L5.81836 0.872558L5.46481 1.22611L1.04448 5.64644L0.690922 5.99999L1.04448 6.35355L5.46481 10.7739L5.81836 11.1274L6.52547 10.4203L6.17191 10.0668L2.60504 6.4999L14.8945 6.4999L14.8945 9.16263L14.8945 9.66263L15.8945 9.66263L15.8945 9.16263L15.8945 5.9999Z"
                      fill="#000"
                    />
                  }
                />
                <div className="IndicatorsPosition">
                  <div className="crousal-container">
                    <p className="crousal-left-count">
                      {' '}
                      {teamidx + 1 < 10 ? `0${teamidx + 1}` : teamidx + 1}{' '}
                    </p>

                    <div className="crousal-wrap">
                      <span className="trial-pin-2"></span>
                      <span className="crousal-pin pin-x"></span>
                      <span className="crousal-pin pin-x0"></span>
                      <span className="crousal-pin pin-x1"></span>
                      <span className="crousal-pin pin-x2"></span>
                      <span className="trial-pin-1"></span>
                      <span className="crousal-center-button"></span>
                      <span className="crousal-pin pin-y0"></span>
                      <span className="crousal-pin pin-y "></span>
                      <span className="crousal-pin pin-y1"></span>
                      <span className="crousal-pin pin-y2"></span>
                      <span className="trial-pin-3"></span>
                    </div>
                    <p className="crousal-right-count">
                      {' '}
                      {teamdata ? teamdata?.team_user_profile?.length : 1}{' '}
                    </p>
                  </div>
                </div>
                <SliderButtons
                  className={'nextarrow'}
                  onClick={(e) => rightswipe(e)}
                  width={16}
                  height={11}
                  path={
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                      fill="#000"
                    />
                  }
                />
              </div>
            </div>
          </div>

          <div className="d-lg-none d-block">
            <div className="mbl_teamslider">
              {teamdata?.prev_teamslocation?.url ? (
                <button
                  style={{ cursor: 'pointer' }}
                  onClick={(e) =>
                    handlearrow(
                      teamdata ? teamdata?.prev_teamslocation?.url : '',
                    )
                  }
                  className="prevPage"
                >
                  <span>
                    {teamdata ? teamdata?.prev_teamslocation?.title : ''} <br />
                    {teamdata ? teamdata?.teamscontent?.about_team : 'team1'}
                  </span>
                  <svg
                    width="28"
                    height="18"
                    viewBox="0 0 28 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27.0427 9.05214L27.0427 9.55214L26.5427 9.55214L2.40591 9.55214L2.27275 9.55214L9.46464 16.744L9.81819 17.0976L9.11108 17.8047L8.75753 17.4511L0.711925 9.40553L0.358371 9.05197L0.711925 8.69842L8.75753 0.652818L9.11108 0.299264L9.81819 1.00637L9.46464 1.35992L2.27242 8.55214L2.40591 8.55214L26.0427 8.55214L26.0427 3.29555L26.0427 2.79555L27.0427 2.79555L27.0427 3.29555L27.0427 9.05214ZM1.90591 8.91865L1.90591 9.1853L1.77258 9.05197L1.90591 8.91865Z"
                      fill="#FEFBF7"
                    />
                  </svg>
                </button>
              ) : null}
              {teamdata?.next_teamslocation?.url ? (
                <button
                  style={{ cursor: 'pointer' }}
                  onClick={(e) =>
                    handlearrow(
                      teamdata ? teamdata?.next_teamslocation?.url : '',
                    )
                  }
                  className="prevPage"
                >
                  <span>
                    {teamdata ? teamdata?.next_teamslocation?.title : ''} <br />
                    {teamdata ? teamdata?.teamscontent?.about_team : 'team1'}
                  </span>
                  <svg
                    width="28"
                    height="18"
                    viewBox="0 0 28 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.717041 9.05214L0.717041 9.55214L1.21704 9.55214L25.3539 9.55214L25.487 9.55214L18.2951 16.744L17.9416 17.0976L18.6487 17.8047L19.0022 17.4511L27.0478 9.40553L27.4014 9.05197L27.0478 8.69842L19.0022 0.652818L18.6487 0.299264L17.9416 1.00637L18.2951 1.35992L25.4873 8.55214L25.3539 8.55214L1.71704 8.55214L1.71704 3.29555L1.71704 2.79555L0.717042 2.79555L0.717042 3.29555L0.717041 9.05214ZM25.8539 8.91865L25.8539 9.1853L25.9872 9.05197L25.8539 8.91865Z"
                      fill="#FEFBF7"
                    />
                  </svg>
                </button>
              ) : null}
            </div>
          </div>
        </div>

        <div className="TeamNavigation d-lg-block d-none">
          <div
            className={`container ${teamdata?.prev_teamslocation?.url ? '' : 'onlynext'}`}
          >
            {teamdata?.prev_teamslocation?.url ? (
              <div
                onMouseMove={(e) => freeze(e, 1)}
                onMouseLeave={(e) => unfreeze(e, 1)}
                className="prev navigation"
              >
                <span>
                  {teamdata ? teamdata?.teamscontent?.about_prev : 'Prev'}{' '}
                  <span className="dotblink ms-2"></span>
                </span>
                <h4>{teamdata ? teamdata?.prev_teamslocation?.title : ''}</h4>
                {/* web button */}
                <div id={`cursor-team`} className={`cursor-team${1}`}>
                  <button
                    onClick={(e) =>
                      handlearrow(
                        teamdata ? teamdata?.prev_teamslocation?.url : '',
                      )
                    }
                    className="navButton"
                  >
                    <img src="/audio/temp_img/left-arrow.svg" alt="side" />
                  </button>
                </div>
              </div>
            ) : null}
            {teamdata?.next_teamslocation?.url ? (
              <div
                onMouseMove={(e) => freeze(e, 2)}
                onMouseLeave={(e) => unfreeze(e, 2)}
                className="next navigation"
              >
                <span>
                  <span className="dotblink ms-2"></span>
                  {teamdata ? teamdata?.teamscontent?.about_next : 'Next'}
                </span>
                <h4>{teamdata ? teamdata?.next_teamslocation?.title : ''}</h4>
                <div id={`cursor-team`} className={`cursor-team${2}`}>
                  <button
                    onClick={(e) =>
                      handlearrow(
                        teamdata ? teamdata?.next_teamslocation?.url : '',
                      )
                    }
                    className="navButton"
                  >
                    <img src="/audio/temp_img/Union.svg" alt="side" />
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <TestomonialAndContact />
        <Footer />
      </div>
    </>
  )
}
export default Teams
