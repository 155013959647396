export const newsListData = [
  {
    newsHeaderText1: 'ON THE',
    newsHeaderText2: 'SOUND WAVES',
    newsSubHeading:
      'Discover our newest updates and explore our latest endeavors.',
    newsListHeadingBgImage: '0',
    newsListData: [
      {
        newsName: 'News Outlet 1',
        newsTitle: 'News Title 1',
        newsDate: '2024-01-01',
        newsThumbnail: 'https://example.com/thumbnail1.jpg',
        newsUrl: 'news1',
        newsCategory: 'Category 1',
        newsId: 'id1',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 2',
        newsTitle: 'News Title 2',
        newsDate: '2024-01-02',
        newsThumbnail: 'https://example.com/thumbnail2.jpg',
        newsUrl: 'news2',
        newsCategory: 'Category 2',
        newsId: 'id2',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 3',
        newsTitle: 'News Title 3',
        newsDate: '2024-01-03',
        newsThumbnail: 'https://example.com/thumbnail3.jpg',
        newsUrl: 'news3',
        newsCategory: 'Category 3',
        newsId: 'id3',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 4',
        newsTitle: 'News Title 4',
        newsDate: '2024-01-04',
        newsThumbnail: 'https://example.com/thumbnail4.jpg',
        newsUrl: 'news4',
        newsCategory: 'Category 4',
        newsId: 'id4',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 5',
        newsTitle: 'News Title 5',
        newsDate: '2024-01-05',
        newsThumbnail: 'https://example.com/thumbnail5.jpg',
        newsUrl: 'news5',
        newsCategory: 'Category 5',
        newsId: 'id5',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 6',
        newsTitle: 'News Title 6',
        newsDate: '2024-01-06',
        newsThumbnail: 'https://example.com/thumbnail6.jpg',
        newsUrl: 'news6',
        newsCategory: 'Category 6',
        newsId: 'id6',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 7',
        newsTitle: 'News Title 7',
        newsDate: '2024-01-07',
        newsThumbnail: 'https://example.com/thumbnail7.jpg',
        newsUrl: 'news7',
        newsCategory: 'Category 7',
        newsId: 'id7',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 8',
        newsTitle: 'News Title 8',
        newsDate: '2024-01-08',
        newsThumbnail: 'https://example.com/thumbnail8.jpg',
        newsUrl: 'news8',
        newsCategory: 'Category 8',
        newsId: 'id8',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 9',
        newsTitle: 'News Title 9',
        newsDate: '2024-01-09',
        newsThumbnail: 'https://example.com/thumbnail9.jpg',
        newsUrl: 'news9',
        newsCategory: 'Category 9',
        newsId: 'id9',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 10',
        newsTitle: 'News Title 10',
        newsDate: '2024-01-10',
        newsThumbnail: 'https://example.com/thumbnail10.jpg',
        newsUrl: 'news10',
        newsCategory: 'Category 10',
        newsId: 'id10',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 11',
        newsTitle: 'News Title 11',
        newsDate: '2024-01-11',
        newsThumbnail: 'https://example.com/thumbnail11.jpg',
        newsUrl: 'news11',
        newsCategory: 'Category 11',
        newsId: 'id11',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 12',
        newsTitle: 'News Title 12',
        newsDate: '2024-01-12',
        newsThumbnail: 'https://example.com/thumbnail12.jpg',
        newsUrl: 'news12',
        newsCategory: 'Category 12',
        newsId: 'id12',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 13',
        newsTitle: 'News Title 13',
        newsDate: '2024-01-13',
        newsThumbnail: 'https://example.com/thumbnail13.jpg',
        newsUrl: 'news13',
        newsCategory: 'Category 13',
        newsId: 'id13',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 14',
        newsTitle: 'News Title 14',
        newsDate: '2024-01-14',
        newsThumbnail: 'https://example.com/thumbnail14.jpg',
        newsUrl: 'news14',
        newsCategory: 'Category 14',
        newsId: 'id14',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 15',
        newsTitle: 'News Title 15',
        newsDate: '2024-01-15',
        newsThumbnail: 'https://example.com/thumbnail15.jpg',
        newsUrl: 'news15',
        newsCategory: 'Category 15',
        newsId: 'id15',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 16',
        newsTitle: 'News Title 16',
        newsDate: '2024-01-16',
        newsThumbnail: 'https://example.com/thumbnail16.jpg',
        newsUrl: 'news16',
        newsCategory: 'Category 16',
        newsId: 'id16',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 17',
        newsTitle: 'News Title 17',
        newsDate: '2024-01-17',
        newsThumbnail: 'https://example.com/thumbnail17.jpg',
        newsUrl: 'news17',
        newsCategory: 'Category 17',
        newsId: 'id17',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 18',
        newsTitle: 'News Title 18',
        newsDate: '2024-01-18',
        newsThumbnail: 'https://example.com/thumbnail18.jpg',
        newsUrl: 'news18',
        newsCategory: 'Category 18',
        newsId: 'id18',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 19',
        newsTitle: 'News Title 19',
        newsDate: '2024-01-19',
        newsThumbnail: 'https://example.com/thumbnail19.jpg',
        newsUrl: 'news19',
        newsCategory: 'Category 19',
        newsId: 'id19',
        newsReadingTime: '15 Mins',
      },
      {
        newsName: 'News Outlet 20',
        newsTitle: 'News Title 20',
        newsDate: '2024-01-20',
        newsThumbnail: 'https://example.com/thumbnail20.jpg',
        newsUrl: 'news20',
        newsCategory: 'Category 20',
        newsId: 'id20',
        newsReadingTime: '15 Mins',
      },
    ],
  },
]
