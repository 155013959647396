export const clientUrl = {
  preLoader: '/audio',
  home: '/:id?/audio',
  services: '/audio/services',
  servicesDescription: '/audio/services/:id',
  servicesSubservices: '/audio/services/:id/:id',
  servicesSuperSubservicesWithLang: '/:id?/audio/services/:id/:id',
  servicesSubservicesWithLang: '/:id?/audio/services/:id',
  servicesDescriptionWithLang: '/:id?/audio/services/:id',
  servicesWithLang: '/:id?/audio/services',
  about: '/audio/about',
  aboutWithLang: '/:id?/audio/about',
  projects: '/audio/projects',
  projectsWithLang: '/:id?/audio/projects',
  projectsDescription: '/audio/projects/:id',
  projectsDescriptionWithLang: '/:id?/audio/projects/:id',
  contactus: '/audio/contactus',
  contactusWithLang: '/:id?/audio/contactus',
  contact: '/audio/contact',
  contactWithLang: '/audio/:id?/audio/contactus',
  news: '/audio/news',
  newsWithLang: '/:id?/audio/news',
  newsDescription: '/audio/news/:id',
  newsDescriptionWithLang: '/:id?/audio/news/:id',
  location: '/audio/contactus/locations',
  locationWithLang: '/:id?/audio/contactus/locations',
  teams: '/audio/teams',
  teamsdetail: '/audio/teams/:id',
  teamsWithLang: '/:id?/audio/teams/:id',
  privacy: '/audio/privacy',
  privacyWithLang: '/:id?/audio/privacy',
  terms: '/audio/terms',
  termsWithLang: '/:id?/audio/terms',
  notFound: '/audio/notfound',
}

const langCode = ['zh-chs', 'zh-cht', 'ja', 'ko', 'fr-ca']

export const getLanguage = () => {
  let param = window.location.href.split('/')
  for (let i = 0; i < param.length; i++) {
    if (langCode.includes(param[i])) return param[i]
  }
  return 'en'
}
