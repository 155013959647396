import React, { useEffect, useState, useRef } from 'react'
import '../../styles/project/projectdescription.css'
import { useLocation } from 'react-router-dom'
import axiosInstance from '../../services/axiosInstance'
import parse from 'html-react-parser'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import anime from 'animejs'
import { forwardRef } from 'react'
import { devapiUrl } from '../../constants/devapi'
import TestomonialAndContact from '../homepage/section8'
import Footer from '../../components/footer/footer'

import { useDispatch } from 'react-redux'
import { changeLoaderState } from '../../actions/lngactions'
import Notfound from '../NotFound/Notfound'
import { getLanguage } from '../../constants/urls'

var x = null,
  y = null

const ProjectDescription = forwardRef((props, ref) => {
  let navigate = useNavigate()
  const lang = useSelector((state) => state.lang)
  const [activeindex, setactiveindex] = useState(0)
  const dispatch = useDispatch()
  const imageRef = useRef(null)
  const projectdescRef = useRef(null)
  const buttonRef = useRef(null)
  const testimonial_count = useRef(0)
  const testimonialRef = useRef(null)
  const project_desc_count = useRef(0)
  const split = useRef(null)
  const [project, Setproject] = useState(null)
  const [data, setData] = useState([])
  const [swipe, setSwipe] = useState(null)
  const [prevurl, setprevurl] = useState('')
  const [nexturl, setnexturl] = useState('')
  const [testimonials, settestimonials] = useState([])
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isNotFound, setIsNotFound] = useState(false)
  const location = useLocation()

  //code for handlinging scalling of banner - Bala
  const cardvalue = localStorage.getItem('isCardClicked')

  let bool = false

  //function

  const controlnextslide = (e) => {
    if (e.target === e.currentTarget) nextSlide()
  }
  const controlprevslide = (e) => {
    if (e.target === e.currentTarget) prevSlide()
  }
  const nextSlide = () => {
    swipe.slideNext()

    var slidelength = swipe.slides.length

    var index = activeindex + 1
    if (slidelength > 1) {
      // fade1.restart()
      x = anime.timeline({ loop: false })
      x.add({
        targets: `.testimonial-letters${index}`,
        opacity: 1,
        duration: 0,
      })
      x.add({
        targets: `.testimonial-letters${index}`,
        translateY: [0, -45],
        easing: 'easeOutExpo',
        delay: (el, i) => 50 * i,
      })
      // if(index+1==3)
      // index=0;
      if (index + 1 === slidelength + 1) {
        y = anime.timeline({ loop: false })
        y.add({
          targets: `.testimonial-letters${1}`,
          opacity: 1,
          duration: 0,
        }).add({
          targets: `.testimonial-letters${1}`,
          translateY: [45, 0],
          easing: 'easeOutExpo',

          delay: (el, i) => 50 * i,
        })
      } else {
        y = anime.timeline({ loop: false })
        y.add({
          targets: `.testimonial-letters${index + 1}`,
          opacity: 1,
          duration: 0,
        }).add({
          targets: `.testimonial-letters${index + 1}`,
          translateY: [45, 0],
          easing: 'easeOutExpo',

          delay: (el, i) => 50 * i,
        })
      }
      x.restart()
      y.restart()
    }
  }

  const prevSlide = () => {
    swipe.slidePrev()

    // textanime3.restart();
    var slidelength = swipe.slides.length
    if (slidelength > 1) {
      // fade1.restart()
      var index = activeindex + 1
      if (index - 1 === 0) {
        index = slidelength + 1
        x = anime.timeline({ loop: false })
        x.add({
          targets: `.testimonial-letters${index - 1}`,
          opacity: 1,
          duration: 0,
        })
        x.add({
          targets: `.testimonial-letters1`,
          translateY: [0, -45],
          easing: 'easeOutExpo',
          delay: (el, i) => 50 * i,
        })
      } else {
        x = anime.timeline({ loop: false })
        x.add({
          targets: `.testimonial-letters${index - 1}`,
          opacity: 1,
          duration: 0,
        })
        x.add({
          targets: `.testimonial-letters${index}`,
          translateY: [0, -45],
          easing: 'easeOutExpo',
          delay: (el, i) => 50 * i,
        })
      }
      y = anime.timeline({ loop: false })
      y.add({
        targets: `.testimonial-letters${index - 1}`,
        opacity: 1,
        duration: 0,
      }).add({
        targets: `.testimonial-letters${index - 1}`,
        translateY: [45, 0],
        easing: 'easeOutExpo',

        delay: (el, i) => 50 * i,
      })
      x.restart()
      y.restart()
    }
  }
  let prevhome = location.state?.fromhome ? 'Yes' : 'No'
  useEffect(() => {
    prevhome = location.state?.fromhome ? 'Yes' : 'No'

    window.scrollTo({ top: 0, left: 0 })
    dispatch(changeLoaderState(true))
  }, [])

  const geturl = () => {
    let param = window.location.href.split('/')
    let url = param[param.length - 1]
    return url
  }

  var textanime3 = anime.timeline({ loop: false })
  textanime3
    .add({
      targets: ' .testimonial-animation .testimonial-letters ',
      translateY: [0],
      duration: 0,
    })
    .add({
      targets: '.testimonial-animation .company-letters',
      translateY: [0],
      duration: 0,
    })
    .add({
      targets: ' .testimonial-animation .testimonial-letters',
      translateY: [0, -45],
      easing: 'easeOutExpo',
      duration: 1000,
      delay: (el, i) => 100 * i,
    })
    .add({
      targets: '.testimonial-animation .company-letters',
      translateY: [0, -45],
      easing: 'easeOutExpo',
      duration: 1000,
      delay: (el, i) => 100 * i,
      endDelay: 500,
    })
    .add({
      targets: '.testimonial-animation .company-letters',
      translateY: 0,
      duration: 0,
      delay: 500,
    })
  useEffect(() => {
    ref.current = 1

    var fade = anime.timeline({ loop: true })
    fade.add({
      targets: '.dot',
      opacity: [1, 0],
      duration: 1500,
      easing: 'easeOutExpo',
    })
    fade.play()

    window.addEventListener('scroll', (e) => {
      var textanime = anime.timeline({ loop: false, autoplay: false })

      textanime
        .add({
          targets: '.project-desc-project-name h1',
          translateY: [0],
          duration: 0,
        })
        .add({
          targets: '.project-desc-project-name h1',
          translateY: [200, 0],
          easing: 'easeOutExpo',
          duration: 1500,
          delay: 0,
        })

      var textanime1 = anime.timeline({
        loop: false,
        autoplay: false,
      })
      textanime1
        .add({
          targets: '.animated-text-box .animated-my-text',
          translateY: [0],
          duration: 0,
        })
        .add({
          targets: '.animated-text-box .animated-my-text',
          translateY: [35, 0],
          easing: 'linear',
          duration: 1000,
          //stagger:anime.stagger(50),
          delay: (el, i) => 50 * i,
          endDelay: 500,
        })

      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            //var count=0;

            if (entry.intersectionRatio === 0) {
              // setCount(entry.intersectionRatio);
              project_desc_count.current = 0
            }
            if (entry.intersectionRatio === 1) {
              // count=count+1;
              // if(count==1)
              if (project_desc_count.current === 0) {
                textanime.restart()
                textanime1.restart()
                //  fade1.restart();
                project_desc_count.current = 1
              }
            }
          })
        },
        { threshold: 1 },
      )

      if (projectdescRef.current != null)
        observer.observe(projectdescRef.current)

      let observer1 = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            //var count=0;
            if (entry.intersectionRatio === 0) {
              // setCount(entry.intersectionRatio);
              testimonial_count.current = 0
            }
            if (entry.intersectionRatio === 1) {
              // count=count+1;
              // if(count==1)
              if (testimonial_count.current === 0) {
                // textanime3.restart();
                testimonial_count.current = 1
              }
            }
          })
        },
        { threshold: 1 },
      )

      if (testimonialRef.current != null)
        observer1.observe(testimonialRef.current)

      e.preventDefault()
    })
  }, [lang, split.current, navigate])
  useEffect(() => {
    // dispatch(changeLoaderState(true))
    let language = getLanguage()
    getprojectdata(language)
    callPageApi(geturl(), language)
  }, [lang, location.pathname])
  const getprojectdata = (lang) => {
    axiosInstance
      .get(`${devapiUrl}${lang}/side-pages/projects`)
      .then((res) => {
        Setproject(res.data.projects)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  const callPageApi = (u, lang) => {
    dispatch(changeLoaderState(true))
    window.scrollTo({ top: 0, left: 0 })
    axiosInstance
      .get(`${devapiUrl}${lang}/project/${u}`)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data

          document.title = data.title || location.state?.title
          setIsNotFound(false)
          settestimonials(data.testimonial)
          setData(data)
          let nextProjectUrl = data?.next_project?.url
            ? data.next_project.url
            : null
          let prevProjectUrl = data?.prev_project?.url
            ? data.prev_project.url
            : null
          setnexturl(nextProjectUrl)
          setprevurl(prevProjectUrl)
          setTimeout(() => {
            dispatch(changeLoaderState(false))
            if (bool === false && prevhome === 'No') {
              bool = true

              anime({
                targets: '.project-banner img',
                delay: 500,
                scale: [0, 1],
                duration: 1000,
                easing: 'easeOutQuart',
              })
            }
          }, 1500)
        }
      })
      .catch((err) => {
        console.error(err)
        if (err?.response?.status === 404) {
          setIsNotFound(true)
        }
        dispatch(changeLoaderState(false))
      })
  }

  const freeze = (e, index) => {
    const cursor = document.querySelector(`.cursor-project${index}`)

    let x = e.pageX - window.scrollX
    let y = e.pageY - window.scrollY

    if (cursor !== null) {
      if (cursor.style.display === 'none') {
        cursor.style.display = 'block'
        cursor.style.left = `${x}px`
        cursor.style.top = `${y}px`
      }
    }
  }

  const unfreeze = (e, index) => {
    const cursor = document.querySelector(`.cursor-project${index}`)

    if (cursor !== null) {
      cursor.style.display = 'none'
    }
  }

  const projectnavigation = (url) => {
    document.body.style.opacity = '0'
    setTimeout(() => {
      document.body.style.opacity = '1'
    }, 200)

    dispatch(changeLoaderState(true))
    const language = getLanguage()

    if (url !== undefined && language !== 'en')
      navigate(`/${language}/audio/projects/${url}`)

    if (url !== undefined && language === 'en')
      navigate(`/audio/projects/${url}`)

    // callPageApi(url, language)
  }

  const changeCases = (str) => {
    if (str && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    } else {
      return str
    }
  }
  const [show, setshow] = useState(window.innerWidth < 768 ? true : false)
  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      let curs = document.querySelectorAll('#cursor-project')
      if (curs !== null) {
        for (const e of curs) {
          if (e !== null) e.style.display = 'none'
        }
      }
    })
    window.addEventListener('resize', () => {
      let width = window.innerWidth
      if (width < 768) {
        setshow(true)
      } else {
        setshow(false)
      }
    })
  }, [show])

  if (isNotFound) {
    return <Notfound />
  }
  const displayContent = () => {
    return (
      <>
        <section className="project-desc-sec-1">
          <div className="container">
            <div ref={projectdescRef} className="row mb-md-5 pb-md-4">
              <div className="col-12 col-md-4 mb-2 animated-text-box">
                <p
                  ref={split}
                  className="
                project-desc-heading mb-0"
                >
                  {project
                    ? project.project_static_content_all_projects_section
                        .projects_static_content_released
                    : 'released'}{' '}
                  {data.released_year}
                </p>
              </div>
              <div className="col-12 col-md-8 mb-4">
                <div className="project-desc-project-name">
                  <h1>{data.title} </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 order-2 order-md-1">
                <div className="services mb-md-5 pb-md-4">
                  <p className="project-desc-heading">
                    {project
                      ? project.project_static_content_all_projects_section
                          .project_static_content_services
                      : 'services'}
                  </p>
                  <p className="project-desc-subheading">
                    {data.services
                      ? data.services.map((_, index) => {
                          return (
                            <label key={index}>
                              {data.services.length !== index + 1
                                ? `${changeCases(_)},`
                                : changeCases(_)}{' '}
                            </label>
                          )
                        })
                      : ''}
                  </p>
                </div>
                <div className="languages">
                  <p className="project-desc-heading">
                    {project
                      ? project.project_static_content_all_projects_section
                          .projects_static_content_languages
                      : 'languages'}
                  </p>
                  <p className="project-desc-subheading">
                    {data.languages
                      ? data.languages.map((_, index) => {
                          return (
                            <label key={index}>
                              {data.languages.length !== index + 1
                                ? `${changeCases(_)},`
                                : changeCases(_)}{' '}
                            </label>
                          )
                        })
                      : ''}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-8 order-1 order-md-2">
                <div className="project-desc-subhead-2-content">
                  <p>
                    {parse(
                      data.project_description !== undefined
                        ? data.project_description
                        : '',
                    )}
                  </p>
                </div>
                <div className="row pt-md-4 mb-4 mb-md-0">
                  <div className="col-5 col-md-6">
                    <div className="developer">
                      <p className="project-desc-heading">
                        {project
                          ? project.project_static_content_all_projects_section
                              .projects_static_content_developer
                          : 'Devloper'}
                      </p>
                      <p className="project-desc-subheading">
                        {data.project_developer}
                      </p>
                    </div>
                  </div>
                  <div className="col-7 col-md-6">
                    <div className="publisher">
                      <p className="project-desc-heading">
                        {project
                          ? project.project_static_content_all_projects_section
                              .projects_static_content_publisher
                          : 'Publisher'}
                      </p>
                      <p className="project-desc-subheading">
                        {data.publisher}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* project desc -sec 1 end */}
        {/* project-desc-sec-2 start */}

        <section className="project-desc-sec-2">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="pr-desc-item">
                  {data.project_media !== null &&
                  data.project_media !== undefined ? (
                    data.project_media[0]?.project_image !== null &&
                    data.project_media[0]?.project_image !== false &&
                    data.project_media[0]?.project_image !== undefined ? (
                      <div className="img position-relative">
                        <div className="borderLines top"></div>
                        <div className="borderLines right"></div>
                        <div className="borderLines bottom"></div>
                        <div className="borderLines left"></div>
                        <img
                          src={data.project_media[0]?.project_image}
                          alt=""
                        />
                      </div>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  {data.project_media !== null &&
                  data.project_media !== undefined ? (
                    data.project_media[0]?.project_video !== null &&
                    data.project_media[0]?.project_video !== undefined &&
                    data.project_media[0]?.project_video !== false ? (
                      <div className="img video">
                        <video
                          src={data.project_media[0]?.project_video}
                          controls
                        />
                      </div>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  {data.project_media !== null &&
                  data.project_media !== undefined ? (
                    data.project_media[0]?.project_video_url !== null &&
                    data.project_media[0]?.project_video_url !== false &&
                    data.project_media[0]?.project_video_url !== undefined &&
                    data.project_media[0]?.project_video_url.length > 0 ? (
                      <div className="projectdesc iframe_video">
                        {parse(data.project_media[0]?.project_video_url)}
                      </div>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  {testimonials !== false ? (
                    <div className="project-desc-sec-2-subsec-1">
                      <Swiper
                        slidesPerView={'1'}
                        loop={true}
                        className="mySwiper"
                        onSlideChange={(e) => setactiveindex(e.realIndex)}
                        // navigation={true}
                        // autoplay={{
                        //   delay: 500,
                        //   disableOnInteraction: false,
                        // }}
                        // modules={[Autoplay]}

                        onSwiper={(s) => {
                          setSwipe(s)
                          setactiveindex(s.realIndex)
                        }}
                      >
                        {testimonials.map((test, index) => (
                          <>
                            <SwiperSlide key={index}>
                              <p className="testimonial-message">
                                {' '}
                                {parse(test.testimonial_message)}
                              </p>
                            </SwiperSlide>
                          </>
                        ))}
                      </Swiper>
                      <div
                        ref={testimonialRef}
                        className="project-desc-sec-2-subsec-2"
                      >
                        <p className="tagline">
                          <span className="horiz-line" />

                          <span className="wrap">
                            {testimonials !== false
                              ? testimonials.map((test, index) => (
                                  <span
                                    className="testimonial-animetext"
                                    key={index}
                                  >
                                    {testimonials.length > 0
                                      ? testimonials[index].testimonial_name
                                          .split('')
                                          .map((d, i) => {
                                            return d === ' ' ? (
                                              <span
                                                className={`testi testimonial-letters${index + 1}`}
                                                key={i}
                                              >
                                                &nbsp;
                                              </span>
                                            ) : (
                                              <span
                                                className={`testi testimonial-letters${index + 1}`}
                                                key={i}
                                              >
                                                {d}
                                              </span>
                                            )
                                          })
                                      : ''}
                                    <span
                                      className={`testi testimonial-letters${index + 1}`}
                                    >
                                      ,
                                    </span>
                                    &nbsp;
                                    {testimonials.length > 0
                                      ? testimonials[activeindex]?.company_name
                                          .split('')
                                          .map((d, i) => {
                                            return d === ' ' ? (
                                              <span
                                                className={`testi testimonial-letters${index + 1}`}
                                                key={i}
                                              >
                                                &nbsp;
                                              </span>
                                            ) : (
                                              <span
                                                className={`testi testimonial-letters${index + 1}`}
                                                key={i}
                                              >
                                                {d}
                                              </span>
                                            )
                                          })
                                      : ''}
                                  </span>
                                ))
                              : ''}
                          </span>
                        </p>
                        {testimonials !== false ? (
                          <p>
                            <span
                              className={`lefttest ${activeindex === 0 ? 'lefttest-inactive' : 'lefttest-active'}`}
                              onClick={() => {
                                if (activeindex !== 0) prevSlide()
                              }}
                            >
                              &nbsp; &nbsp;{' '}
                            </span>
                            <span className="active">0{activeindex + 1}/</span>0
                            {testimonials.length}{' '}
                            <span
                              className={`righttest ${activeindex === testimonials.length - 1 ? 'righttest-inactive' : 'righttest-active'}`}
                              onClick={() => {
                                if (activeindex !== testimonials.length - 1)
                                  nextSlide()
                              }}
                            >
                              &nbsp;
                            </span>
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {(testimonials !== false || testimonials === false) &&
              show === false ? (
                <div className="project-desc-sec-3 align-items-center">
                  <div
                    onClick={(e) => controlprevslide(e)}
                    className={'d-flex align-items-center'}
                  >
                    {data.prev_project === '' ? (
                      ''
                    ) : (
                      <>
                        <span>
                          {project
                            ? project
                                .project_static_content_all_projects_section
                                .projects_static_content_prev
                            : 'PREV'}
                        </span>
                        &nbsp;
                        <span className="dot"></span>
                      </>
                    )}
                  </div>

                  <div
                    onClick={(e) => {
                      controlnextslide(e)
                    }}
                    className="d-flex align-items-center"
                  >
                    {data.next_project === '' ? (
                      ''
                    ) : (
                      <>
                        <span className="dot"></span>
                        &nbsp;
                        <span>
                          {project
                            ? project
                                .project_static_content_all_projects_section
                                .projects_static_content_next
                            : 'NEXT'}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
              {testimonials !== false && show === true ? (
                <div className="project-desc-sec-3 align-items-center">
                  <div
                    onClick={(e) => controlprevslide(e)}
                    className={'d-flex align-items-center'}
                    style={{ opacity: testimonials.length > 1 ? '1' : '0.3' }}
                  >
                    {data.prev_project === '' ? (
                      ''
                    ) : (
                      <>
                        <span>
                          {project
                            ? project
                                .project_static_content_all_projects_section
                                .projects_static_content_prev
                            : 'PREV'}
                        </span>
                        &nbsp;
                        <span className="dot"></span>
                      </>
                    )}
                  </div>

                  <div
                    onClick={(e) => {
                      controlnextslide(e)
                    }}
                    className="d-flex align-items-center"
                    style={{ opacity: testimonials.length > 1 ? '1' : '0.3' }}
                  >
                    {data.next_project === '' ? (
                      ''
                    ) : (
                      <>
                        <span className="dot"></span>
                        &nbsp;
                        <span>
                          {project
                            ? project
                                .project_static_content_all_projects_section
                                .projects_static_content_next
                            : 'NEXT'}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
              <div ref={buttonRef} className="project-desc-sec-4">
                <div
                  onMouseMove={(e) => freeze(e, 1)}
                  onMouseLeave={(e) => unfreeze(e, 1)}
                  className="project-desc-left"
                >
                  <h4 className="d-none d-lg-block">
                    {data.prev_project
                      ? data.prev_project.title === undefined
                        ? ''
                        : data.prev_project.title
                      : ''}
                  </h4>

                  {data.project_order === 1 ? (
                    ''
                  ) : (
                    <>
                      <div
                        id={`cursor-project`}
                        className={`cursor-project${1}`}
                      >
                        <button
                          onClick={(e) => projectnavigation(prevurl)}
                          className="btn-left-side_desk"
                        ></button>
                      </div>
                      <button
                        onClick={(e) => projectnavigation(prevurl)}
                        className="btn-left-side_mobile"
                      >
                        {
                          data?.project_static_content
                            ?.projects_static_content_prev
                        }
                        <br></br>
                        {
                          project?.project_static_content_topsection
                            ?.project_static_content_project
                        }
                        <br />
                        <svg
                          width="28"
                          height="19"
                          viewBox="0 0 28 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M27.248 9.54432L27.248 10.0443L26.748 10.0443L2.61123 10.0443L2.47807 10.0443L9.66996 17.2362L10.0235 17.5898L9.31641 18.2969L8.96285 17.9433L0.917247 9.89772L0.563694 9.54416L0.917247 9.19061L8.96285 1.14501L9.31641 0.791451L10.0235 1.49856L9.66996 1.85211L2.47774 9.04433L2.61123 9.04433L26.248 9.04432L26.248 3.78774L26.248 3.28774L27.248 3.28774L27.248 3.78774L27.248 9.54432ZM2.11123 9.41084L2.11123 9.67748L1.97791 9.54416L2.11123 9.41084Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </>
                  )}
                </div>

                <div
                  onMouseMove={(e) => freeze(e, 2)}
                  onMouseLeave={(e) => unfreeze(e, 2)}
                  className="project-desc-right"
                >
                  <h4 className="d-none d-lg-block">
                    {data.next_project
                      ? data.next_project.title === undefined
                        ? ''
                        : data.next_project.title
                      : ''}
                  </h4>
                  {data.next_project === '' ? (
                    ''
                  ) : (
                    <>
                      <div
                        id={`cursor-project`}
                        className={`cursor-project${2}`}
                      >
                        <button
                          onClick={(e) => projectnavigation(nexturl)}
                          className="btn-right-side_desk"
                        ></button>
                      </div>
                      <button
                        onClick={(e) => projectnavigation(nexturl)}
                        className="btn-right-side_mobile"
                      >
                        {
                          data?.project_static_content
                            ?.projects_static_content_next
                        }
                        <br></br>
                        {
                          project?.project_static_content_topsection
                            ?.project_static_content_project
                        }
                        <br />
                        <svg
                          width="28"
                          height="19"
                          viewBox="0 0 28 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.923829 9.54432L0.923828 10.0443L1.42383 10.0443L25.5606 10.0443L25.6938 10.0443L18.5019 17.2362L18.1484 17.5898L18.8555 18.2969L19.209 17.9433L27.2546 9.89772L27.6082 9.54416L27.2546 9.19061L19.209 1.14501L18.8555 0.791451L18.1484 1.49856L18.5019 1.85211L25.6941 9.04433L25.5606 9.04433L1.92383 9.04432L1.92383 3.78774L1.92383 3.28774L0.923829 3.28774L0.923829 3.78774L0.923829 9.54432ZM26.0606 9.41084L26.0606 9.67748L26.194 9.54416L26.0606 9.41084Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TestomonialAndContact />
        <Footer />
      </>
    )
  }

  // const imageStyle = {
  //   transform: cardvalue === 'false' || cardvalue === null ? 'scale(1)' : '',
  //   transition: 'transform 0.5s ease', // Transition for the scaling effect
  //   cursor: 'pointer',
  // }

  return (
    <>
      {data?.is_banner_image ? (
        <div className="main-project-banner">
          <div className="project-banner">
            <img
              src={location?.state?.banner_image || data?.banner_image}
              alt=""
              className="transition-banner-image"
              // className={`transition-banner-image  scale-image ${cardvalue === 'true' && imageLoaded === true ? 'scaled' : ''} `}
              ref={imageRef}
              // style={imageStyle}
              onLoad={() => setImageLoaded(true)}
            />

            {/* centered played button hidden by aryan */}
            {/* <div className="project-banner-button">
            Play
          <span className="bg_fill"></span>
          </div> */}
            {/* <span className="bg_fill"></span>  */}
          </div>
        </div>
      ) : (
        <div className="main-project-banner-video">
          <div className="project-banner-video">
            <video
              controls
              className="project-video-thumbnail"
              src={data?.banner_image}
            ></video>
          </div>
        </div>
      )}
      <div className="main-project-content">
        {imageLoaded || !data?.is_banner_image ? displayContent() : <></>}
      </div>
    </>
  )
})

export default ProjectDescription
