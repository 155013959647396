import '../../styles/about/about.css'
import TestomonialAndContact from '../homepage/section8'
import Footer from '../../components/footer/footer'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/pagination'
import 'swiper/css'
import anime from 'animejs'
import 'swiper/css/bundle'
import SliderButtons from '../Buttons/SliderButtons'
import { Navigation } from 'swiper/modules'
import { useNavigate } from 'react-router-dom'
import { Fragment, useRef, useState } from 'react'
import { forwardRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../services/axiosInstance'
import { devapiUrl } from '../../constants/devapi'
import parse from 'html-react-parser'
import { changeLoaderState } from '../../actions/lngactions'
import Draggable from 'react-draggable'
import { getLanguage } from '../../constants/urls'
import Notfound from '../NotFound/Notfound'
import { langCodes } from '../../constants/country'

const langCode1 = ['zh-chs', 'zh-cht', 'ja', 'ko', 'fr-ca']

const About = forwardRef((props, ref) => {
  let bool = false
  const loadingState = useSelector((state) => state.loadingState)
  const dispatch = useDispatch()
  const timelineRef = useRef(null)
  const [data, setdata] = useState([])
  let langcode = useSelector((state) => state.lang)
  const [aboutdata, Setaboutdata] = useState(null)
  const [teamdata, Setteamdata] = useState(null)
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 })
  const [isHovered, setIsHovered] = useState(false)
  const [timeLineData, setTimeLineData] = useState([])
  const [currentTestimonial, setCurrentTestimonial] = useState('')
  const [currentObj, setCurrentObj] = useState({})
  const [initialActiveEventIndex, setInitialActiveEventIndex] = useState()

  const [initialActivePointPosition, setInitialActivePointPosition] = useState()

  const [render, setRender] = useState([])
  const [firstActiveObj, setFirstActiveObj] = useState()
  const [lastActiveObj, setLastActiveObj] = useState()
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isMatchingScreenSize, setIsMatchingScreenSize] = useState(false)
  const [isMatchingScreenSize2, setIsMatchingScreenSize2] = useState(false)

  const [isNotFound, setIsNotFound] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(1)
  const [isDragOnDiv, setIsDragOnDiv] = useState(false)

  let count = 0
  let activeTemp = 0
  let activeDotBool = false
  // const [monthAndYearOfEvent, setMonthAndYearOfEvent] = useState('')
  // const [eventTitle, setEventTitle] = useState('')
  // const [eventDescription, setEventDescription] = useState('')
  const cursorref = useRef(null)
  var countinServices = 1

  const handleMouseMove = (e) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect()
      setCursorPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top })
    }
  }

  let navigate = useNavigate()
  const getLang1 = () => {
    let param = window.location.href.split('/')
    for (let i = 0; i < param.length; i++) {
      if (langCode1.includes(param[i])) return param[i]
    }
    return ''
  }

  const servicenavigation = (url, title) => {
    url = url.split('-').join('').toLowerCase()
    title = title.split('-').join('').toLowerCase()
    const language = getLang1()

    if (language === '') navigate(`/audio/services/${url}/${title}`)
    else navigate(`/${language}/audio/services/${url}/${title}`)
  }

  let param = window.location.href.split('/')
  let url = param[param.length - 1]

  if (url === 'about') document.title = 'SIDE - About'
  if (ref != null) ref.current = 1

  const animation = () => {
    let head = document.querySelector('.Headsection')
    if (head != null) head.style.opacity = 0

    if (loadingState === false) {
      count = count + 1
    }
    // if(count==4){
    if (head != null) head.style.opacity = 1
    anime
      .timeline({ loop: false })
      .add({
        targets: '.about-heading .headTag1',
        translateY: [200, 0],
        easing: 'easeOutExpo',
        duration: 3400,
      })
      .add({
        targets: '.headTag1',
        opacity: 1,
        duration: 100,
        easing: 'easeOutExpo',
        delay: 10,
        loop: false,
      })
    anime
      .timeline({ loop: false })
      .add({
        targets: '.about-heading .headTag2',
        translateY: [200, 0],
        easing: 'easeOutExpo',
        duration: 3400,
      })
      .add({
        targets: '.headTag2',
        opacity: 1,
        duration: 100,
        easing: 'easeOutExpo',
        delay: 10,
        loop: false,
      })

    anime
      .timeline({ loop: false })
      .add({
        targets: '.about-subheading',
        opacity: 0,
        duration: 1000,
      })
      .add({
        targets: '.about-subheading',
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 5400,
      })
    // }
  }
  const handleswiperclick = (url) => {
    //  Setteamloading(false)
    url = url.toLowerCase()
    // url = url.replaceAll(" ", "")

    const language = getLang1()
    if (language === '') navigate(`/audio/teams/${url}`)
    else navigate(`/${language}/audio/teams/${url}`)
  }
  const switchTimeLine = (param) => {
    let arr = timeLineData

    if (currentObj.id === firstActiveObj.id && param === 'PREV') {
      handleDivClick(arr.findIndex((el) => el.id === lastActiveObj.id))
      setTimeout(() => {
        setCurrentObj(lastActiveObj)
      }, 400)
      return
    }
    let id =
      param === 'NEXT'
        ? currentObj.id + 1
        : param === 'PREV'
          ? currentObj.id - 1
          : null
    let existingId
    if (id !== null) {
      existingId = arr.findIndex((story) => story.id === id)
    }
    if (existingId !== -1) {
      let obj = {}
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].story_isactive === 'Yes' && id === arr[i].id) {
          arr[i].isDisplayed = true
          obj = arr[i]
        } else {
          arr[i].isDisplayed = false
        }
      }

      setTimeLineData(arr)
      setCurrentTestimonial(obj.story_testimonial)

      setTimeout(() => {
        setCurrentObj(obj)
      }, 400)
      handleDivClick(arr.findIndex((el) => el.id === obj.id))
    }
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
    dispatch(changeLoaderState(true))
    // animation()
    //  getDataTimeLineApi(langcode)
  }, [])
  const handleActiveEventPosition = () => {
    const element = document.getElementById('initialActiveEventDiv')
    // document.querySelector('.prevarrow')?.setAttribute('disabled', 'disabled')

    if (element)
      setInitialActivePointPosition(element?.getBoundingClientRect()?.left)

    const newZoomLevel = window.devicePixelRatio
    setZoomLevel(newZoomLevel)
  }
  useEffect(() => {
    if (!initialActivePointPosition) handleActiveEventPosition()

    window.addEventListener('resize', handleActiveEventPosition)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleActiveEventPosition)
    }
  }, [render])
  const handleMouseEnter = () => {
    setIsHovered(true)
    setIsDragOnDiv(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
    setIsDragOnDiv(false)
  }
  const getDataTimeLineApi = (lang) => {
    axiosInstance
      .get(`${devapiUrl}${lang}/ourstories`)
      .then((res) => {
        let arr = res.data.ourstories
        let index = arr.length - 1
        for (let i = arr.length - 1; i >= 0; i--) {
          if (
            arr[i].story_isactive === 'Yes' &&
            arr[i].story_isevent === 'Yes'
          ) {
            index = i
            break
          }
        }
        for (let i = 0; i < arr.length - 1; i++) {
          if (
            arr[i].story_isactive === 'Yes' &&
            arr[i].story_isevent === 'Yes'
          ) {
            setFirstActiveObj(arr[i])
            break
          }
        }
        arr[index].isDisplayed = true
        setCurrentObj(arr[index])
        setLastActiveObj(arr[index])

        setCurrentTestimonial(arr[index].story_testimonial)
        // generateOtherStories(arr)
        setTimeLineData(arr)
        setTimeout(() => {
          dispatch(changeLoaderState(false))
        }, 1000)
        setInitialActiveEventIndex(index)
        setRender(arr)
      })
      .catch((err) => {
        console.error(`Error from timeline API`)
      })
  }

  let counter = 0

  const setEventView = (event, obj) => {
    let arr = timeLineData
    let curObj = {}
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].story_isactive === 'Yes' && obj.id === arr[i].id) {
        arr[i].isDisplayed = true
        curObj = arr[i]
      } else {
        arr[i].isDisplayed = false
      }
    }

    setCurrentTestimonial(curObj.story_testimonial)
    setTimeout(() => {
      setCurrentObj(curObj)
    }, 400)
    setTimeLineData(arr)
  }

  useEffect(() => {
    const mainDiv = document.getElementById('mainDiv')
    if (mainDiv) {
      mainDiv.style.transform = `translateX(0px)`
    }
    const language = getLanguage()
    window.scrollTo({ top: 0, left: 0 })
    getservicedata(language)
    getaboutdata(language)
    getaboutteamdata(language)
    getDataTimeLineApi(language)
  }, [langcode])

  const unfreeze = (e, index, i) => {
    const cursor = document.querySelector(`.cursor${index}${i}`)

    if (cursor != null) {
      cursor.style.display = 'none'
    }
  }
  const freeze = (e, index, i) => {
    const cursor = document.querySelector(`.cursor${index}${i}`)

    let x = e.pageX - window.scrollX - 5
    let y = e.pageY - window.scrollY - 70

    if (cursor != null) {
      if (cursor.style.display === 'none') {
        cursor.style.display = 'block'
        cursor.style.left = `${x}px`
        cursor.style.top = `${y}px`
      }
    }
  }
  const getservicedata = (lang) => {
    axiosInstance
      .get(`${devapiUrl}${lang}/aboutservices`)
      .then((res) => {
        setdata(res.data.aboutservices)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  const getaboutdata = (lang) => {
    axiosInstance.get(`${devapiUrl}${lang}/side-pages/about`).then((res) => {
      Setaboutdata(res.data.about)
    })
  }
  const getaboutteamdata = (lang) => {
    let arr = []

    // location_image:'../img/ws-banner.png',
    // location_name:'London'

    axiosInstance.get(`${devapiUrl}${lang}/aboutteams`).then((res) => {
      // Setteamdata(res.data.teamexpand)
      res.data.teamexpand.forEach((data, index) => {
        arr.push({
          location_image: data.team_studio_images[0].studio_image_544x738,
          location_name: data.title,
          location_url: data.about_team_url_title,
          id: index,
        })
      })
      if (bool === false && langcode === getLanguage())
        setTimeout(() => {
          animation()
        }, 1000)
      bool = true
      if (arr.length > 0) {
        Setteamdata(arr)
      }
      setTimeout(() => {
        // Setaboutloading(true)
      }, 700)
    })
  }
  const handleDivClick = (index) => {
    const clickedDiv = document.querySelector(`.child-div-${index}`)
    const targetDiv = document.getElementById('initialActiveEventDiv')
    if (targetDiv) {
      const clickedDivRect = clickedDiv?.getBoundingClientRect()
      const targetDivRect = targetDiv?.getBoundingClientRect()

      const distance = targetDivRect?.left - clickedDivRect?.left

      const mainDiv = document.getElementById('mainDiv')

      if (mainDiv) {
        mainDiv.style.transform = `translateX(${distance}px)`
      }

      setPosition({ x: distance, y: 0 })
    }
  }

  function findClosestIndex(array, target) {
    let closestIndex = 0
    let closestDifference = Math.abs(target - array[0])
    array.forEach((value, i) => {
      const currentDifference = Math.abs(target - value)

      if (currentDifference < closestDifference) {
        closestIndex = i
        closestDifference = currentDifference
      }
    })

    return closestIndex
  }
  const handleDrag = (e, ui) => {
    const newX = position.x + ui.deltaX
    setPosition({ x: Math.max(newX, 0), y: position.y })

    setIsHovered(true)
    const elements = document.querySelectorAll('[data-event-index]')

    const elementsArray = Array.from(elements)

    const elementBounderyClients = elementsArray.map(
      (el) => el.getBoundingClientRect().left,
    )

    const closestValueIndex = findClosestIndex(
      elementBounderyClients,
      initialActivePointPosition,
    )

    const closestElement = elementsArray[closestValueIndex]

    const objIndex = closestElement.dataset.eventIndex

    let curObj = timeLineData[objIndex]

    setCurrentTestimonial(curObj.story_testimonial)
    setCurrentObj(curObj)
  }
  const handleDragStop = () => {
    if (!isDragOnDiv) setIsHovered(false)

    const elements = document.querySelectorAll('[data-event-index]')

    const elementsArray = Array.from(elements)

    const elementBounderyClients = elementsArray.map(
      (el) => el.getBoundingClientRect().left,
    )

    const closestValueIndex = findClosestIndex(
      elementBounderyClients,
      initialActivePointPosition,
    )

    const closestElement = elementsArray[closestValueIndex]

    const objIndex = closestElement.dataset.eventIndex

    let curObj = timeLineData[objIndex]

    setCurrentTestimonial(curObj.story_testimonial)
    setCurrentObj(curObj)

    handleDivClick(objIndex)
  }

  let count1 = useRef(0)
  let count2 = useRef(0)
  let count3 = useRef(0)
  let count4 = useRef(0)
  const sideline1ref = useRef(null)
  const sideline2ref = useRef(null)
  const sideline3ref = useRef(null)
  const sideline4ref = useRef(null)
  var sideline2 = anime.timeline({ loop: false, autoplay: false }).add({
    targets: '.about_sideline2',
    translateY: [280, 180],
    easing: 'easeOutExpo',
    duration: 3000,
    delay: 100,
  })
  var sideline1 = anime.timeline({ loop: false, autoplay: false }).add({
    targets: '.about_sideline1',
    translateY: [700, 300],
    easing: 'easeOutExpo',
    duration: 3000,
    delay: 100,
  })
  var sideline3 = anime.timeline({ loop: false, autoplay: false }).add({
    targets: '.about_sideline3',
    translateY: [550, 350],
    easing: 'easeOutExpo',
    duration: 3000,
    delay: 100,
  })

  var sideline4 = anime.timeline({ loop: false, autoplay: false }).add({
    targets: '.about_sideline4',
    translateY: [550, 350],
    easing: 'easeOutExpo',
    duration: 3000,
    delay: 100,
  })
  useEffect(() => {
    let observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.target.className === 'about_sideline1') {
            if (entry.intersectionRatio === 1) {
              if (count1.current === 0) sideline1.play()
              count1.current = count1.current + 1
            } else if (entry.intersectionRatio === 0) {
              count1.current = 0
            } else count1.current = count1.current + 1
          }
          if (entry.target.className === 'about_sideline2') {
            if (entry.intersectionRatio === 1) {
              if (count2.current === 0) {
                sideline2.play()
              }
              count2.current = count2.current + 1
            } else if (entry.intersectionRatio === 0) {
              count2.current = 0
            } else count2.current = count2.current + 1
          } else if (entry.target.className === 'about_sideline3') {
            if (entry.intersectionRatio === 1) {
              // sideline2.play()

              if (count3.current === 0) {
                sideline3.play()
              }
              count3.current = count3.current + 1
            } else if (entry.intersectionRatio === 0) {
              count3.current = 0
            } else count3.current = count3.current + 1
          } else if (entry.target.className === 'about_sideline4') {
            if (entry.intersectionRatio === 1) {
              // sideline2.play()

              if (count4.current === 0) {
                sideline4.play()
              }
              count4.current = count4.current + 1
            } else if (entry.intersectionRatio === 0) {
              count4.current = 0
            } else count4.current = count4.current + 1
          }
        })
      },

      { threshold: 1 },
    )

    window.addEventListener('scroll', (e) => {
      // Get the new Value

      let curs = document.querySelectorAll('#cursor-service')
      if (curs != null) {
        for (const e of curs) {
          if (e != null) e.style.display = 'none'
        }
      }
      if (sideline1ref.current !== null) observer.observe(sideline1ref.current)
      if (sideline2ref.current !== null) observer.observe(sideline2ref.current)
      if (sideline3ref.current !== null) observer.observe(sideline3ref.current)
      if (sideline4ref.current !== null) observer.observe(sideline4ref.current)
      // if (cursorref.current != null) observer1.observe(cursorref.current)
      // Update the old value
    })
  })

  useEffect(() => {
    const mediaQuery = window.matchMedia(
      '(min-width: 575px) and (max-width: 990px)',
    )
    const mediaQuery2 = window.matchMedia(
      '(min-width: 389px) and (max-width: 500px)',
    )

    setIsMatchingScreenSize(mediaQuery.matches)
    setIsMatchingScreenSize2(mediaQuery2.matches)
    const handleMediaQueryChange = (event) => {
      setIsMatchingScreenSize(event.matches)
    }
    const handleMediaQueryChange2 = (event) => {
      setIsMatchingScreenSize2(event.matches)
    }

    mediaQuery.addEventListener('change', handleMediaQueryChange)
    mediaQuery2.addEventListener('change', handleMediaQueryChange2)

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange)
      mediaQuery2.removeEventListener('change', handleMediaQueryChange2)
    }
  }, [])

  useEffect(() => {
    let param = window.location.href.split('/')
    if (param.length === 5) {
      if (!langCodes.includes(param[3]) && param[3] !== '') {
        setIsNotFound(true)
      } else {
        setIsNotFound(false)
      }
    }
  }, [])
  // if (isNotFound) {
  //   return <Notfound />
  // }
  return (
    <>
      {/* <div className='about-container'>
        <div className='section-1'>
            <div className='about-header'>
         <h1>We make<br/>Waves</h1>
         </div>

         <div className='about-content'>
         <span />
            <p>We’re here to bring your vision to life.<br/> Learn about our people and their specialties.</p>
         </div>
         
        </div>
     </div> */}
      {/* <Loader /> */}

      <div className="about_page">
        <div className="about-container">
          <span className="leftshadow"></span>
          <div className="Headsection container d-flex align-items-end">
            <div className="head_title">
              <div className="about-heading">
                <h1 className="headTag1">
                  {aboutdata ? aboutdata.about_we_make : 'We make'}
                </h1>
              </div>

              <div className="about-heading">
                <h1 className="headTag2">
                  {aboutdata ? aboutdata.about_waves : 'Waves'}
                </h1>
              </div>
            </div>
            <div className="sub_title mb-2">
              <span className="about-subheading">
                <span className="line_before"></span>
                {aboutdata ? (
                  // parse(aboutdata.about_description)
                  aboutdata.about_description
                    .split('\n')
                    .map(function (item, idx) {
                      return (
                        <p key={idx}>
                          {item}
                          <br />
                        </p>
                      )
                    })
                ) : (
                  <p>
                    We're here to bring your vision to life. <br /> Learn about1
                    our people and their specialties.
                  </p>
                )}
                {/* <p>We're here to bring your vision to life. <br /> Learn about our people and their specialties.</p> */}
              </span>
            </div>
          </div>

          <div className="ourteams_section">
            <div ref={sideline1ref} className="about_sideline1">
              <div className="teamslideup_line">
                <div className="sidelinecontent">
                  <span className="filldot"></span>
                  <p className="text">
                    01 {aboutdata ? aboutdata.about_our_teams : 'Our teams '}
                  </p>
                  <span className="line"></span>
                  <span className="outlinedot"></span>
                  <span className="outlinedot"></span>
                  <span className="outlinedot"></span>
                </div>
              </div>
            </div>
            <div className="ourteam_swiper">
              <Swiper
                slidesPerView={3}
                modules={[Navigation]}
                loop={true}
                allowTouchMove={false}
                rebuildonupdate={true}
                spaceBetween={60}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  1030: {
                    slidesPerView: 3,
                  },
                }}
                navigation={{
                  prevEl: '.prevarrow',
                  nextEl: '.nextarrow',
                }}
                onTransitionStart={(swiper) => {
                  // let slide = document.querySelector('.swiper-slide-next')
                  // slide.style.css={{'opacity'='1';}}
                  // .opacity=1;
                  // slide.style.filter="greysacle(0)"
                }}
                onSlideChange={(swiper) => {
                  var slidelength =
                    document.querySelectorAll('.swipercontent').length
                  if (slidelength - 1 === swiper.realIndex) {
                    document
                      .querySelector('.nextarrow')
                      .classList.add('disabled')
                    document
                      .querySelector('.nextarrow')
                      .setAttribute('disabled', 'disabled')
                  } else {
                    document
                      .querySelector('.nextarrow')
                      .classList.remove('disabled')
                    document
                      .querySelector('.nextarrow')
                      .removeAttribute('disabled')
                  }

                  if (swiper.realIndex === 0) {
                    document
                      .querySelector('.prevarrow')
                      .classList.add('disabled')
                    document
                      .querySelector('.prevarrow')
                      .setAttribute('disabled', 'disabled')
                  } else {
                    document
                      .querySelector('.prevarrow')
                      .classList.remove('disabled')
                    document
                      .querySelector('.prevarrow')
                      .removeAttribute('disabled')
                  }
                }}
                className="mySwiper"
              >
                {/* third imple */}
                {teamdata ? (
                  teamdata.map((test, index) => (
                    <SwiperSlide key={index}>
                      <div
                        onClick={(e) => handleswiperclick(test.location_url)}
                        style={{ cursor: 'pointer' }}
                        className="swipercontent"
                      >
                        <div className="button_cover">
                          <img src={test.location_image} alt="side" />
                          <button
                            onClick={(e) =>
                              handleswiperclick(test.location_url)
                            }
                            className="viewbtn d-lg-none d-flex"
                          >
                            {aboutdata
                              ? aboutdata.about_view_team
                              : 'View Team'}
                          </button>
                        </div>
                        <h5 className="">{test.location_name}</h5>
                      </div>
                    </SwiperSlide>
                  ))
                ) : (
                  <SwiperSlide></SwiperSlide>
                )}
              </Swiper>

              <div className="swiper_arrows arrows d-flex">
                <SliderButtons
                  className="nextarrow"
                  width={16}
                  height={11}
                  path={
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                      fill="#3e3e3e"
                    />
                  }
                />
                <SliderButtons
                  disabled
                  className="prevarrow disabled"
                  width={16}
                  height={11}
                  path={
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.8945 5.9999L15.8945 5.4999L15.3945 5.4999L2.60523 5.4999L6.17191 1.93322L6.52547 1.57966L5.81836 0.872558L5.46481 1.22611L1.04448 5.64644L0.690922 5.99999L1.04448 6.35355L5.46481 10.7739L5.81836 11.1274L6.52547 10.4203L6.17191 10.0668L2.60504 6.4999L14.8945 6.4999L14.8945 9.16263L14.8945 9.66263L15.8945 9.66263L15.8945 9.16263L15.8945 5.9999Z"
                      fill="#3e3e3e"
                    />
                  }
                />
              </div>
            </div>
          </div>

          <div className="ourservices_title">
            <h1 className="d-lg-flex d-none">
              {aboutdata ? aboutdata.about_our_services : 'Our Services1'}
            </h1>
            <h2 className="d-lg-none d-flex">
              {aboutdata ? aboutdata.about_service_what : 'What1'}{' '}
              <span>
                {aboutdata ? aboutdata.about_service_we_do : 'we1 do'}
              </span>
            </h2>
            <p>
              {aboutdata
                ? aboutdata.about_our_service_description
                : 'Sed viverra faucibus rutrum ut tellus. Faucibus arcu, porttitor diam sem. Amet praesent turpis morbi amet.'}
            </p>
          </div>

          <div className="services_list">
            <div ref={sideline2ref} className="about_sideline2">
              <div className="serviceslideup_line">
                <div className="sidelinecontent">
                  <span className="outlinedot"></span>
                  <span className="filldot"></span>
                  <p className="text">
                    02{' '}
                    {aboutdata ? aboutdata.about_our_services : 'Our Services'}
                  </p>
                  <span className="line"></span>
                  <span className="outlinedot"></span>
                  <span className="outlinedot"></span>
                </div>
              </div>
            </div>
            <div ref={cursorref} className="container row">
              {data?.length > 0
                ? data.map((services, index) => {
                    return (
                      <Fragment key={index}>
                        {services.sub_service?.length > 0
                          ? services.sub_service.map((s, i) => {
                              countinServices++
                              return (
                                <div className="col-lg-5" key={i}>
                                  {/* <div onMouseEnter={(e) => freeze(e, countinServices)} onMouseLeave={(e) => unfreeze(e, countinServices)} */}
                                  <div
                                    onMouseLeave={(e) => unfreeze(e, index, i)}
                                    className={
                                      countinServices % 2 === 0
                                        ? 'service_block leftblock mt-2'
                                        : 'service_block rightblock mt-2'
                                    }
                                  >
                                    <div className="hover_img">
                                      <img
                                        src={services.service_image}
                                        alt="side"
                                      />
                                    </div>

                                    <div
                                      onMouseMove={(e) => freeze(e, index, i)}
                                      className="content"
                                    >
                                      <h2>{s.sub_service_title}</h2>
                                      <p>{s.about_service_description}</p>
                                      <div
                                        id={`cursor-service`}
                                        className={`cursor${index}${i}`}
                                      >
                                        <button
                                          onClick={(e) => {
                                            servicenavigation(
                                              services.service_url,
                                              s.sub_service_url,
                                            )
                                          }}
                                        >
                                          <svg
                                            width="30"
                                            height="20"
                                            viewBox="0 0 16 11"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                                              fill="#313131"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className="d-lg-none d-flex"
                                      onClick={(e) => {
                                        servicenavigation(
                                          services.service_url,
                                          s.sub_service_title,
                                        )
                                      }}
                                    >
                                      <svg
                                        width="17"
                                        height="12"
                                        viewBox="0 0 17 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M0.133789 6.0547L0.133789 6.5547L0.633789 6.5547L15.085 6.5547L10.9938 10.6459L10.6402 10.9994L11.3473 11.7065L11.7009 11.353L16.6456 6.40824L16.9992 6.05469L16.6456 5.70114L11.7009 0.756391L11.3473 0.402838L10.6402 1.10994L10.9938 1.4635L15.085 5.5547L1.13379 5.5547L1.13379 2.51676L1.13379 2.01676L0.133789 2.01676L0.133789 2.51676L0.133789 6.0547Z"
                                          fill="#FEFBF7"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          : ''}
                      </Fragment>
                    )
                  })
                : null}
              {data ? (
                <>
                  {data.reduce(
                    (acc, subArray) => acc + subArray?.sub_service?.length,
                    0,
                  ) %
                    2 ===
                  1 ? (
                    <>
                      {' '}
                      <div className="col-lg-5">
                        <div className="service_block_last rightblock">
                          <div className="hover_img"></div>
                          <div className="content">
                            <h2>{}</h2>
                            <p>{}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : null}
            </div>
            <div className='left_border1 d-none d-lg-block'></div>
            <div className='left_border2 d-none d-lg-block'></div>
            <div className='left_border3 d-none d-lg-block'></div>
            <div className='left_border4 d-none d-lg-block'></div>
            <div className='left_border5 d-none d-lg-block'></div>
          </div>
  
          <div className="reelsection">
     
            <div ref={sideline3ref} className="about_sideline3">
              <div className="reelslideup_line d-lg-block d-none">
                <div className="sidelinecontent">
                  <span className="outlinedot"></span>
                  <span className="outlinedot"></span>
                  <span className="filldot"></span>
                  <p className="text">
                    03 {aboutdata ? aboutdata.about_showreel_label : 'Showreel'}
                  </p>
                  <span className="line"></span>
                  <span className="outlinedot"></span>
                </div>
              </div>
            </div>
          
            <div className="container">
              <h1>{aboutdata ? aboutdata.about_showreel_label : 'Showreel'}</h1>

              {/* IFRAME */}
              <div className="reeliframe_main">
                <div className="reeliframe-border">
                  <div className="reeliframe">
                    {aboutdata ? (
                      parse(aboutdata.about_show_reel_video)
                    ) : (
                      <iframe
                        width="1280"
                        height="720"
                        src="https://www.youtube.com/embed/04jOnpjLh2g"
                        title="Cyberpunk 2077: Widmo wolności — Oficjalny Zwiastun Premierowy z audiodeskrypcją"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    )}
                    <div className="playbutton">
                      <p>
                        {aboutdata ? aboutdata.about_showreel_play : 'Play'}{' '}
                        <br />
                        {aboutdata
                          ? aboutdata.about_showreel_label
                          : 'Showreel'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* VIDEO */}
              {/* <div className='reelvideo'>
                  <video className="" src={aboutdata?aboutdata.about_show_reel_video:"/img/videos/video.mp4"}></video>
                  <div className='playbutton'>
                     <p>{aboutdata?aboutdata.about_showreel_play:'Play'} <br/>{ aboutdata?aboutdata.about_showreel_label:'Showreel'}</p>
                  </div>
               </div> */}
            </div>
          </div>
          <div className="wrapup-timeline">
            <span className="leftshadow"></span>
            <span className="Rightshadow"></span>
            <div className="ourstorySection">
              <div className="container">
                <h1 className="headline">
                  {aboutdata ? aboutdata.about_our : 'Our'}
                </h1>
                <h1 className="headline">
                  {aboutdata ? aboutdata.about_story : 'Story'}
                  <span></span>
                </h1>
              </div>
              <div ref={sideline4ref} className="about_sideline4">
                <div className="timelineslideup_line">
                  <div className="sidelinecontent">
                    <span className="outlinedot d-lg-block d-none"></span>
                    <span className="outlinedot d-lg-block d-none"></span>
                    <span className="outlinedot d-lg-block d-none"></span>
                    <span className="filldot"></span>
                    <p className="text">
                      04{' '}
                      <span className="d-none sidelineour">
                        {aboutdata ? aboutdata.about_our : 'Our'}
                      </span>{' '}
                      {aboutdata ? aboutdata.about_timeline : 'Timeline'}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="timelineSection">
              {/* <span class="topshadow"></span>
            <span class="bottomshadow"></span> */}
              <div className="container">
                <div className="sofarSection col-lg-4">
                  <h2 style={{ paddingLeft: '2rem' }}>
                    {aboutdata ? aboutdata?.about_so_far : 'So far'}
                  </h2>
                  <p>{currentObj?.story_testimonial}</p>
                  <div className="arrows swiper_arrows justify-content-start d-lg-flex d-none">
                    <SliderButtons
                      // disabled={currentObj?.id === firstActiveObj?.id}
                      onClick={(e) => switchTimeLine('PREV')}
                      className="prevarrow"
                      width={16}
                      height={11}
                      path={
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.8945 5.9999L15.8945 5.4999L15.3945 5.4999L2.60523 5.4999L6.17191 1.93322L6.52547 1.57966L5.81836 0.872558L5.46481 1.22611L1.04448 5.64644L0.690922 5.99999L1.04448 6.35355L5.46481 10.7739L5.81836 11.1274L6.52547 10.4203L6.17191 10.0668L2.60504 6.4999L14.8945 6.4999L14.8945 9.16263L14.8945 9.66263L15.8945 9.66263L15.8945 9.16263L15.8945 5.9999Z"
                          fill="#FEFBF7"
                          stroke="#fefbf7"
                          strokeWidth="0.8"
                        />
                      }
                    />
                    <SliderButtons
                      disabled={currentObj?.id === lastActiveObj?.id}
                      onClick={(e) => switchTimeLine('NEXT')}
                      className={`nextarrow ${currentObj?.id === lastActiveObj?.id ? 'disabled nextarrow-last' : ''}`}
                      width={16}
                      height={11}
                      path={
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                          fill="white"
                          stroke="#fefbf7"
                          strokeWidth="0.8"
                        />
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="timeline_graph">
                {/* <div className="ourclientslideup_line d-lg-block d-none">
              <div className="sidelinecontent">
                <p className="text">05 Clients</p>
              </div>
            </div> */}
                <div className="detail_content d-lg-none d-block">
                  <svg
                    className="leftCorner d-lg-none d-block"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.6521 0.898926H0.954102V19.7243"
                      stroke="#FEFBF7"
                      strokeWidth="0.5"
                    />
                  </svg>

                  <svg
                    className="rightCorner d-lg-none d-block"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.233202 0.898926H18.9312V19.7243"
                      stroke="#FEFBF7"
                      strokeWidth="0.5"
                    />
                  </svg>

                  <h5>
                    {currentObj.story_month} {currentObj?.story_year}
                  </h5>
                  <h6>{currentObj?.story_title}</h6>
                  <p>{currentObj?.story_description}</p>
                </div>
                <div className="arrows swiper_arrows justify-content-start d-lg-none d-flex about">
                  <SliderButtons
                    onClick={(e) => switchTimeLine('PREV')}
                    className="prevarrow"
                    width={16}
                    height={11}
                    path={
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.8945 5.9999L15.8945 5.4999L15.3945 5.4999L2.60523 5.4999L6.17191 1.93322L6.52547 1.57966L5.81836 0.872558L5.46481 1.22611L1.04448 5.64644L0.690922 5.99999L1.04448 6.35355L5.46481 10.7739L5.81836 11.1274L6.52547 10.4203L6.17191 10.0668L2.60504 6.4999L14.8945 6.4999L14.8945 9.16263L14.8945 9.66263L15.8945 9.66263L15.8945 9.16263L15.8945 5.9999Z"
                        fill="#FEFBF7"
                        stroke="#fefbf7"
                        strokeWidth="0.8"
                      />
                    }
                  />
                  <SliderButtons
                    disabled={currentObj?.id === lastActiveObj?.id}
                    onClick={(e) => switchTimeLine('NEXT')}
                    className={`nextarrow ${currentObj?.id === lastActiveObj?.id ? 'disabled nextarrow-last' : ''}`}
                    width={16}
                    height={11}
                    path={
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                        fill="#fefbf7"
                        stroke="#fefbf7"
                        strokeWidth="0.8"
                      />
                    }
                  />
                </div>
                {timeLineData?.length > 0 && (
                  <div
                    className="point_wrap_active"
                    style={{
                      left: `${
                        initialActivePointPosition
                          ? isMatchingScreenSize
                            ? initialActivePointPosition + 12.5
                            : zoomLevel > 1
                              ? isMatchingScreenSize2
                                ? initialActivePointPosition - 7.1
                                : initialActivePointPosition - 3.1
                              : initialActivePointPosition - 5.46
                          : ''
                      }px`,
                    }}
                  >
                    <div className="point highlight active"></div>
                    <div className="detail_content_active d-lg-block d-none">
                      <h5>
                        {currentObj.story_month} {currentObj.story_year}
                      </h5>
                      <h6>
                        {currentObj.story_title ? currentObj.story_title : ''}
                      </h6>
                      <p>
                        {currentObj.story_description
                          ? currentObj.story_description
                          : ''}
                      </p>
                    </div>{' '}
                  </div>
                )}
                <Draggable
                  onDrag={(e, ui) => handleDrag(e, ui)}
                  axis="x"
                  grid={[10, 10]}
                  position={position}
                  disabled={!isHovered}
                  onStop={handleDragStop}
                >
                  <div
                    ref={timelineRef}
                    className="timeline_points"
                    style={{
                      cursor: isHovered ? 'none' : 'auto',
                      userSelect: 'none',
                      WebkitUserSelect: 'none',
                      MozUserSelect: 'none',
                      msUserSelect: 'none',
                    }}
                    onMouseMove={handleMouseMove}
                    id="mainDiv"
                  >
                    {isHovered && (
                      <div
                        className="dragCursor d-lg-flex d-none"
                        style={{
                          left: cursorPosition.x - 50,
                          top: cursorPosition.y - 40,
                        }}
                      >
                        <div className="lefticon me-2">
                          <svg
                            width="9"
                            height="15"
                            viewBox="0 0 9 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.99408 1.12738L1.19092 7.50632L7.99408 13.8853"
                              stroke="#FEFBF7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="dragCircle">
                          <p>{aboutdata?.about_hover_button_drag}</p>
                        </div>
                        <div className="righticon ms-2">
                          <svg
                            width="9"
                            height="15"
                            viewBox="0 0 9 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.17291 1.12006L7.97607 7.49899L1.17291 13.8779"
                              stroke="#FEFBF7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    )}

                    {timeLineData.length > 0 ? (
                      <>
                        {timeLineData.map((_, i) => {
                          if (activeTemp !== _.story_year) {
                            activeTemp = _.story_year
                            activeDotBool = true
                          } else {
                            activeDotBool = false
                          }
                          counter++
                          let boolValue = counter % 5 === 0 ? true : false
                          return (
                            <Fragment key={i}>
                              {_.story_isactive === 'Yes' ? (
                                <div
                                  className={
                                    i <= 4
                                      ? `point_wrap  d-lg-flex  child-div-${i}`
                                      : `point_wrap child-div-${i}`
                                  }
                                  id={
                                    initialActiveEventIndex === i
                                      ? 'initialActiveEventDiv'
                                      : ''
                                  }
                                  onClick={(e) => {
                                    if (_.story_isactive === 'Yes') {
                                      handleDivClick(i)
                                      setEventView(e, _)
                                    }
                                  }}
                                  data-event-index={i}
                                >
                                  <div
                                    className="point highlight"
                                    onClick={(e) =>
                                      _.story_isactive === 'Yes'
                                        ? setEventView(e, _)
                                        : e.preventDefault()
                                    }
                                  ></div>
                                  <p className="year">
                                    {activeDotBool || i === 0
                                      ? _.story_year
                                      : ''}
                                  </p>
                                </div>
                              ) : (
                                <div
                                  className={
                                    i <= 4
                                      ? 'point_wrap  d-lg-flex '
                                      : 'point_wrap'
                                  }
                                >
                                  <div className="point"></div>
                                  <p className="year">
                                    {boolValue || i === 0 ? _.story_year : ''}
                                  </p>
                                </div>
                              )}
                            </Fragment>
                          )
                        })}
                      </>
                    ) : (
                      ''
                    )}

                <img
                  className="leftShadow"
                  src="/audio/img/clientlogos/shadowLeft.png"
                  alt="side"
                />
                <div
                  className="timeline-dragger"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                ></div>
              </div>
            </Draggable>
          </div>
        </div>

        <TestomonialAndContact />
        <Footer />
      </div>
    </div>
    </div>
    </>
  )
})

export default About
