import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { clientUrl } from './urls'
import anime from 'animejs'
import Rightbutton from '../components/Buttons/Rightbutton'
import Leftbutton from '../components/Buttons/Leftbutton'

export const Slide = React.memo(function (StackedCarouselSlideProps) {
  const home = useSelector((state) => state.homedata)
  const {
    data,
    dataIndex,
    isCenterSlide,
    swipeTo,
    slideIndex,
    goBack,
    goNext,
  } = StackedCarouselSlideProps
  const lang = useSelector((state) => state.lang)
  const active = true
  const [exp, setexp] = useState(false)
  const croppedImage = data[dataIndex]?.cropped_banner_image
    ? data[dataIndex]?.cropped_banner_image
    : ''
  const coverImage = data[dataIndex]?.banner_image
    ? data[dataIndex]?.banner_image
    : ''
  const isBannerImage = data[dataIndex]?.is_banner_image

  const text = data[dataIndex]?.title ? data[dataIndex]?.title : ''

  const history = useNavigate()

  const [isTransformed, setTransformed] = useState(false)

  const handleImageClick = (u) => {
    localStorage.setItem('isCardClicked', false)

    setTransformed(!isTransformed)
    animate(!isTransformed, u)
    var elementText = document.querySelector(
      '.react-stacked-center-carousel-slide-0 .slidetext',
    )
    var navigationBtn = document.querySelector(
      '.react-stacked-center-carousel-slide-0  .Customnavigation1',
    )
    var plusIcon = document.querySelector('.plusIcon')
    var sideline = document.querySelector('.sidelinecontent')
    // anime({
    //   targets: elementText,
    //   translateY: [0, 200],
    //   duration: 200,
    //   loop: false,
    // })

    // elementText.style.bottom = "80%";
    navigationBtn.style.display = 'none'
    plusIcon.style.display = 'none'
    sideline.style.display = 'none'
  }

  const animate = async (isTransformed, u) => {
    const mainParent = document.querySelector('.card.projectsSlider_custom')
    const mainDiv = document.querySelector('.react-stacked-center-carousel ')
    const imgElement = document.querySelector(
      '.react-stacked-center-carousel-slide-0 .card-card .imagelink',
    )
    const elementCard = document.querySelector(
      '.react-stacked-center-carousel-slide-0 .card-card',
    )
    const elementDiv = document.querySelector(
      '.react-stacked-center-carousel-slide-0',
    )
    const imgElementAnimationProperties = {
      targets: imgElement,
      duration: 2000,
      easing: 'easeInOutCubic',
    }
    const elementCardAnimationProperties = {
      targets: elementCard,
      duration: 2000,
      easing: 'easeInOutCubic',
    }
    const elementDivanimationProperties = {
      targets: elementDiv,
      duration: 2000,
      easing: 'easeInOutCubic',
    }

    if (isTransformed) {
      mainDiv.classList.add('transform')
      mainParent.classList.add('p-0')
      imgElementAnimationProperties.width = '100vw'
      imgElementAnimationProperties.height = '100vh'
      elementCardAnimationProperties.height = '100vh !important'
      elementCardAnimationProperties.height = '100%'
      elementCardAnimationProperties.width = '100%'
    }

    const animationPromises = [
      anime(imgElementAnimationProperties).finished,
      anime(elementCardAnimationProperties).finished,
      anime(elementDivanimationProperties).finished,
    ]

    await Promise.all(animationPromises)
    const updatedImageId = u?.replace(/\s+/g, '-')
    if (updatedImageId) {
      return history(
        lang === 'en'
          ? `/audio/projects/${updatedImageId}`
          : `/${lang}/audio/projects/${updatedImageId}`,
        { relative: 'path', state: { ...data[dataIndex], updatedImageId } },
      )
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.pageYOffset === 0) {
        setexp(false)
        anime({
          targets: '#scrollEnable img',
          opacity: [0, 1],
          loop: false,
          direction: 'linear',
          delay: anime.stagger(70, { from: 'first' }),
          easing: 'easeInOutSine',
          duration: 1000,
          autoplay: true,
        })
      } else {
        setexp(true)
      }
    })
    if (window.pageYOffset === 0) {
      anime({
        targets: '#scrollEnable img',
        opacity: [0, 1],
        loop: false,

        direction: 'linear',
        delay: anime.stagger(70, { from: 'first' }),
        easing: 'easeInOutSine',
        duration: 1000,
        autoplay: true,
      })
    }

    anime({
      targets:
        '.projectsSlider_custom.maxim-slide .react-stacked-center-carousel-slide-0 .card-card .discription',
      scale: [0, 1],
      duration: 2000,
      loop: false,
      easing: 'easeOutQuart',
    })
  }, [exp])

  return (
    <div
      className={active ? 'card-card reached' : 'card-card'}
      draggable={false}
    >
      <div className={`cover fill ${isCenterSlide ? 'off' : 'on'}`}>
        <div className="card-overlay fill" />
      </div>
      <div className="detail fill">
        <div className="discription">
          <p
            className="slidetext"
            id="textSlide"
            style={{
              display: isCenterSlide ? 'block' : 'none',
            }}
          >
            {text}
          </p>
          {isCenterSlide ? (
            <>
              <Link
                className="imagelink"
                onClick={() => {
                  if (
                    isCenterSlide &&
                    document
                      .getElementById('scrollEnable')
                      .classList.contains('maxim-slide')
                  )
                    handleImageClick(
                      data[dataIndex]?.project_url,
                      lang,
                      dataIndex,
                    )
                }}
              >
                <div className="plusIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 50 50"
                    height="40px"
                    id="Layer_1"
                    version="1.1"
                    viewBox="0 0 50 50"
                    width="40px"
                  >
                    <line
                      fill="none"
                      stroke="#FEFBF7"
                      strokeWidth="1"
                      x1="9"
                      x2="41"
                      y1="25"
                      y2="25"
                    />
                    <line
                      fill="none"
                      stroke="#FEFBF7"
                      strokeWidth="1"
                      x1="25"
                      x2="25"
                      y1="9"
                      y2="41"
                    />
                  </svg>
                </div>
                {/* aryan code */}

                {croppedImage === '' ? (
                  <></>
                ) : (
                  <img
                    style={{ display: isCenterSlide ? 'none' : '' }}
                    className="cover-image cropped"
                    border="0"
                    src={croppedImage}
                    alt=""
                  />
                )}

                {coverImage === '' ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#212324',
                    }}
                  />
                ) : isBannerImage ? (
                  <img
                    style={{
                      display: isCenterSlide ? '' : 'none',
                      objectFit: exp === true ? '' : 'cover',
                    }}
                    className="cover-image-test not-cropped"
                    border="0"
                    src={coverImage}
                    alt=""
                  />
                ) : (
                  <video
                    controls={false}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                    className={
                      isCenterSlide ? 'cover-image-test' : 'cover-image'
                    }
                  >
                    <source src={coverImage} type="video/mp4" />
                  </video>
                )}
              </Link>

              {/* sandhya code don't delete it  */}
              {/* <div className="Customnavigation">
                <div onClick={(e) => goBack()}>
                  <img
                    className="leftNav"
                    src="/img/leftarrow.svg"
                    alt="leftarrow"
                  />
                </div>
                <div onClick={(e) => goNext()}>
                  <img
                    className="rightNav"
                    src="/img/rightarrow.svg"
                    alt="rightarrow"
                  />
                </div>
              </div> */}

              {/* aryan code */}
              <div className="Customnavigation1">
                <div
                  style={{ position: 'absolute' }}
                  className="leftNav custombtn1 mbl_displaybtn"
                >
                  <Leftbutton onClick={(e) => goBack()} />
                </div>

                <div
                  style={{ position: 'absolute' }}
                  className="rightNav custombtn1 mbl_displaybtn"
                >
                  <Rightbutton onClick={(e) => goNext()} />
                </div>
              </div>
            </>
          ) : (
            // aryan code
            <>
              {croppedImage === '' ? (
                <></>
              ) : (
                <img
                  style={{
                    display: isCenterSlide ? 'none' : '',
                    backgroundColor: coverImage === '' ? '#212324' : 'none',
                  }}
                  className={
                    isCenterSlide ? 'cover-image-test' : 'cover-image cropped1'
                  }
                  border="0"
                  src={croppedImage}
                  alt=""
                  onClick={() => {
                    if (
                      !isCenterSlide &&
                      document
                        .getElementById('scrollEnable')
                        .classList.contains('maxim-slide')
                    )
                      swipeTo(slideIndex)
                  }}
                />
              )}

              <img
                style={{ display: isCenterSlide ? '' : 'none' }}
                className={isCenterSlide ? 'cover-image-test' : 'cover-image'}
                border="0"
                src={coverImage}
                // src={coverImage}
                alt=""
                onClick={() => {
                  if (
                    !isCenterSlide &&
                    document
                      .getElementById('scrollEnable')
                      .classList.contains('maxim-slide')
                  )
                    swipeTo(slideIndex)
                }}
              />
            </>
          )}

          <div className="viewallprojects">
            <Link
              to={{
                pathname:
                  lang === 'en' ? clientUrl.projects : `/${lang}${clientUrl.projects}`,
              }}
            >
              <span className="animate">
                <span className="animateText">
                  {home ? home.home_view_all_project : 'view all projects'}
                </span>
                <span className="animateText2">
                  {home ? home.home_view_all_project : 'view all projects'}
                </span>
              </span>
              <svg
                width="15"
                height="11"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3536 4.35355C11.5488 4.15829 11.5488 3.84171 11.3536 3.64645L8.17157 0.464466C7.97631 0.269204 7.65973 0.269204 7.46447 0.464466C7.2692 0.659728 7.2692 0.976311 7.46447 1.17157L10.2929 4L7.46447 6.82843C7.2692 7.02369 7.2692 7.34027 7.46447 7.53553C7.65973 7.7308 7.97631 7.7308 8.17157 7.53553L11.3536 4.35355ZM0.5 4.5H11V3.5H0.5V4.5Z"
                  fill="#94DB16"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
})
