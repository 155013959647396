import { useSelector } from 'react-redux'
import CardExample from '../../constants/swipecustom'
import AudioPortal from './audio/audio'
import anime from 'animejs'
import { useEffect, useState } from 'react'
import Section3 from '../homepage/section3'
import Section4 from '../homepage/section4'
import Section5 from '../homepage/section5'
import Section6 from '../homepage/section6'
import Section7 from '../homepage/section7'
import TestomonialAndContact from '../homepage/section8'
import Footer from '../../components/footer/footer'
import Notfound from '../NotFound/Notfound'
import { getLanguage } from '../../constants/urls'
import { langCodes } from '../../constants/country'

var flag = 0

const defaultLang = [
  {
    lang: 'en' || '',
    description: ['we', 'bring', 'games', 'to', 'life'],
    heading: ['BRINGING', 'YOUR', 'STORY', 'TO THE WORLD'],
    audio: ['Click To', 'Play Audio'],
    scroll: 'Scroll Down',
  },
  {
    lang: 'zh-cht',
    description: ['我們', '令', '遊戲', '栩栩', '如生'],
    heading: ['把', '您的', '故事', '帶給全世界'],
    audio: ['點擊', '播放音檔'],
    scroll: '向下捲動',
  },
  {
    lang: 'zh-chs',
    description: ['我们', '赋予', '游戏', '以', '生命'],
    heading: ['将', '您的', '故事', '致全世界'],
    audio: [' 点击以', ' 播放音频'],
    scroll: '向下滚动',
  },
  {
    lang: 'ja',
    description: ['私たちは', 'ゲームに', '命を', '吹き込みます', 'そして'],
    heading: ['あなたの', 'プロジェクトの', '話', '世界へ'],
    audio: ['タップして', '音声をプレイ'],
    scroll: '下へスクロール',
  },
  {
    lang: 'ko',
    description: ['우리는', '여러분의', '게임에', '생명을', '불어넣습니다'],
    heading: ['여러분의', '프로젝트를', '이야기', '세계로'],
    audio: ['탭하여', '오디오 재생'],
    scroll: '아래로 스크롤',
  },
  {
    lang: 'fr-ca',
    description: ['NOUS', 'DONNONS', 'VIE', 'AUX', 'JEUX'],
    heading: ['PARTAGEONS', 'VOTRE', "D'HISTOIRE", 'AVEC LE MONDE'],
    audio: ['Cliquez pour', "JOUER L'AUDIO"],
    scroll: 'FAIRE DÉFILER',
  },
]

function getDescription(langCode) {
  const langObj = defaultLang.find((item) => item.lang === langCode)
  return langObj ? langObj : []
}

const Section1 = ({ projectRef }) => {
  const home = useSelector((state) => state.homedata)
  const staticData = getDescription(getLanguage())
  var upwardanimation
  var audioanimation
  //animations

  var zeroanimation = () => {
    // upwardanimation.pause();
    const a = document.querySelector('.abc')
    const b = document.querySelector('.aryan')
    if (a != null) a.style.display = ''
    if (b != null) b.style.display = ''
  }
  //animation for we bring your voice
  var firstanimation = () => {
    upwardanimation?.pause()
    audioanimation?.pause()
    anime({
      targets: '.top_line_content span',
      translateY: [45, 0],
      easing: 'easeOutExpo',
      duration: 1000,
      loop: false,
      delay: (el, i) => 100 * i,
    })
  }

  //animation to bring uplifted content to 0 position
  var secondanimation = () => {
    anime({
      targets: '.aryan',
      translateY: 0,
      opacity: 0,
      loop: false,
      duration: 0,
    })
  }
  //animation to bring auto value to 0 value
  var thirdanimation = () => {
    anime({
      targets: '.abc',
      translateY: 0,
      duration: 0,
      opacity: 0,
      loop: false,
    })
  }
  //animation for displaying its opacity
  var fifthanimation = () => {
    // console.log('sknakna');
    anime({
      targets: '.aryan',
      opacity: 1,
      delay: 500,
      duration: 0,
      loop: false,
      // complete: function(anim) {
      //   var abc = document.querySelector('.abc')
      //   if(abc !== null) {abc.style.display='';}
      //  }
    })
  }
  //animation for first line rising from botton
  var fourthanimation_1 = () => {
    anime({
      targets: '.sec_h1_line1',
      translateY: [200, 0],
      easing: 'easeOutExpo',
      duration: 2300,
      delay: 800,
      loop: false,
    })
  }
  //animation for second line rising from botton
  var fourthanimation_2 = () => {
    anime({
      targets: '.sec_h1_line3',
      translateY: [200, 0],
      easing: 'easeOutExpo',
      duration: 2300,
      delay: 800,
      loop: false,
    })
  }
  //animation for fourth line rising from botton
  var fourthanimation_3 = () => {
    anime({
      targets: '.sec_h1_line2',
      translateY: [200, 0],
      easing: 'easeOutExpo',
      duration: 2300,
      delay: 800,
      loop: false,
    })
  }

  //animation for lifting all line to up

  var sixthanimation = () => {
    upwardanimation = anime({
      targets: '.aryan',
      opacity: [0.9, 0],
      loop: false,
      translateY: [0, -90],
      duration: 500,
      easing: 'easeInOutSine',
      autoplay: false,
    })
    upwardanimation.play()
  }

  //animation for displaying audio button
  var eightanimation = () => {
    if (projectRef.current === 0) {
      // console.log('eight')
      anime({
        loop: false,
        targets: '.abc',
        translateY: 0,
        opacity: [0, 1],
        duration: 500,
        delay: 1200,
      })
    }
  }

  //animation for lifting audio button
  var ninthanimation = () => {
    audioanimation = anime({
      loop: false,
      targets: '.abc',
      opacity: [0.9, 0],
      // translateY:[0,-40],
      display: 'block',
      duration: 300,
      easing: 'easeInOutSine',
      complete: function (anim) {
        var div = document.querySelector('.abc')
        if (div !== null) {
          div.style.display = 'none'
        }
      },
    })
  }

  var ml4 = {}
  ml4.opacityIn = [0, 1]

  ml4.durationIn = 300
  ml4.durationOut = 300
  ml4.delay = 700

  useEffect(() => {
    // console.log('project ref is ', projectRef.current)
    // console.log('scroll position in home is', window.pageYOffset)
    // setTimeout(() => {
    //   Setloading(true)
    // },3000);
    if (projectRef.current === 1) {
      // window.location.reload();
      const a = document.querySelector('.abc')
      const b = document.querySelector('.aryan')
      if (a !== null) a.style.display = 'none'
      if (b !== null) {
        b.style.display = 'none'
      }
      projectRef.current = 0
    }

    flag = 0

    //animation for the rotating words
    var third = anime
      .timeline({ loop: false, autoplay: false })
      .add({
        targets: '.rotate1',
        opacity: ml4.opacityIn,
        translateY: 0,
        duration: 2000,
        // delay:ml4.delay
      })
      .add({
        targets: '.rotate1',
        translateY: -900,
        easing: 'easeInExpo',
        duration: ml4.durationOut,
        delay: ml4.delay,
      })
      .add({
        targets: '.rotate2',
        opacity: ml4.opacityIn,
        translateY: 0,
        duration: ml4.durationIn,
      })
      .add({
        targets: '.rotate2',
        opacity: 0,
        translateY: -900,

        duration: ml4.durationOut,
        easing: 'easeInExpo',
        delay: ml4.delay,
      })
      .add({
        targets: '.rotate3',
        opacity: ml4.opacityIn,
        translateY: 0,
        duration: ml4.durationIn,
      })
      .add({
        targets: '.rotate3',
        opacity: 0,
        translateY: -900,
        duration: ml4.durationOut,
        easing: 'easeInExpo',
        delay: ml4.delay,
      })
      .add({
        targets: '.rotate_extra1',
        opacity: ml4.opacityIn,
        translateY: 0,
        duration: ml4.durationIn,
      })
      .add({
        targets: '.rotate_extra1',
        opacity: 0,
        translateY: -900,
        duration: ml4.durationOut,
        easing: 'easeInExpo',
        delay: ml4.delay,
      })
      .add({
        targets: '.rotate4',
        opacity: ml4.opacityIn,
        duration: 500,
      })
      .add({
        targets: '.rotate4',
        opacity: 0,
        translateY: -900,
        duration: ml4.durationOut,
        easing: 'easeInExpo',
        delay: ml4.delay,
      })
      .add({
        targets: '.rotate5',
        opacity: ml4.opacityIn,
        duration: 500,
      })
      .add({
        targets: '.rotate5',
        // opacity: 0,
        // translateY: -900,
        duration: ml4.durationOut,
        // easing: 'easeInExpo',
        delay: ml4.delay,
      })
    // .add({
    //   targets: '.rotate6',
    //   opacity: ml4.opacityIn,
    //   duration: 500,
    // })
    // .add({
    //   targets: '.rotate6',

    //   duration: ml4.durationOut,

    //   delay: ml4.delay,
    // })

    var s = document.querySelector('.sec-1-slideup')
    if (s !== null) {
      s.style.display = 'none'
    }

    if (window.scrollY === 0) {
      zeroanimation()
      firstanimation()
      secondanimation()
      fifthanimation()
      thirdanimation()
      fourthanimation_1()
      fourthanimation_2()
      fourthanimation_3()

      // fifthanimation();
      third.restart()

      eightanimation()
    }

    let oldValue = 0

    var temp = false
    window.addEventListener('scroll', (e) => {
      //console.log('rendercount',rendercount)
      // console.log('scroll position in home is inside add', window.pageYOffset)
      let newValue = window.pageYOffset
      // console.log('window scroll ', window.pageYOffset)

      if (window.pageYOffset === 0) {
        // console.log('its working')
        zeroanimation()
        firstanimation()
        secondanimation()
        fifthanimation()
        thirdanimation()
        fourthanimation_1()
        fourthanimation_2()
        fourthanimation_3()
        third.restart()
        eightanimation()

        var sec1Div = document.querySelector('.sec-1-slideup')
        if (sec1Div !== null) {
          sec1Div.style.display = 'none'
        }

        flag = 0
      }

      if (oldValue - newValue < 0) {
        if (flag === 0) {
          sixthanimation()
          ninthanimation()
          third.pause()
        }

        flag = flag + 1

        setTimeout(() => {
          temp = true
        }, 1500)
      }

      //  // Update the old value
      //  oldValue = newValue;
      if (newValue <= 0) {
        oldValue = 0
        newValue = 0
        oldValue = newValue
      } else {
        oldValue = newValue
      }
    })

    //  setTimeout(()=>{
    // Setloading(true)
    //  },600)
  }, [])

  useEffect(() => {
    let param = window.location.href.split('/')

    if (param.length === 5 || param.length === 6) {
      if (![...langCodes, 'audio'].includes(param[4]) && param[4] !== '') {
        setIsNotFound(true)
      } else {
        setIsNotFound(false)
      }
    }
  }, [])

  // NEW
  const [isTopLayerHidden, setIsTopLayerHidden] = useState(false)
  const [tshow, setTshow] = useState(false)
  const [isNotFound, setIsNotFound] = useState(false)
  // console.log('top layer loaded', isNotFound)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 300)

    const handleScroll = () => {
      const scrollPosition = window.scrollY

      if (scrollPosition > 0) {
        setIsTopLayerHidden(true)
        setTimeout(() => {
          setTshow(true)
          // alert('over')
        }, 100)
      } else {
        setIsTopLayerHidden(false)
        setTimeout(() => {
          setTshow(false)
        }, 100)
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  if (isNotFound) {
    return <Notfound />
  }
  return (
    <>
      <>
        <div className="section1 section_data" id="section1">
          <div className="banner_wrapper">
            <div style={{ width: '100vw' }}>
              <CardExample />
            </div>
            {
              <AudioPortal
                text={
                  home
                    ? home.top_banner.home_press_to_play_audio
                    : 'Tap to play audio'
                }
                animation={true}
                audio={home?.top_banner?.home_top_banner_audio}
              />
            }
          </div>

          {
            <div
              className={
                isTopLayerHidden
                  ? 'hidden layerContent banner_content section_data'
                  : 'layerContent banner_content section_data'
              }
            >
              <div className="content aryan">
                <p className="top_line_content">
                  <span>
                    {home ? home.top_banner.home_we : staticData.description[0]}
                  </span>
                  &nbsp;
                  <span>
                    {home
                      ? home.top_banner.home_bring
                      : staticData.description[1]}
                  </span>
                  &nbsp;
                  <span>
                    {home
                      ? home.top_banner.home_games
                      : staticData.description[2]}
                  </span>
                  &nbsp;
                  <span>
                    {home ? home.top_banner.home_to : staticData.description[3]}
                  </span>
                  &nbsp;
                  <span>
                    {home
                      ? home.top_banner.home_life
                      : staticData.description[4]}
                  </span>
                </p>

                <div className="sec_head sec_head_rotate1">
                  <h1 className="sec_h1_line1">
                    {home
                      ? home.top_banner.home_bringing
                      : staticData.heading[0]}
                  </h1>
                </div>
                <div className="sec_head sec_head_rotate2">
                  <h1 className="sec_h1_line2">
                    {home ? home.top_banner.home_your : staticData.heading[1]}
                    <span className="animetext_rotate">
                      <span className="animetext rotate1">
                        {home
                          ? home.top_banner.home_vision
                            ? home.top_banner.home_vision
                            : staticData.heading[2]
                          : staticData.heading[2]}
                      </span>
                      <span className="animetext rotate2">
                        {home
                          ? home.top_banner.home_voice
                          : staticData.heading[2]}
                      </span>
                      <span className="animetext rotate3">
                        {home
                          ? home.top_banner.home_idea
                          : staticData.heading[2]}
                      </span>
                      <span className="animetext rotate_extra1">
                        {home
                          ? home.top_banner.home_sound
                          : staticData.heading[2]}
                      </span>
                      <span className="animetext rotate4">
                        {home
                          ? home.top_banner.home_game
                          : staticData.heading[2]}
                      </span>

                      {/* dont touch below code ask by prasanna to remove it maybe it will be back */}
                      {/* <span className="animetext rotate5">
                        {home ? home.top_banner.home_project : 'project'}
                      </span> */}

                      {/* new content as per content sheet is agaain added by prasanna */}
                      {/* <span className="animetext rotate5">
                        {home
                          ? home.top_banner.home_characters
                          : staticData.heading[2]}
                      </span> */}
                      <span className="animetext rotate5">
                        {home
                          ? home.top_banner.home_story
                            ? home.top_banner.home_story
                            : staticData.heading[2]
                          : staticData.heading[2]}
                      </span>
                    </span>
                  </h1>
                </div>
                <div className="sec_head sec_head_rotate3">
                  <h1 className="sec_h1_line3">
                    {home
                      ? home.top_banner.to_the_world
                      : staticData.heading[3]}
                  </h1>
                </div>
              </div>
              {tshow ? (
                ''
              ) : (
                <div
                  style={{ display: 'none' }}
                  className="scrollIndicator_position"
                >
                  <div className="scrollIndicator">
                    {home?.top_banner && (
                      <span className="text">
                        {home
                          ? home.top_banner.home_scroll_down
                          : staticData.scroll}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          }
        </div>
        {tshow ? (
          <>
            <Section3 />
            <Section4 />
            <Section5 />
            <div className="col-rev-mbl">
              <Section6 />
              <Section7 />
            </div>
            <TestomonialAndContact />
            <Footer />
          </>
        ) : null}
      </>
    </>
  )
}

export default Section1
