import { useEffect } from 'react'
import Footer from '../footer/footer'

const Notfound = () => {
  const styles = {
    container: {
      textAlign: 'center',
      margin: '60px 0',
    },
    heading: {
      fontSize: '36px',
      marginBottom: '20px',
    },
    message: {
      fontSize: '18px',
    },
  }
  useEffect(() => {
    document.title = '404-Not Found'
  }, [])
  return (
    <>
      <div style={styles.container}>
        <h1 style={styles.heading}>404 - Not Found</h1>
        <p style={styles.message}>
          The page you are looking for does not exist.
        </p>
      </div>
      <Footer />
    </>
  )
}

export default Notfound
