import '../../styles/location/location.css'

const Location = () => {
  return (
    <>
      <body>
        <section className="sidev2-location-secheader">
          <div className="content">
            <div className="Backimg">
              <div className="backtext">
                <img className="rbackimg" src="/audio/img/Backbtn.svg" alt=""></img>
                <span className="backto">BACK TO ABOUT</span>
              </div>
              <div className="sidev2-location-la">
                <h1>SIDE LA</h1>
              </div>

              <div className="sidev2-location-london">
                <div>
                  <h1>SIDE</h1>
                </div>
                <div>
                  <span className="london"> LONDON</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sidev2-location-sec" id="home">
          {/*}  <div className="vertical">		
        <h3>
         <img src="img/Ellipse.svg"></img>
         <span>01 LOCATION</span>
         <img className="line40" src="img/Line-40.png"></img>
         <img src="img/Ellipsevertical.svg"></img>
         <img src="img/Ellipsevertical.svg"></img>
        </h3>
    </div> */}

          <div className="maindiv">
            {/* <div className="secver" >
           <img  src="img/secvertical.svg"></img>
        </div> */}
            <div className="secdiv">
              <div className="secsubdiv">
                <div className="content">
                  <p>
                    Sed viverra faucibus rutrum ut tellus. Faucibus arcu,
                    porttitor diam sem. Amet praesent turpis morbi amet, etiam
                    dictum. Sed diam in pharetra massa cursus feugiat amet, duis
                    tellus arcu.
                  </p>
                  <br></br>
                  <p>
                    Amet praesent turpis morbi amet, etiam dictum. Sed diam in
                    pharetra massa cursus feugiat amet.
                  </p>

                  <div className="service">
                    <h2>
                      <img src="/audio/img/serviceline.svg" alt=""></img> <br></br>
                      SERVICES <br></br>
                    </h2>
                    <span className="addr">
                      Production Management<br></br>
                      Casting<br></br>
                      Localization<br></br>
                      Performance Capture<br></br>
                      Studio Recording<br></br>
                    </span>

                    <br></br>

                    <div className="view">
                      <h3 className="view">VIEW THE WHOLE TEAM</h3>
                      <img
                        className="down"
                        src="/audio/img/downarrow.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>

                {/*   <div className="allimage">
            <img className="firstimg" src="img/firstimg.svg" alt="" ></img>  
            <img className="secondimg" src="img/secondimg.svg" alt="" ></img>  
            <img className="thirdimg"    src="img/la11.svg" alt="" ></img> 
            <img  className="fourtimg" src="img/fourthimg.svg" alt=""></img> 
            <img className="fifthimg"   src='img/fifthimg.svg' alt=""></img>            
            
            </div> 
         */}

                <div className="sidev2-location-sliding-active">
                  <img
                    className="sidev2-location-Sliding-Images-active"
                    src="/audio/img/Slide_Image_coloured.svg"
                    alt=""
                  ></img>
                </div>

                <div className="sidev2-location-sliding-active-mobile">
                  <div className="shadowbuttons-mobile">
                    <button className="button-s-m rounded-button-shadow-mobile">
                      <img src="/audio/img/leftbtn.svg" alt=""></img>
                    </button>
                    <span>01</span>
                    <button className="button-s-m rounded-button-shadow-mobile">
                      <img src="/audio/img/rightbtn.svg" alt=""></img>
                    </button>
                  </div>
                  <div className="sidev2-location-Sliding-Images-active-mobile">
                    <img
                      className="sidev2-location-Sliding-Images-active"
                      src="/audio/img/Slide_Image_coloured.svg"
                      alt=""
                    ></img>
                  </div>
                </div>
              </div>
              <div className="horizline">
                <img src="/audio/img/seclinehoriz.svg" alt=""></img>
              </div>

              <div className="card-row">
                <div className="card-row1">
                  <div className="card">
                    <div className="cardpfl-1">
                      <img src="/audio/img/firstpfl.svg" alt=""></img>
                    </div>
                    <div className="cardpfl-1">
                      <img src="/audio/img/fifthpfl.svg" alt=""></img>
                    </div>
                    <h3>DEPARTMENT</h3>
                    <h4>JOB TITLE HERE</h4>
                    <p>NAME SURNAME</p>
                  </div>
                  <div className="card">
                    <div className="cardpfl-2">
                      <img src="/audio/img/secpfl.svg" alt=""></img>
                    </div>
                    <div className="cardpfl-2">
                      <img src="/audio/img/thirdpfl.svg" alt=""></img>
                    </div>
                    <h3>DEPARTMENT</h3>
                    <h4>JOB TITLE HERE</h4>
                    <p>NAME SURNAME</p>
                  </div>
                  <div className="card">
                    <div className="cardpfl-3">
                      <img src="img/thirdpfl.svg" alt=""></img>
                    </div>
                    <div className="cardpfl-3">
                      <img src="img/fifthpfl.svg" alt=""></img>
                    </div>
                    <h3>DEPARTMENT</h3>
                    <h4>JOB TITLE HERE</h4>
                    <p>NAME SURNAME</p>
                  </div>
                  <div className="card">
                    <div className="cardpfl-4">
                      <img src="img/fourthpfl.svg" alt=""></img>
                    </div>
                    <div className="cardpfl-4">
                      <img src="img/fifthpfl.svg" alt=""></img>
                    </div>
                    <h3>DEPARTMENT</h3>
                    <h4>JOB TITLE HERE</h4>
                    <p>NAME SURNAME</p>
                  </div>
                  <div className="card">
                    <div className="cardpfl-5">
                      <img src="img/fifthpfl.svg" alt=""></img>
                    </div>
                    <div className="cardpfl-5">
                      <img src="img/firstpfl.svg" alt=""></img>
                    </div>
                    <h3>DEPARTMENT</h3>
                    <h4>JOB TITLE HERE</h4>
                    <p>NAME SURNAME</p>
                  </div>
                  <div className="card">
                    <div className="cardpfl-6">
                      <img src="img/firstpfl.svg" alt=""></img>
                    </div>
                    <div className="cardpfl-6">
                      <img src="img/secpfl.svg" alt=""></img>
                    </div>
                    <h3>DEPARTMENT</h3>
                    <h4>JOB TITLE HERE</h4>
                    <p>NAME SURNAME</p>
                  </div>
                  <div className="card">
                    <div className="cardpfl-7">
                      <img src="img/secpfl.svg" alt=""></img>
                    </div>
                    <div className="cardpfl-7">
                      <img src="img/thirdpfl.svg" alt=""></img>
                    </div>
                    <h3>DEPARTMENT</h3>
                    <h4>JOB TITLE HERE</h4>
                    <p>NAME SURNAME</p>
                  </div>
                  <div className="card">
                    <div className="cardpfl-8">
                      <img src="img/thirdpfl.svg" alt=""></img>
                    </div>
                    <div className="cardpfl-8">
                      <img src="img/fifthpfl.svg" alt=""></img>
                    </div>
                    <h3>DEPARTMENT</h3>
                    <h4>JOB TITLE HERE</h4>
                    <p>NAME SURNAME</p>
                  </div>
                  <div className="card">
                    <div className="cardpfl-9">
                      <img src="img/fourthpfl.svg" alt=""></img>
                    </div>
                    <div className="cardpfl-9">
                      <img src="img/secpfl.svg" alt=""></img>
                    </div>
                    <h3>DEPARTMENT</h3>
                    <h4>JOB TITLE HERE</h4>
                    <p>NAME SURNAME</p>
                  </div>
                  <div className="card">
                    <div className="cardpfl-10">
                      <img src="img/fifthpfl.svg" alt=""></img>
                    </div>
                    <div className="cardpfl-10">
                      <img src="img/firstpfl.svg" alt=""></img>
                    </div>
                    <h3>DEPARTMENT</h3>
                    <h4>JOB TITLE HERE</h4>
                    <p>NAME SURNAME</p>
                  </div>
                </div>

                <div className="card-row1-mobile">
                  <div className="card">
                    <img src="img/firstpfl.svg" alt=""></img>
                    <h4>
                      JOB TITLE HERE<span>DEPARTMENT</span>
                    </h4>
                    <p>NAME SURNAME</p>
                  </div>
                </div>
              </div>
            </div>
            {/*   <div className="horizline1" >
           <img src="img/secverticalline1.svg"></img>
        </div>  */}
            <div className="sidev2-location-shadow-images">
              <div className="sidev2-location-shadowsub-images">
                <img
                  className="shadowimg1"
                  src="/img/location_shadowimg.svg"
                  alt=""
                ></img>

                <img
                  className="shadowimg2"
                  src="/img/location_shadowimg1.svg"
                  alt=""
                ></img>
                <img
                  className="shadowimg3"
                  src="/img/location_shadowimg2.svg"
                  alt=""
                ></img>
              </div>

              <div className="shadowbuttons">
                <button className="button-s rounded-button-shadow">
                  <img src="img/leftbtn.svg" alt=""></img>
                </button>
                <span>01</span>
                <button className="button-s rounded-button-shadow">
                  <img src="/img/rightbtn.svg" alt=""></img>
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="sidev2-location-about" id="about">
          <div className="aboutmaindiv">
            <div className="aboutsubdiv">
              <div className="content">
                <span className="prev">PREV</span>
                <img className="rbackimg" src="img/Ellipse.svg" alt=""></img>
                <h2 className="city">LOS ANGELES</h2>
              </div>

              <div className="aboutnext">
                <div className="twoimages">
                  <button className="button rounded-button-02">
                    <img src="img/rightarrowimg.svg" alt=""></img>
                  </button>
                </div>
                <div className="twoimages1">
                  <img className="rbackimg1" src="img/Ellipse.svg" alt=""></img>
                  <span className="prevnext">NEXT</span>
                  <h2 className="city">PARIS</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="clienthar1">
          <img className="divline" src="img/clienthorline.svg" alt=""></img>
        </div>

        <section className="sidev2-location-about-mobile">
          <div className="about-mobile-sec">
            <div className="desc">
              <div className="profilescroll">
                <div>
                  <button className="button-m rounded-button-mobile">
                    <img src="img/leftbtn.svg" alt=""></img>
                  </button>
                </div>
                <div className="numberdiv">
                  <span className="num">01</span>
                  <img src="img/scrollframe.svg" alt=""></img>
                  <span className="num">09</span>
                </div>
                <div>
                  <button className="button-m rounded-button-mobile">
                    <img src="img/rightbtn.svg" alt=""></img>
                  </button>
                </div>
              </div>
            </div>

            <div className="roundteams">
              <div className="team">
                <button className="button-t rounded-button-team">
                  <p>LOS ANGELES</p>
                  <p>TEAM</p>
                  <br></br>
                  <img src="img/whiteltbtn.svg" alt=""></img>
                </button>

                <button className="button-t rounded-button-team">
                  <p>PARIS TEAM</p>
                  <br></br>
                  <img src="img/whitertbtn.svg" alt=""></img>
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="sidev2-location-aboutdownsec">
          <div className="aboutdown">
            <div className="clientver">
              {/*  <div className="clientversub1">
           <img src="img/clientleftline.svg"></img>           
      </div> */}
              <div>
                <div>
                  <div className="abuotsection8">
                    <div className="leftcontent">
                      <h5>
                        what clients <br /> say about us
                      </h5>
                      <p className="content">
                        <img
                          className="greenimg"
                          src="img/greenlines.svg"
                          alt=""
                        ></img>
                        <i className="clientpara">
                          Sed viverra faucibus rutrum ut tellus. Faucibus arcu,
                          porttitor diam sem. Amet praesent turpis morbi amet,
                          etiam dictum sed amet. Lorem ipsum dolor sit amet
                          conseceteur arcu et viverra.
                        </i>
                      </p>
                      <p className="tagline">
                        <span />
                        NAME SURNAME, COMPANY
                      </p>
                      <div className="arrows">
                        <button>
                          <img src="/img/leftarrow.svg" alt="" />
                        </button>
                        <button>
                          <img src="/img/rightarrow.svg" alt="" />
                        </button>
                      </div>
                    </div>
                    {/*  <div className="midcontent">
          <img src="img/locationmidline.svg"></img>
    </div> */}
                    <div className="rightcontent">
                      <h1>
                        Tell us <br></br> Your <br></br> Story
                      </h1>
                      <div className="circular fill">
                        <button className="circularbutton rounded-button-circular">
                          CONTACT US
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  <div className="clientversub2">
           <img src="img/clientrightline.svg"></img>
  </div> */}
            </div>
            <div></div>
          </div>
        </section>

        <section className="sidev2-location-aboutdownsec-mobile">
          <div className="aboutdown-mobile">
            <div>
              <img
                className="mobilehorline"
                src="img/mobilehorline.svg"
                alt=""
              ></img>
            </div>
            <div className="clientver-mobile">
              <div>
                <div>
                  <div className="abuotsection8-mobile">
                    <div className="leftcontent">
                      <h4>AND</h4>
                      <h5>
                        what they <br /> say about us
                      </h5>
                      <p className="content">
                        <img
                          className="greenimg"
                          src="img/greenlines.svg"
                          alt=""
                        ></img>
                        <i className="clientpara">
                          Sed viverra faucibus rutrum ut tellus. Faucibus arcu,
                          porttitor diam sem. Amet praesent turpis morbi amet,
                          etiam dictum sed amet. Lorem ipsum dolor sit amet
                          conseceteur arcu et viverra.
                        </i>
                      </p>
                      <p className="tagline">
                        <span />
                        NAME SURNAME, COMPANY
                      </p>
                      <div className="arrows">
                        <button>
                          <img src="/img/leftarrow.svg" alt="" />
                        </button>
                        <button>
                          <img src="/img/rightarrow.svg" alt="" />
                        </button>
                      </div>
                    </div>
                    <div>
                      <img
                        className="mobilehorline"
                        src="img/mobilehorline.svg"
                        alt=""
                      ></img>
                    </div>

                    <div className="rightcontent">
                      <h1>
                        Tell us <br></br> Your <br></br> Story
                      </h1>
                      <div className="circular fill">
                        <button className="circularbutton rounded-button-circular">
                          CONTACT
                        </button>
                      </div>

                      {/*
        <div>       
          <h4>NOW</h4>
          <h5>
            Tell us 
            <br></br>
            Your Story
           </h5>         
          <div className="contact">
          <h2>Name</h2> 
           <div className="contactsub">
             <p type="Name:"><input className="exam" placeholder="EXAM"></input></p>
             <p type="Name:"><input className="emailmsg" placeholder="EMAIL"></input></p>
             <p type="Name:"><input className="emailmsg" placeholder="ADD A MESSAGE"></input></p>
            </div>
          </div>
          <div className="submit">
          <button className="btn">
             SUBMIT                 
               <img className="ico" src="/img/rightarrow.svg" alt="" />
          </button>
          </div>

          </div>
       */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </section>
      </body>
    </>
  )
}

export default Location
