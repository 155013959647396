import '../../styles/services/services.css'
import { forwardRef, useEffect, useState } from 'react'
import { useRef } from 'react'
import TestomonialAndContact from '../homepage/section8'
import Footer from '../../components/footer/footer'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/pagination'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import anime from 'animejs'
import 'swiper/css/bundle'
import SliderButtons from '../Buttons/SliderButtons'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import axiosInstance from '../../services/axiosInstance'
import { devapiUrl } from '../../constants/devapi'
import { changeLoaderState } from '../../actions/lngactions'
import { useLocation } from 'react-router'
import { getLanguage } from '../../constants/urls'
import { langCodes } from '../../constants/country'
import Notfound from '../NotFound/Notfound'

const Section = ({ count, total, title }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <span
          className={index + 1 === count ? 'filldot1' : 'outlinedot'}
        ></span>
      ))}
      {/* <span className="filldot1"></span> */}
      <p className="text">
        0{count} {title ? title : 'Lorem Ipsum'}
      </p>

      {count === total ? '' : <span className="line"></span>}
      {[...Array(total - count)].map((_, index) => (
        <span className="outlinedot"></span>
      ))}

      {/* <span className="outlinedot"></span>
      <span className="outlinedot"></span> */}
    </>
  )
}

const Services = forwardRef((props, ref) => {
  let bool = false
  const loadingState = useSelector((state) => state.loadingState)
  const location = useLocation()
  const dispatch = useDispatch()
  const [slideview, Setslideview] = useState(window.innerWidth <= 991 ? 1 : 3)
  const [service, Setservice] = useState(null)
  const [highlight, Sethighlight] = useState([])
  const [isNotFound, setIsNotFound] = useState(false)
  var sidelinearr = []
  const [dots, Setdots] = useState(1)
  let param = window.location.href.split('/')

  let url = param[param.length - 1]
  const serviceRef = useRef(null)
  if (param.includes('services')) document.title = 'SIDE - Services'
  if (ref !== null) ref.current = 1
  const [data, Setdata] = useState(null)
  let langcode = useSelector((state) => state.lang)
  let count = 0
  const animation = () => {
    let head = document.querySelector('.Headsection')
    if (head !== null) head.style.opacity = 0
    if (loadingState === false) count = count + 1
    // if (loadingState==false&&count==2) {
    if (head !== null) head.style.opacity = 1
    anime
      .timeline({ loop: false })
      .add({
        targets: '.service-heading .headTag1',
        translateY: [200, 0],
        easing: 'easeOutExpo',
        duration: 3400,
      })
      .add({
        targets: '.headTag1',
        opacity: 1,
        duration: 100,
        easing: 'easeOutExpo',
        delay: 10,
        loop: false,
      })
    anime
      .timeline({ loop: false })
      .add({
        targets: '.service-heading .headTag2',
        translateY: [200, 0],
        easing: 'easeOutExpo',
        duration: 3400,
      })
      .add({
        targets: '.headTag2',
        opacity: 1,
        duration: 100,
        easing: 'easeOutExpo',
        delay: 10,
        loop: false,
      })
    anime
      .timeline({ loop: false })
      .add({
        targets: '.service-subheading',
        opacity: 0,
        duration: 1000,
      })
      .add({
        targets: '.service-subheading',
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 5400,
      })
    // }
  }
  const handleswiperclick = (e, index1, index2) => {
    var clikedelement = e.target.closest('.swiper-slide')
    var selected_index = 0
    let borders = document.getElementById(`borderstatus${index1}`)
    if (index2 === 1) {
      if (clikedelement.classList.contains('swiper-slide-active')) {
        selected_index = 0
      } else if (clikedelement.classList.contains('swiper-slide-next')) {
        selected_index = 1
      } else {
        selected_index = 2
      }
    } else {
      if (clikedelement.classList.contains('swiper-slide-active')) {
        selected_index = 2
      } else if (clikedelement.classList.contains('swiper-slide-next')) {
        selected_index = 1
      } else {
        selected_index = 0
      }
    }
    if (borders !== null) var x = borders.getElementsByTagName('div')

    for (let i = 0; i < x.length; i++) {
      let ele = x[i]
      ele.classList.remove('active')
    }
    for (let i = 0; i < x.length; i++) {
      let ele = x[i]
      if (i === selected_index) {
        ele.classList.add('active')
        // borders[i].classList.add('active')
      }
    }
  }

  const getservicedata = (lang) => {
    axiosInstance
      .get(`${devapiUrl}${lang}/side-pages/services`)
      .then((res) => {
        Setdata(res.data.services)
        Setdots(res.data.services.service_types.length)
        for (let i = 0; i < res.data.services.service_types.length; i++) {
          sidelinearr[i] = 0
        }

        dispatch(changeLoaderState(false))

        if (bool === false && langcode === getLanguage()) {
          animation()
        }
        bool = true
        let param = window.location.href.split('/')
        if (lang === 'en') {
          Setservice(param[param.length - 2].split('-').join(''))
          let x = param[param.length - 1].split('-').join('')
          var ele = document.getElementById(`${x}`)

          if (param.length >= 6) {
            let ele1 = document.getElementById(
              `${param[param.length - 2].split('-').join('')}`,
            )
            let arr1 = []
            let arr2 = []

            let subser = param[param.length - 1]
            let ser = param[param.length - 2]

            res.data.services.service_types.forEach((x, i) => {
              if (x.service_url === ser) {
                x.sub_services.forEach((y, i) => {
                  let url = y.sub_service_url
                  url = url.split('-').join('').toLowerCase()

                  if (url === subser) {
                    arr1.push(y)
                  } else {
                    arr2.push(y)
                  }
                })

                Sethighlight(arr1.concat(arr2))

                if (ele1 != null) {
                  setTimeout(() => {
                    ele1.scrollIntoView()
                    window.scrollBy(0, -50)
                  }, 50)
                }
              }
            })
          } else {
            if (ele !== null) {
              setTimeout(() => {
                ele.scrollIntoView()
                window.scrollBy(0, -50)
              }, 50)
            }
          }
        } else {
          Setservice(param[param.length - 2].split('-').join(''))
          let x = param[param.length - 1].split('-').join('')
          var ele = document.getElementById(`${x}`)

          if (param.length >= 7) {
            let ele1 = document.getElementById(
              `${param[param.length - 2].split('-').join('')}`,
            )
            let arr1 = []
            let arr2 = []

            let subser = param[param.length - 1]
            let ser = param[param.length - 2]

            res.data.services.service_types.forEach((x, i) => {
              if (x.service_url === ser) {
                x.sub_services.forEach((y, i) => {
                  let url = y.sub_service_url
                  url = url.split('-').join('').toLowerCase()

                  if (url === subser) {
                    arr1.push(y)
                  } else {
                    arr2.push(y)
                  }
                })

                Sethighlight(arr1.concat(arr2))

                if (ele1 != null) {
                  setTimeout(() => {
                    ele1.scrollIntoView()
                    window.scrollBy(0, -50)
                  }, 50)
                }
              }
            })
          } else {
            if (ele !== null) {
              setTimeout(() => {
                ele.scrollIntoView()
                window.scrollBy(0, -50)
              }, 50)
            }
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
  useEffect(() => {
    dispatch(changeLoaderState(true))
    getservicedata(getLanguage())
    window.addEventListener('resize', () => {
      let width = window.innerWidth
      if (width <= 991) {
        Setslideview(1)
      } else {
        Setslideview(3)
      }
    })
  }, [location.pathname])

  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(
      (entry) => {
        let serviceblock = entry.target

        let servicestripes =
          serviceblock !== null
            ? serviceblock.getElementsByTagName('div')
            : null
        if (entry.intersectionRatio > 0.9) {
          for (let j = 0; j < servicestripes.length; j++) {
            let ele = servicestripes[j]
            if (j === 0) {
              ele.children[0].style.height = '15%'
              ele.children[1].style.height = '10%'
            } else if (j === 1) {
              ele.children[0].style.height = '5%'
              ele.children[1].style.height = '0%'
            } else if (j === 2) {
              ele.children[0].style.height = '9%'
              ele.children[1].style.height = '4%'
            } else if (j === 3) {
              ele.children[0].style.height = '0%'
              ele.children[1].style.height = '0%'
            } else if (j === 4) {
              ele.children[0].style.height = '15%'
              ele.children[1].style.height = '10%'
            }
          }
        } else if (entry.intersectionRatio === 0) {
          // reseting their original height

          for (let j = 0; j < servicestripes.length; j++) {
            let ele = servicestripes[j]
            if (j === 0) {
              ele.children[0].style.height = '0%'
              ele.children[1].style.height = '0%'
            } else if (j === 1) {
              ele.children[0].style.height = '20%'
              ele.children[1].style.height = '10%'
            } else if (j === 2) {
              ele.children[0].style.height = '12%'
              ele.children[1].style.height = '20%'
            } else if (j === 3) {
              ele.children[0].style.height = '5%'
              ele.children[1].style.height = '9%'
            } else if (j === 4) {
              ele.children[0].style.height = '0%'
              ele.children[1].style.height = '0%'
            }
          }
        }
      },
      { threshold: 1 },
    )
  })
  useEffect(() => {
    let sideline_observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(
        (entry) => {
          let x = entry.target.className.charAt(
            entry.target.className.length - 1,
          )
          let index = Number(x)

          if (entry.intersectionRatio > 0) {
            if (sidelinearr[index] === 0) {
              entry.target.style.opacity = 1
              anime({
                targets: entry.target,
                translateY: [600, 0],
                easing: 'easeOutExpo',
                duration: 4000,
                delay: 100,
              })
              sidelinearr[index] = 1
            }
          } else {
            if (entry.intersectionRatio === 0) {
            }
          }
        },
        { threshold: 1 },
      )
    })
    dispatch(changeLoaderState(true))
    window.addEventListener('scroll', (e) => {
      if (window.scrollY == 0) {
        for (let i = 0; i < sidelinearr.length; i++) {
          sidelinearr[i] = 0
        }
      }

      let side = document.querySelectorAll(`.services_line1`)

      if (side != null) {
        for (let i = 0; i < side.length; i++) {
          // if(i==0)
          sideline_observer.observe(side[i])
        }
        //   for(let i=0;i<side.length;i++)
        //   {

        //  sideline_observer.unobserve(side[i], i)

        // }
      }

      e.preventDefault()
    })
  }, [])

  useEffect(() => {
    let param = window.location.href.split('/')
    console.log('param----------', param)

    if (param.length === 5) {
      if (![...langCodes, 'services'].includes(param[3]) && param[3] !== '') {
        setIsNotFound(true)
      } else {
        setIsNotFound(false)
      }
    }
  }, [])
  // if (isNotFound) {
  //   return <Notfound />
  // }
  return (
    <>
      <div className="services_page">
        <span className="leftshadow"></span>
        <div className="Headsection container d-flex align-items-end">
          <div className="head_title">
            <div className="service-heading">
              <h1 className="headTag1">
                {data ? data.service_heading.services_sound : 'sound'}
              </h1>
            </div>

            <div className="service-heading">
              <h1 className="headTag2">
                {data ? data.service_heading.services_judgement : 'judgement'}
              </h1>
            </div>
          </div>
          <div className="sub_title mb-2">
            <span style={{ opacity: '0' }} className="service-subheading">
              <span className="line_before"></span>

              {data ? (
                data.service_heading_description
                  .split('\n')
                  .map(function (item, idx) {
                    return (
                      <p key={idx}>
                        {item}
                        <br />
                      </p>
                    )
                  })
              ) : (
                <p>
                  Bringing your characters to life1. <br /> Bringing your story
                  to the world.
                </p>
              )}
            </span>
          </div>
        </div>
        {/* service 1 */}
        {/* service background */}

        {data ? (
          <>
            {data.service_types.map((x, i) => {
              // {x.service_title.toLowerCase().replaceAll(/\s/g, '')!=service?<></>:''}
              return (
                <div
                  key={i}
                  id={x.service_url}
                  className={
                    i % 2 === 0
                      ? 'servies_block rightService'
                      : 'servies_block leftService'
                  }
                >
                  <div className="services_bg">
                    <div className="servicebg_img">
                      <img src={x.service_image} alt="" />
                    </div>

                    {/* //data.services.service_types -- list of services */}
                    <div className="sidelinecontent1">
                      <div className={`services_line1 sideline${i}`}>
                        <div className="sidelinecontent">
                          <Section
                            count={i + 1}
                            total={dots}
                            title={x.service_title}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        i % 2 === 0
                          ? 'services_slider right services_slider1'
                          : 'services_slider left services_slider1'
                      }
                    >
                      <h1 className="service-title-header">
                        {x ? x.service_title : 'Lorem Ipsum'}
                      </h1>
                      <div className="ourteam_swiper">
                        {x?.sub_services?.length > slideview ? (
                          <div className="swiper_arrows arrows d-flex">
                            <SliderButtons
                              disabled
                              className={`prevarrow prevarrow${i} disabled`}
                              width={19}
                              height={11}
                              path={
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M18.0781 6.92688L18.0781 7.42688L17.5781 7.42688L1.95551 7.42688L6.41621 11.8876L6.76977 12.2411L6.06266 12.9482L5.70911 12.5947L0.394718 7.2803L0.0411641 6.92675L0.394718 6.57319L5.70911 1.25881L6.06266 0.905253L6.76977 1.61236L6.41621 1.96591L1.95525 6.42688L17.0781 6.42688L17.0781 3.12446L17.0781 2.62446L18.0781 2.62446L18.0781 3.12446L18.0781 6.92688Z"
                                  fill="black"
                                />
                              }
                            />
                            <SliderButtons
                              className={`nextarrow nextarrow${i}`}
                              width={16}
                              height={11}
                              path={
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                                  fill="#3e3e3e"
                                />
                              }
                            />
                          </div>
                        ) : (
                          <div
                            style={{ opacity: '0' }}
                            className="swiper_arrows arrows d-flex"
                          >
                            <SliderButtons
                              disabled
                              className={`prevarrow prevarrow${i} disabled`}
                              width={16}
                              height={11}
                              path={
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M15.8945 5.9999L15.8945 5.4999L15.3945 5.4999L2.60523 5.4999L6.17191 1.93322L6.52547 1.57966L5.81836 0.872558L5.46481 1.22611L1.04448 5.64644L0.690922 5.99999L1.04448 6.35355L5.46481 10.7739L5.81836 11.1274L6.52547 10.4203L6.17191 10.0668L2.60504 6.4999L14.8945 6.4999L14.8945 9.16263L14.8945 9.66263L15.8945 9.66263L15.8945 9.16263L15.8945 5.9999Z"
                                  fill="#3e3e3e"
                                />
                              }
                            />
                            <SliderButtons
                              className={`nextarrow nextarrow${i}`}
                              width={16}
                              height={11}
                              path={
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                                  fill="#3e3e3e"
                                />
                              }
                            />
                          </div>
                        )}
                        <Swiper
                          slidesPerView={3}
                          loop={false}
                          spaceBetween={30}
                          modules={[Navigation]}
                          breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            992: {
                              slidesPerView: 3,
                            },
                          }}
                          navigation={{
                            prevEl: `.prevarrow${i}`,
                            nextEl: `.nextarrow${i}`,
                          }}
                          onSlideChange={(swiper) => {
                            if (
                              swiper.realIndex === 0 ||
                              swiper.realIndex ===
                                swiper.slides.length - slideview
                            ) {
                              if (swiper.realIndex === 0) {
                                let ele1 = document.querySelector(
                                  `.prevarrow${i}`,
                                )
                                if (ele1 !== null) {
                                  ele1.classList.add('disabled')
                                  ele1.setAttribute('disabled', 'disabled')
                                }
                                let ele2 = document.querySelector(
                                  `.nextarrow${i}`,
                                )
                                if (ele2 != null) {
                                  ele2.classList.remove('disabled')
                                  ele2.removeAttribute('disabled')
                                }
                              } else {
                                let ele1 = document.querySelector(
                                  `.nextarrow${i}`,
                                )
                                if (ele1 != null) {
                                  ele1.classList.add('disabled')
                                  ele1.setAttribute('disabled', 'disabled')
                                }
                                let ele2 = document.querySelector(
                                  `.prevarrow${i}`,
                                )
                                if (ele2 != null) {
                                  ele2.classList.remove('disabled')
                                  ele2.removeAttribute('disabled')
                                }
                              }
                            } else {
                              document
                                .querySelector(`.prevarrow${i}`)
                                .classList.remove('disabled')
                              document
                                .querySelector(`.prevarrow${i}`)
                                .removeAttribute('disabled')

                              document
                                .querySelector(`.nextarrow${i}`)
                                .classList.remove('disabled')
                              document
                                .querySelector(`.nextarrow${i}`)
                                .removeAttribute('disabled')
                            }
                            if (swiper.isEnd) {
                              document
                                .querySelector(`.nextarrow${i}`)
                                .classList.add('disabled')
                              document
                                .querySelector(`.nextarrow${i}`)
                                .setAttribute('disabled', 'disabled')
                            } else {
                              document
                                .querySelector(`.nextarrow${i}`)
                                .classList.remove('disabled')
                              document
                                .querySelector(`.nextarrow${i}`)
                                .removeAttribute('disabled')
                            }
                            let selected_index = i
                            let borders = document.getElementById(
                              `borderstatus${selected_index}`,
                            )

                            if (borders !== null)
                              var x = borders.getElementsByTagName('div')

                            for (let i = 0; i < x.length; i++) {
                              let ele = x[i]
                              ele.classList.remove('active')
                            }
                            for (let i = 0; i < x.length; i++) {
                              let ele = x[i]
                              if (i === 0) {
                                ele.classList.add('active')
                              }
                            }
                          }}
                          className={`mySwiper myswiper${i}`}
                        >
                          {x.service_title
                            .toLowerCase()
                            .replaceAll(/\s/g, '') === service ? (
                            <>
                              {highlight.map((services, index) => {
                                return (
                                  <SwiperSlide
                                    key={index}
                                    ref={serviceRef}
                                    onClick={
                                      i % 2 === 0
                                        ? (e) => handleswiperclick(e, i, 1)
                                        : (e) => handleswiperclick(e, i, 2)
                                    }
                                    style={{
                                      backgroundColor:
                                        services.sub_service_title === ''
                                          ? ''
                                          : '#0d0d0d',
                                      border:
                                        services.sub_service_title === ''
                                          ? 'none'
                                          : '1px solid #fff',
                                      width: '12rem !important',
                                    }}
                                  >
                                    {services.about_service_isservice_text_or_img ===
                                    'Image' ? (
                                      <>
                                        {' '}
                                        <div className="servicesblock_img">
                                          <img
                                            src={services.sub_service_image}
                                            alt=""
                                          />
                                          <div
                                            id={`service_anim${i}`}
                                            className="overlay"
                                          >
                                            <div className="block trail-block1">
                                              <span></span>
                                              <span></span>
                                            </div>
                                            <div className="block trail-block2">
                                              <span></span>
                                              <span></span>
                                            </div>
                                            <div className="block trail-block3">
                                              <span></span>
                                              <span></span>
                                            </div>
                                            <div className="block trail-block4">
                                              <span></span>
                                              <span></span>
                                            </div>
                                            <div className="block trail-block5">
                                              <span></span>
                                              <span></span>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {' '}
                                        <div className="swipercontent">
                                          <h5>{services.sub_service_title}</h5>
                                          <p className="content">
                                            {services.sub_service_description_1}
                                          </p>
                                          <p className="subcontent">
                                            {services.sub_service_description_2}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </SwiperSlide>
                                )
                              })}
                            </>
                          ) : (
                            <>
                              {x.sub_services?.map((services, index) => {
                                return (
                                  <SwiperSlide
                                    key={index}
                                    ref={serviceRef}
                                    onClick={
                                      i % 2 === 0
                                        ? (e) => handleswiperclick(e, i, 1)
                                        : (e) => handleswiperclick(e, i, 2)
                                    }
                                    style={{
                                      backgroundColor:
                                        services.sub_service_title === ''
                                          ? ''
                                          : '#0d0d0d',
                                      border:
                                        services.sub_service_title === ''
                                          ? 'none'
                                          : '1px solid #fff',
                                      width: '12rem !important',
                                    }}
                                  >
                                    {services.about_service_isservice_text_or_img ===
                                    'Image' ? (
                                      <>
                                        {' '}
                                        <div className="servicesblock_img">
                                          <img
                                            src={services.sub_service_image}
                                            alt=""
                                          />
                                          <div
                                            id={`service_anim${i}`}
                                            className="overlay"
                                          >
                                            <div className="block trail-block1">
                                              <span></span>
                                              <span></span>
                                            </div>
                                            <div className="block trail-block2">
                                              <span></span>
                                              <span></span>
                                            </div>
                                            <div className="block trail-block3">
                                              <span></span>
                                              <span></span>
                                            </div>
                                            <div className="block trail-block4">
                                              <span></span>
                                              <span></span>
                                            </div>
                                            <div className="block trail-block5">
                                              <span></span>
                                              <span></span>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {' '}
                                        <div className="swipercontent">
                                          <h5>{services.sub_service_title}</h5>
                                          <p className="content">
                                            {services.sub_service_description_1}
                                          </p>
                                          <p className="subcontent">
                                            {services.sub_service_description_2}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </SwiperSlide>
                                )
                              })}
                            </>
                          )}
                        </Swiper>
                        <div id={`borderstatus${i}`} className="borderStatus">
                          <div className="borderline active"></div>
                          <div className="borderline"></div>
                          <div className="borderline gradient"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </>
        ) : null}
        <TestomonialAndContact />
        <Footer />
      </div>
    </>
  )
})
export default Services
