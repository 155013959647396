import { useSelector } from 'react-redux'

const Section4 = () => {
  const home = useSelector((state) => state.homedata)
  const handlescaling = (e, index) => {
    var div1 = document.getElementById('sec-4-ppt').getElementsByTagName('div')

    for (var i = 0; i < div1.length; i++) {
      var elem = div1[i]
      if (i === 0) {
        elem.children[0].style.height = '25%'
        elem.children[1].style.height = '25%'
      } else if (i === 1) {
        elem.children[0].style.height = '5%'
        elem.children[1].style.height = '28%'
      } else if (i === 2) {
        elem.children[0].style.height = '16%'
        elem.children[1].style.height = '10%'
      } else if (i === 3) {
        elem.children[0].style.height = '28%'
        elem.children[1].style.height = '18%'
      } else if (i === 4) {
        elem.children[0].style.height = '19%'
        elem.children[1].style.height = '29%'
      } else {
        elem.children[0].style.height = '34%'
        elem.children[1].style.height = '22%'
      }
    }

    var ele = div1[index - 1]
    if (index === 1) {
      ele.children[0].style.height =
        parseInt(ele.children[0].style.height) - 8 + '%'
      ele.children[1].style.height =
        parseInt(ele.children[1].style.height) - 8 + '%'
      div1[index].children[0].style.height =
        parseInt(div1[index].children[0].style.height) - 3 + '%'
      div1[index].children[1].style.height =
        parseInt(div1[index].children[1].style.height) - 5 + '%'
    } else if (index === 6) {
      div1[index - 2].children[0].style.height =
        parseInt(div1[index - 2].children[0].style.height) - 5 + '%'
      div1[index - 2].children[1].style.height =
        parseInt(div1[index - 2].children[1].style.height) - 5 + '%'
      ele.children[0].style.height =
        parseInt(ele.children[0].style.height) - 12 + '%'
      ele.children[1].style.height =
        parseInt(ele.children[1].style.height) - 12 + '%'
    } else if (index === 4) {
      ele.children[0].style.height =
        parseInt(ele.children[0].style.height) - 12 + '%'
      ele.children[1].style.height =
        parseInt(ele.children[1].style.height) - 12 + '%'
      div1[index - 2].children[0].style.height =
        parseInt(div1[index - 2].children[0].style.height) + 4 + '%'
      div1[index - 2].children[1].style.height =
        parseInt(div1[index - 2].children[1].style.height) + '%'
      div1[index].children[0].style.height =
        parseInt(div1[index].children[0].style.height) - 5 + '%'
      div1[index].children[1].style.height =
        parseInt(div1[index].children[1].style.height) - 5 + '%'
    } else {
      if (index === 2) {
        ele.children[0].style.height =
          parseInt(ele.children[0].style.height) - 5 + '%'
        ele.children[1].style.height =
          parseInt(ele.children[1].style.height) - 12 + '%'
      } else {
        ele.children[0].style.height =
          parseInt(ele.children[0].style.height) - 8 + '%'
        ele.children[1].style.height =
          parseInt(ele.children[1].style.height) - 8 + '%'
      }
      div1[index - 2].children[0].style.height =
        parseInt(div1[index - 2].children[0].style.height) - 5 + '%'
      div1[index - 2].children[1].style.height =
        parseInt(div1[index - 2].children[1].style.height) - 5 + '%'
      div1[index].children[0].style.height =
        parseInt(div1[index].children[0].style.height) - 5 + '%'
      div1[index].children[1].style.height =
        parseInt(div1[index].children[1].style.height) - 5 + '%'
    }

    e.preventDefault()
  }

  return (
    <div className="section4 oneScroll">
      <div className="video_stripes">
        <img
          src={home ? home.home_middle_banner_image : '/img/videoStripeimg.jpg'}
          alt="Side"
        />
        <div id="sec-4-ppt" className="stripes">
          <div
            onMouseOver={(e) => handlescaling(e, 1)}
            className="block trail-block1"
          >
            <span></span>
            <span></span>
          </div>
          <div
            onMouseOver={(e) => handlescaling(e, 2)}
            className="block trail-block2"
          >
            <span></span>
            <span></span>
          </div>
          <div
            onMouseOver={(e) => handlescaling(e, 3)}
            className="block trail-block3"
          >
            <span></span>
            <span></span>
          </div>
          <div
            onMouseOver={(e) => handlescaling(e, 4)}
            className="block trail-block4"
          >
            <span></span>
            <span></span>
          </div>
          <div
            onMouseOver={(e) => handlescaling(e, 5)}
            className="block trail-block5"
          >
            <span></span>
            <span></span>
          </div>
          <div
            onMouseOver={(e) => handlescaling(e, 6)}
            className="block trail-block6"
          >
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section4
