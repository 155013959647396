import axios from 'axios'

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config
    if (error.message === 'Network Error' && !originalRequest._retry) {
      originalRequest._retry = true
      return axiosInstance(originalRequest)
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
