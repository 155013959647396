import { Link } from 'react-router-dom'
import { getDateFormat } from '../../../constants/dateconvert'

const NewsSmallCard = ({ data, className, staticdata, language }) => {
  return (
    <>
      <div className={className}>
        <div className="news-image">
          <Link
            to={
              data.news_url
                ? language === 'en'
                  ? `/audio/news/${data.news_url}`
                  : `/${language}/audio/news/${data.news_url}`
                : null
            }
            state={data}
          >
            <img
              src={
                data.news_images.news_image1_275x170
                  ? data.news_images.news_image1_275x170
                  : '/audio/temp_img/Rectangle 1239.svg'
              }
              alt=""
            />
          </Link>
          {/* <img src="/temp_img/Rectangle 1239.svg" alt="" /> */}
        </div>
        <div className="news-details">
          <h4>{data.title ? data.title : ''}</h4>
        </div>
        <div className="news-footer">
          <div className="date">
            <p>
              {getDateFormat(
                data.published_date ? data.published_date : new Date(),
                'MMM DD YYYY',
              )}
            </p>
          </div>
          <div style={{ textTransform: 'uppercase' }} className="read">
            <Link
              to={
                data.news_url
                  ? language === 'en'
                    ? `/audio/news/${data.news_url}`
                    : `/${language}/audio/news/${data.news_url}`
                  : null
              }
              state={data}
            >
              {staticdata ? staticdata.news_content_read : 'Read1'}{' '}
              <span>→</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewsSmallCard
