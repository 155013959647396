import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import anime from 'animejs'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

const Section7 = () => {
  let home = useSelector((state) => state.homedata)
  const sidelineplay = useRef(null)

  useEffect(() => {
    var sideline = anime.timeline({ autoplay: false, loop: false }).add({
      targets: '.sec-7-slideup',

      translateY: [500, 15],
      easing: 'easeOutExpo',
      duration: 5400,
      delay: 100,
    })
    let observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio === 1) {
            sideline.play()
          }
        })
      },
      { threshold: 1 },
    )
    window.addEventListener('scroll', (e) => {
      if (sidelineplay.current != null) observer.observe(sidelineplay.current)
    })
  })
  const options = {
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    // slideTansition: 'linear',
    autoplayTimeout: 3000,
    autoplaySpeed: 3000,
    margin: 30,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 5,
      },
    },
  }

  return (
    <div className="section7 oneScroll">
      <div className="verticalLine" src="/audio/img/verticalLine.svg"></div>
      <div className="verticalLine right" src="/audio/img/verticalLine.svg"></div>
      <div className="sec-7-slideup slideup">
        <div className="sidelinecontent">
          <span className="outlinedot"></span>
          <span className="outlinedot"></span>
          <span className="outlinedot"></span>
          <span className="filldot"></span>
          <p>
            04{' '}
            {home
              ? home.home_section_4.home_section4_our_client
              : `Our1 Clients`}
          </p>
        </div>
      </div>
      <div className="section7width">
        <div className="our_clients">
          <div className="clients_head">
            <div className="shadows">
              <img
                alt="Side"
                className="leftShadow"
                src="/audio/img/clientlogos/shadowLeft.png"
              />
              <img
                alt="Side"
                className="rightShadow"
                style={{
                  right: '-3rem',
                  zIndex: '55',
                  top: '7rem',
                  width: '20%',
                }}
                src="/audio/img/clientlogos/shadowRight.png"
              />
              <img
                alt="Side"
                className="whiterightShadow"
                style={{ display: 'none' }}
                src="/audio/img/clientlogos/clients_whiteshadow.png"
              />
            </div>
            <h1>
              {home
                ? home.home_section_4.home_section4_our_client
                : `Our Clients1`}
            </h1>
            <div ref={sidelineplay} className="">
              <OwlCarousel className="clientsslider" {...options}>
                {home ? (
                  home.home_section_4.home_section4_our_client_images.map(
                    (data, index) => {
                      return (
                        <div className="item" key={index}>
                          <img
                            src={data.home_section4_client_image}
                            alt="Side"
                          />
                        </div>
                      )
                    },
                  )
                ) : (
                  <>
                    <div className="item">
                      <img
                        alt="Side"
                        src="/audio/img/clientlogos/CDPR_Logo-Horizontal-White_RGB.png"
                      />
                    </div>
                    <div className="item">
                      <img
                        alt="Side"
                        src="/audio/img/clientlogos/FOCUS_Entertainment-Logo_horizontal-WHITE.png"
                      />
                    </div>
                    <div className="item">
                      <img
                        alt="Side"
                        src="/audio/img/clientlogos/Frontier-Games-Logo-White.png"
                      />
                    </div>
                    <div className="item">
                      <img
                        alt="Side"
                        src="/audio/img/clientlogos/Outright-Games-logo.png"
                      />
                    </div>
                    <div className="item">
                      <img
                        alt="Side"
                        src="/audio/img/clientlogos/Remedy-Games-Logo-White.png"
                      />
                    </div>
                    <div className="item">
                      <img
                        alt="Side"
                        src="/audio/img/clientlogos/SQUARE-ENIX-White-logo.png"
                      />
                    </div>
                    <div className="item">
                      <img
                        alt="Side"
                        src="/audio/img/clientlogos/Ubisoft-Horizontal-Logo-WHITE_1.png"
                      />
                    </div>
                  </>
                )}
              </OwlCarousel>
            </div>
          </div>

          <p className="">
            {home
              ? home.home_section_4.home_section4_description1
              : `From world-renowned game publishers to industry newcomers about to make some noise, SIDE delivers top-tier audio services for the global game and interactive industry. Our clients encompass everything from AAA to indie game developers and publishers to the technology brands behind some of the biggest IPs and media platforms available today.`}
          </p>
          <p className="smalltext">
            {home
              ? home.home_section_4.description2
              : ` Our clients encompass everything from AAA to indie game developers and
              publishers to the technology brands behind some of the biggest IPs and
              media platforms available today.`}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Section7
