import React from 'react'

const NetworkErrorPage = () => {
  return (
    <div style={{ height: '40vh', padding: '5rem' }}>
      <h1>Network Error</h1>
      <p>
        It seems that your internet connection is down. Please check your
        connection and try again.
      </p>
    </div>
  )
}

export default NetworkErrorPage
