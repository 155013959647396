const SliderButtons = (props) => {
  return (
    <>
      <button
        disabled={props?.disabled}
        className={props.className}
        onClick={(e) => (props.onClick ? props.onClick() : '')}
      >
        <span className="arrowcircle">
          <span className="bg_fill"></span>
          <span className="bg_fill"></span>

          <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {props.path}
          </svg>
        </span>

        {/* <img src="/img/leftarrow.svg" alt="" /> */}
      </button>
    </>
  )
}

export default SliderButtons
