import Button from '../Buttons/Button'
import AudioPortal from './audio/audio'
import anime from 'animejs'
import { useSelector } from 'react-redux'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { clientUrl } from '../../constants/urls'

const Section3 = () => {
  const lang = useSelector((state) => state.lang)
  const sidelineplay = useRef(null)
  const sec3ref = useRef(null)
  const count = useRef(0)
  const navigate = useNavigate()
  const home = useSelector((state) => state.homedata)

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      var sideline = anime.timeline({ autoplay: false, loop: false })
      sideline.add({
        targets: '.sec-3-slideup',
        translateY: [200, -50],
        easing: 'easeOutExpo',
        duration: 5400,
        delay: 100,
      })
      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio === 0) {
              // setCount(entry.intersectionRatio);
              count.current = 0
            }
            if (entry.intersectionRatio === 1) {
              if (count.current === 0) {
                sideline.restart()
                count.current = 1
              }
            }
          })
        },
        { threshold: 1 },
      )

      if (sidelineplay.current != null) observer.observe(sidelineplay.current)
      e.preventDefault()
    })
    window.addEventListener('scroll', (e) => {
      var domsec6 = document.getElementById('animclipsec3')

      var animclip3 =
        domsec6 !== null ? domsec6.getElementsByTagName('div') : null
      var windowYScroll = window.scrollY

      if (animclip3 !== null) {
        if (
          (windowYScroll > 500 && windowYScroll < 1000) ||
          (windowYScroll > 1500 && windowYScroll < 2000)
        ) {
          for (let i = 0; i < animclip3.length; i++) {
            let elem = animclip3[i]
            if (i === 1) {
              elem.children[0].style.height = '15%'
              elem.children[1].style.height = '25%'
            } else if (i === 2) {
              elem.children[0].style.height = '35%'
              elem.children[1].style.height = '5%'
            } else if (i === 3) {
              elem.children[0].style.height = '8%'
              elem.children[1].style.height = '31%'
            } else if (i === 4) {
              elem.children[0].style.height = '20%'
              elem.children[1].style.height = '17%'
            }
          }
        }

        if (
          (windowYScroll > 1000 && windowYScroll < 1500) ||
          (windowYScroll > 2500 && windowYScroll < 3000)
        ) {
          for (let i = 0; i < animclip3.length; i++) {
            let elem = animclip3[i]
            if (i === 1) {
              elem.children[0].style.height = '20%'
              elem.children[1].style.height = '20%'
            } else if (i === 2) {
              elem.children[0].style.height = '30%'
              elem.children[1].style.height = '10%'
            } else if (i === 3) {
              elem.children[0].style.height = '12%'
              elem.children[1].style.height = '26%'
            } else if (i === 4) {
              elem.children[0].style.height = '14%'
              elem.children[1].style.height = '22%'
            }
          }
        }
      }
      //----------------------------codes ends here for location card sec5----------//
      e.preventDefault()
    })
  }, [])

  const navigateToAboutUs = () => {
    navigate(lang === 'en' ? clientUrl.about : `/${lang}/about`)
  }

  return (
    <div className="section3 oneScroll" id="section3">
      <div className="sec-3-slideup slideup">
        <div className="sidelinecontent">
          <span className="outlinedot"></span>
          <span className="filldot"></span>
          <p>02 {home ? home.home_section_2.home_section2_who_we_are : ''}</p>
          <span className="line"></span>
          <span className="outlinedot"></span>
          <span className="outlinedot"></span>
        </div>
      </div>
      <div className="section3width">
        <h5>
          {home
            ? home.home_section_2.section2_description1
            : 'We bring games to life.'}
        </h5>
        <div className="sidecontent">
          <div className="leftcontent">
            <p ref={sidelineplay}>
              {home
                ? home.home_section_2.home_section2_description2
                : `For over 25 years, SIDE's award-winning team of technical and
              creative experts have been providing voice production, casting,
              directing, performance capture, localized audio, sound design,
              music composition, and soundtrack production to the global games
              industry.`}
            </p>
            <div className="indentcontent">
              <p>
                {home
                  ? home.home_section_2.home_section2_description3
                  : `To this day, our team holds the same collective intention: to
                develop the truest interpretation of each client's vision, for
                audiences of every genre, on any platform, in all languages.`}
              </p>
              <div className="custombtn desk_displaybtn">
                <Button
                  text={
                    home
                      ? home.home_section_2.home_section2_learn_more_about_us
                      : 'Learn More about us'
                  }
                  className={''}
                  onClick={(e) => navigateToAboutUs(e)}
                />
              </div>
              <div className="custombtn mbl_displaybtn">
                <Button
                  text={
                    home
                      ? home.home_section_2.home_section2_about_us
                      : 'about us'
                  }
                  onClick={(e) => navigateToAboutUs(e)}
                />
              </div>
            </div>
          </div>
          <div ref={sec3ref} className="rightcontent">
            <div className="strips-boxs">
              <AudioPortal
                animation={false}
                audio={home?.home_section_2?.home_section2_audio}
                isInlineStyle={true}
              />
              <div className="sbs-img" id="animclipsec3">
                <img
                  src={
                    home
                      ? home.home_section_2.home_section2_image
                      : '/img/games-life.jpg'
                  }
                  alt=""
                />
                <div className="overlay">
                  <div className="block trail-block1">
                    <span></span>
                    <span></span>
                  </div>
                  <div className="block trail-block2">
                    <span></span>
                    <span></span>
                  </div>
                  <div className="block trail-block3">
                    <span></span>
                    <span></span>
                  </div>
                  <div className="block trail-block4">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section3
