const changeLanguage = (value) => {
  return {
    type: 'changeLanguage',
    lang: value,
  }
}

const changeLoaderState = (bool) => {
  return {
    type: 'changeLoaderState',
    loadingState: bool,
  }
}

const languagedata = (data) => {
  return {
    type: 'language_data',
    data: data,
  }
}
const projectdata = (projectdata) => {
  return {
    type: 'project_data',
    projectdata: projectdata,
  }
}
const contactdata = (contactdata) => {
  return {
    type: 'contact_data',
    contactdata: contactdata,
  }
}

const termsdata = (termsdata) => {
  return {
    type: 'terms_data',
    termsdata: termsdata,
  }
}
const servicedata = (servicedata) => {
  return {
    type: 'service_data',
    servicedata: servicedata,
  }
}
const homedata = (homedata) => {
  return {
    type: 'home_data',
    homedata: homedata,
  }
}
const footerdata = (footerdata) => {
  return {
    type: 'footer_data',
    footerdata: footerdata,
  }
}
export {
  changeLanguage,
  changeLoaderState,
  languagedata,
  projectdata,
  contactdata,
  termsdata,
  servicedata,
  homedata,
  footerdata,
}
