import { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getLanguage } from '../../../constants/urls'

const AudioPortal = (props) => {
  const [per, setPer] = useState(0)
  const [r, Setr] = useState(
    window.innerWidth <= 1300 ? 75 : window.innerWidth <= 1700 ? 80 : 90,
  )
  const [r1, Setr1] = useState(
    window.innerWidth <= 1300 ? 75 : window.innerWidth <= 1700 ? 80 : 100,
  )
  const audioRef = useRef(null)
  const circle = useRef(null)
  var mobileTimer
  let home = useSelector((state) => state.homedata)

  const defaultLang = [
    {
      lang: 'en' || '',
      description: ['we', 'bring', 'games', 'to', 'life'],
      heading: ['BRINGING', 'YOUR', 'STORY', 'TO THE WORLD'],
      audio: ['Click To', 'Play Audio'],
      scroll: 'Scroll Down',
    },
    {
      lang: 'zh-cht',
      description: ['我們', '令', '遊戲', '栩栩', '如生'],
      heading: ['把', '您的', '故事', '帶給全世界'],
      audio: ['點擊', '播放音檔'],
      scroll: '向下捲動',
    },
    {
      lang: 'zh-chs',
      description: ['我们', '赋予', '游戏', '以', '生命'],
      heading: ['将', '您的', '故事', '致全世界'],
      audio: [' 点击以', ' 播放音频'],
      scroll: '向下滚动',
    },
    {
      lang: 'ja',
      description: ['私たちは', 'ゲームに', '命を', '吹き込みます', 'そして'],
      heading: ['あなたの', 'プロジェクトの', '話', '世界へ'],
      audio: ['タップして', '音声をプレイ'],
      scroll: '下へスクロール',
    },
    {
      lang: 'ko',
      description: ['우리는', '여러분의', '게임에', '생명을', '불어넣습니다'],
      heading: ['여러분의', '프로젝트를', '이야기', '세계로'],
      audio: ['탭하여', '오디오 재생'],
      scroll: '아래로 스크롤',
    },
    {
      lang: 'fr-ca',
      description: ['NOUS', 'DONNONS', 'VIE', 'AUX', 'JEUX'],
      heading: ['PARTAGEONS', 'VOTRE', "D'HISTOIRE", 'AVEC LE MONDE'],
      audio: ['Cliquez pour', "JOUER L'AUDIO"],
      scroll: 'FAIRE DÉFILER',
    },
  ]

  function getDescription(langCode) {
    const langObj = defaultLang.find((item) => item.lang === langCode)
    return langObj ? langObj : []
  }

  const staticData = getDescription(getLanguage())
  useEffect(() => {
    window.addEventListener('resize', () => {
      let width = window.innerWidth
      // console.log(width)
      if (width <= 1300) {
        Setr(75)
        Setr1(75)
      } else if (width <= 1700 && width > 1300) {
        Setr(80.0)
        Setr1(80.0)
      } else {
        Setr(90.0)
        Setr1(100.0)
      }
    })
  }, [])

  const playAudio = (b) => {
    const audio = audioRef.current
    // && b && document.getElementById('scrollEnable').classList.contains('minim-slide')
    if (
      audio.paused &&
      b &&
      document.getElementById('scrollEnable').classList.contains('minim-slide')
    ) {
      audio
        .play()
        .then((success) => {
          let temp = 0
          mobileTimer = setInterval(() => {
            var per = (r * audio.currentTime) / audio.duration
            let perRound = per.toFixed(2)
            if (Number(perRound) >= Number(temp)) {
              setPer(perRound)
              if (per === r) {
                setPer(0)
                clearInterval(mobileTimer)
              }
              temp = perRound
            }
          }, 100)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      let temp = 0
      if (audio.paused && b === false) {
        audio
          .play()
          .then((success) => {
            mobileTimer = setInterval(() => {
              var per = (r1 * audio.currentTime) / audio.duration
              let perRound = per.toFixed(2)
              if (Number(perRound) >= Number(temp)) {
                setPer(perRound)
                if (per === r1) {
                  setPer(0)
                  clearInterval(mobileTimer)
                }
                temp = perRound
              }
            }, 100)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        audio.pause()
        audio.currentTime = audio.currentTime
        clearInterval(mobileTimer)
      }
    }
  }
  const inlineStyle = props.isInlineStyle ? { width: '85%', height: '85%' } : {}
  return (
    <>
      <div
        onClick={(e) => playAudio(props?.animation)}
        className={
          props?.animation
            ? 'circular-progress abc device_specific'
            : 'circular-progress'
        }
        style={{ userSelect: 'none', zIndex: '10' }}
      >
        <div className="inner-blur" style={inlineStyle}></div>
        <svg>
          <circle cx="90" cy="89" r="85"></circle>
          <circle
            ref={circle}
            cx="90"
            cy="89"
            r="85"
            style={{ '--percent': per }}
          ></circle>
        </svg>
        <div className="percentage">
          <p className="till_desk">
            {home ? home.top_banner.home_press_to : staticData.audio[0]}
            <br />
            {home ? home.top_banner.home_play_audio : staticData.audio[1]}
          </p>
          <p className="in_mbl">
            {home ? home.top_banner.home_tap_to : staticData.audio[0]}
            <br />
            {home ? home.top_banner.home_play_audio : staticData.audio[1]}
          </p>
        </div>
        <audio
          ref={audioRef}
          preload="auto"
          controls
          src={props?.audio}
          style={{ display: 'none' }}
        ></audio>

        {props?.animation ? (
          <div className=" new_abc scrollIndicator_position">
            <div className="scrollIndicator">
              {home?.top_banner && (
                <span className="text">
                  {home ? home.top_banner.home_scroll_down : staticData.scroll}
                </span>
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default AudioPortal
