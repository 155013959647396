import { useState, useEffect, useRef } from 'react'
import anime from 'animejs'
import { useSelector } from 'react-redux'
import { forwardRef } from 'react'
import { useNavigate } from 'react-router'
import { clientUrl, getLanguage } from '../../constants/urls'

const Contact = forwardRef((props, ref) => {
  let navigate = useNavigate()
  const footer = useSelector((state) => state.footerdata)
  let param = window.location.href.split('/')
  let url = param[param.length - 1]

  if (url === 'contactus') document.title = 'SIDE - Contact'
  if (ref !== null) ref.current = 1
  const data = useSelector((state) => state.data)

  const contactRef = useRef(null)
  const count1 = useRef(0)
  const [size, setsize] = useState(window.innerWidth <= 991 ? false : true)

  const handlecontact = () => {
    const language = getLanguage()
    if (language !== 'en') {
      navigate(`/${language}${clientUrl.contactus}`)
    }
    if (language === 'en') {
      navigate(`${clientUrl.contactus}`)
    }
  }
  const freeze = (e, index) => {
    const cursor = document.querySelector(`.cursor-contact${index}`)

    let x = e.pageX - window.scrollX
    let y = e.pageY - window.scrollY

    if (cursor !== null) {
      if (cursor.style.display === 'none') {
        cursor.style.display = 'block'
        cursor.style.left = `${x}px`
        cursor.style.top = `${y}px`
      }
    }
  }

  const unfreeze = (e, index) => {
    const cursor = document.querySelector(`.cursor-contact${index}`)

    if (cursor !== null) {
      cursor.style.display = 'none'
    }
  }
  useEffect(() => {
    var textanime1 = anime.timeline({ loop: false, autoplay: false })
    textanime1
      .add({
        targets: '.contact_sec_line',
        translateY: [0],
        easing: 'easeOutExpo',
        duration: 0,
        delay: 0,
      })
      .add({
        targets: '.contact_sec_line',
        translateY: [200, 0],
        easing: 'easeOutExpo',
        duration: 1500,
        delay: 0,
      })
    window.addEventListener('scroll', (e) => {
      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio === 0) {
              count1.current = 0
            }
            if (entry.intersectionRatio > 0.309) {
              if (count1.current === 0) textanime1.restart()
              count1.current = 1
            }

            if (entry.intersectionRatio !== 1) {
            }
          })
        },
        { threshold: 1 },
      )

      if (contactRef.current !== null) observer.observe(contactRef.current)
      e.preventDefault()
    })
  }, [data])
  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      let curs = document.querySelectorAll('#cursor-contact')
      if (curs !== null) {
        for (const e of curs) {
          if (e !== null) e.style.display = 'none'
        }
      }
    })

    window.addEventListener('resize', () => {
      let width = window.innerWidth

      if (width <= 991) {
        setsize(false)
      } else {
        setsize(true)
        setsize(true)
      }
    })
  }, [])
  return (
    <div className="" ref={contactRef}>
      <div className="verticalLine right"></div>
      <div
        onMouseMove={(e) => freeze(e, 1)}
        onMouseLeave={(e) => unfreeze(e, 1)}
        className="contact_sec_circle"
      >
        <div className="circle_head">
          <h1>
            {footer ? footer.tellusyourstory.tellus_story_tell_us : 'Tell us'}
          </h1>
          <h1>{footer ? footer.tellusyourstory.tellus_story_your : 'Your'}</h1>
          <h1>
            {footer ? footer.tellusyourstory.tellus_story_story : 'Story'}
          </h1>
        </div>
        <div
          id={size === true ? `cursor-contact` : ''}
          className={size === true ? `cursor-contact${1}` : ''}
          style={{ display: size === true ? 'none' : 'block' }}
        >
          <div onClick={(e) => handlecontact()} className="contact_circle">
            <p>
              {footer ? footer.tellusyourstory.tellus_story_contact : 'Contact'}{' '}
              <span>
                {footer ? footer.tellusyourstory.tellus_story_us : 'Us'}{' '}
              </span>{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
})
export default Contact
