const Leftbutton = (props) => {
  return (
    <>
      <button
        className={props.className}
        name=""
        onClick={(e) => (props.onClick ? props.onClick() : '')}
      >
        {/* <span className="animateText">{props.text}</span>
        <span className="animateText2">{props.text}</span> */}

        <span
          className="arrowcircle"
          onClick={(e) => (props.onClick ? props.onClick() : '')}
        >
          <span className="bg_fill"></span>
          <span className="bg_fill"></span>
          <img src="/audio/temp_img/Union (2).svg"></img>
        </span>
      </button>
    </>
  )
}
export default Leftbutton
