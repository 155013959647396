import { Fragment, useEffect, useRef, useState } from 'react'
import Footer from '../../components/footer/footer'
import '../../styles/contactus/contactus.css'
import Button from '../Buttons/Button'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useNavigate } from 'react-router-dom'
import 'swiper/css/pagination'
import 'swiper/css'
import anime from 'animejs'
import 'swiper/css/bundle'
import SliderButtons from '../Buttons/SliderButtons'
import axiosInstance from '../../services/axiosInstance'
import { useDispatch, useSelector } from 'react-redux'
import { devapiUrl } from '../../constants/devapi'
import parse from 'html-react-parser'
import { changeLoaderState } from '../../actions/lngactions'
import { country, langCodes } from '../../constants/country'
import { getLanguage } from '../../constants/urls'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Notfound from '../NotFound/Notfound'

const Contactus = () => {
  let navigate = useNavigate()
  document.title = 'SIDE - Contact'
  const loadingState = useSelector((state) => state.loadingState)
  let count = 0
  let bool = false
  const dispatch = useDispatch()
  let langcode = useSelector((state) => state.lang)

  const emailPattern =
    /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-Z]{2,})+$/

  const namepattern = /^(?:(?:\p{L}\s?){3,})$/u

  // if(number.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && ! (number.match(/0{5,}/)) )
  //     var checkbox = document.getElementById('checkbox');
  // // Interpreted as "Is it true that checkbox.checked == true"?
  // if(checkbox.checked){

  // }

  const [locationdropdown, Setlocationdropdown] = useState(false)
  const [data, Setdata] = useState([])
  const [locdata, Setlocdata] = useState(null)
  const [heardropdown, Setheardropdown] = useState(false)
  const [naturedropdown, Setnaturedropdown] = useState(false)
  const [toggle, Settoggle] = useState(true)
  const [buttonText, setButtonText] = useState(
    data.contacts_contact_form
      ? data.contacts_contact_form.contacts_usubmit
      : 'Submit',
  )
  const locationRef = useRef(null)
  const hearRef = useRef(null)
  const natureRef = useRef(null)
  const nameRef = useRef(null)
  const mailRef = useRef(null)
  const messageRef = useRef(null)
  const phoneRef = useRef(null)
  const selected_locationRef = useRef('')
  const submitRef = useRef(null)
  const selected_hearRef = useRef('')
  const selected_naturedRef = useRef('')
  const locationDivRef = useRef('')
  // const inputcaptchaRef = useRef('')
  const natureOfEnquiryDivRef = useRef('')
  const hearAboutUsDivRef = useRef('')
  const locationInputRef = useRef(null)
  const enquiryInputRef = useRef(null)
  const hearAboutInputRef = useRef(null)
  const [dropdown1, Setdropdown1] = useState([])
  const [dropdown2, Setdropdown2] = useState([])
  const [submitstyle, Setsubmitstyle] = useState(false)
  const [show, setshow] = useState(window.innerWidth < 991 ? true : false)
  const [validation_location, Setvalidation_location] = useState(
    'Please select location.',
  )
  const [validation_nature, Setvalidation_nature] = useState(
    'Please select nature of enquiry.',
  )
  const [validation_hear, Setvalidation_hear] = useState(
    'Please select how did you hear about us.',
  )
  const [validation_name, Setvalidation_name] = useState(
    'Please enter a name consisting of three or more characters',
  )
  const [validation_phone, Setvalidation_phone] = useState(
    'Please enter valid phone nuumber1.',
  )
  const [validation_message, Setvalidation_message] = useState(
    'Please enter a message consisting of twenty or more characters.',
  )
  const [validation_email, Setvalidation_email] = useState(
    'Invalid Email Address.',
  )
  const [validation_term, Setvalidation_term] = useState(
    'Please agree to our Terms & conditions.',
  )
  const [countryList, setCountryList] = useState(country)

  const [initialEnquiryList, setIitialEnquiryList] = useState([])
  const [initialHearAboutUsList, setInitialHearAboutUsList] = useState([])
  const [isNotFound, setIsNotFound] = useState(false)

  // const captchaRef = useRef(null)

  const animation = () => {
    let head = document.querySelector('.Headsection')
    if (head !== null) head.style.opacity = 0

    if (loadingState === false) count = count + 1
    // if (count==2) {
    if (head != null) head.style.opacity = 1
    anime
      .timeline({ loop: false })
      .add({
        targets: '.contact-heading .headTag1',
        translateY: [200, 0],
        easing: 'easeOutExpo',
        duration: 3400,
      })
      .add({
        targets: '.headTag1',
        opacity: 1,
        duration: 100,
        easing: 'easeOutExpo',
        delay: 10,
        loop: false,
      })
    anime
      .timeline({ loop: false })
      .add({
        targets: '.contact-heading .headTag2',
        translateY: [200, 0],
        easing: 'easeOutExpo',
        duration: 3400,
      })
      .add({
        targets: '.headTag2',
        opacity: 1,
        duration: 100,
        easing: 'easeOutExpo',
        delay: 10,
        loop: false,
      })
    anime.timeline({ loop: false }).add({
      targets: '.contact_sideline',
      translateY: [500, 160],
      easing: 'easeOutExpo',
      duration: 7400,
      delay: 100,
    })
    // }
  }
  let locationanim = false
  useEffect(() => {
    window.addEventListener('resize', () => {
      let width = window.innerWidth
      if (width < 991) {
        setshow(true)
      } else {
        setshow(false)
      }
    })
    window.addEventListener('scroll', (e) => {
      if (window.scrollY >= 660 && window.scrollY <= 2000) {
        if (locationanim === false) {
          anime.timeline({ loop: false }).add({
            targets: '.location_sideline',
            translateY: [2000, 1000],
            easing: 'easeOutExpo',
            duration: 7400,
            delay: 100,
          })
          anime.timeline({ loop: false }).add({
            targets: '.location_sideline1',
            translateY: [200, -100],
            easing: 'easeOutExpo',
            duration: 7400,
            delay: 100,
          })
        }
        locationanim = true
      } else {
        locationanim = false
      }
    })
  }, [show])
  useEffect(() => {
    let head = document.querySelector('.Headsection')
    if (head !== null) head.style.opacity = 0
    dispatch(changeLoaderState(true))
    window.scrollTo({ top: 0, left: 0 })

    var contactanime = document.getElementById('contact_stripes')
    var contactblocks =
      contactanime !== null ? contactanime.getElementsByTagName('div') : null
    //  var hoverslide=document.querySelectorAll('.card-card');

    for (let i = 0; i < contactblocks.length; i++) {
      contactblocks[i].addEventListener('mouseover', function () {
        let ele = contactblocks[i]
        ele.children[0].classList.add('contact-hover-1')
        ele.children[1].classList.add('contact-hover-2')
      })
      contactblocks[i].addEventListener('mouseleave', function () {
        setTimeout(() => {
          let ele = contactblocks[i]
          ele.children[0].classList.remove('contact-hover-1')
          ele.children[1].classList.remove('contact-hover-2')
          // hoverslide[i].classList.remove('apply-hover');
        }, 200)
      })
    }
    // setTimeout(() => {
    //     Setcontactloading(true)
    // },2200);

    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [selected_hearRef, selected_naturedRef, selected_locationRef])
  const handleClickOutside = (e) => {
    if (
      locationRef.current &&
      !locationRef.current.contains(e.target) &&
      !e.target.classList.contains('location-label')
    ) {
      Setlocationdropdown(false)
    }

    if (
      natureRef.current &&
      !natureRef.current.contains(e.target) &&
      !e.target.classList.contains('nature-label')
    ) {
      Setnaturedropdown(false)
    }
    if (
      hearRef.current &&
      !hearRef.current.contains(e.target) &&
      !e.target.classList.contains('hear-label')
    ) {
      Setheardropdown(false)
    }
  }
  const handleNatureclick = (e) => {
    selected_naturedRef.current = e.target.innerHTML
    setErr((data) => ({ ...data, natureErr: '' }))
    setisNatureValidated(true)
    setContactData((data) => ({ ...data, nature: selected_naturedRef.current }))
  }

  const handleLocationclick = (e) => {
    selected_locationRef.current = e.target.innerHTML
    setErr((data) => ({ ...data, locationErr: '' }))
    setisLocationValidated(true)
    setContactData((data) => ({
      ...data,
      location: selected_locationRef.current,
    }))
  }

  const handleHearclick = (e) => {
    selected_hearRef.current = e.target.innerHTML
    setErr((data) => ({ ...data, hearErr: '' }))
    setisHearValidated(true)
    setContactData((data) => ({ ...data, hear: selected_hearRef.current }))
  }
  const handleaccordion = (index) => {
    let x = document.querySelectorAll('.accordion-collapse')
    let y = document.querySelectorAll('.accordion-item')
    let z = document.getElementById(`${index - 1}`)
    var ele = document.querySelector(`.location-${index}`)
    var ele1 = document.querySelector(`.accordion-item-${index}`)

    if (ele !== null) {
      if (ele.classList.contains('show')) ele.classList.remove('show')
      else ele.classList.add('show')
      if (ele1.classList.contains('rotate-cross'))
        ele1.classList.remove('rotate-cross')
      else ele1.classList.add('rotate-cross')
    }

    for (let i = 0; i < x.length; i++) {
      if (i !== index - 1) x[i].classList.remove('show')
    }
    for (let j = 0; j < y.length; j++) {
      if (j !== index - 1) {
        y[j].classList.remove('rotate-cross')
      }
    }
    if (z != null) {
      z.scrollIntoView({ block: 'center' })
    }
  }

  const [isNameValidated, setIsNameValidated] = useState(true)
  const [isMailValidated, setMailValidated] = useState(true)
  const [isMessageValidated, setMessageValidated] = useState(true)
  const [isTelephoneValidated, setIsTelephoneValidated] = useState(true)
  const [isCheckValidated, setisCheckValidated] = useState(true)
  const [isLocationValidated, setisLocationValidated] = useState(false)
  const [isHearValidated, setisHearValidated] = useState(false)
  const [isNatureValidated, setisNatureValidated] = useState(false)
  // const [isCaptchaValidated, setisCaptchaValidated] = useState(false)
  const [contactData, setContactData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
    condition: '',
    location: '',
    hear: '',
    nature: '',
    // captcha: '',
  })
  const [err, setErr] = useState({
    nameErr: '',
    mailErr: '',
    messageErr: '',
    phoneErr: '',
    checkErr: '',
    locationErr: '',
    hearErr: '',
    natureErr: '',
    // captchaErr: '',
  })
  //validation

  const handlechange = (e) => {
    const { id, value } = e.target
    // console.log(e.target.value)

    if (value.trim().length === 0) {
      if (id === 'name') {
        setErr((data) => ({ ...data, nameErr: '' }))

        // setMailValidated(true)
        setIsNameValidated(true)
      }
      if (id === 'email') {
        setErr((data) => ({ ...data, mailErr: '' }))
        setMailValidated(true)
      }
      if (id === 'message') {
        setMessageValidated(true)
        setErr((data) => ({ ...data, messageErr: '' }))
      }
      if (id === 'phone') {
        setIsTelephoneValidated(true)
        setErr((data) => ({ ...data, phoneErr: '' }))
      }

      setContactData((data) => ({ ...data, [id]: value }))
    } else {
      if (id === 'name') {
        let errName = value.trim().length >= 3 ? '' : validation_name
        if (errName === '') {
          setErr((data) => ({ ...data, nameErr: '' }))
          setContactData((data) => ({ ...data, [id]: value.trim() }))
          setIsNameValidated(true)
        } else {
          setErr((data) => ({ ...data, nameErr: errName }))
          setIsNameValidated(false)
          setContactData((data) => ({ ...data, [id]: value }))
        }
      }
      if (id === 'email') {
        let errEmail = emailPattern.test(value) ? '' : validation_email
        if (errEmail === '') {
          setContactData((data) => ({ ...data, [id]: value }))
          setMailValidated(true)
          setErr((data) => ({ ...data, mailErr: '' }))
        } else {
          setErr((data) => ({ ...data, mailErr: errEmail }))
          setMailValidated(false)
          setContactData((data) => ({ ...data, [id]: value }))
        }
      }

      if (id === 'message') {
        let errMessage = value.trim().length >= 20 ? '' : validation_message
        if (errMessage === '') {
          setErr((data) => ({ ...data, messageErr: '' }))
          setContactData((data) => ({ ...data, [id]: value }))
          setMessageValidated(true)
        } else {
          setErr((data) => ({ ...data, messageErr: errMessage }))
          setMessageValidated(false)
          setContactData((data) => ({ ...data, [id]: value }))
        }
      }
      if (id === 'phone') {
        let phoneErr = value.match(/^\+?[^\d\n]*(\d[^\d\n]*){6,15}$/)
          ? ''
          : validation_phone
        // if(number.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && ! (number.match(/0{5,}/)) )
        if (phoneErr === '') {
          setErr((data) => ({ ...data, phoneErr: '' }))
          setContactData((data) => ({ ...data, [id]: value }))
          setIsTelephoneValidated(true)
        } else {
          setErr((data) => ({ ...data, phoneErr: phoneErr }))
          setIsTelephoneValidated(false)
          setContactData((data) => ({ ...data, [id]: value }))
        }
      }
      if (id === 'conditions') {
        let checkbox = document.getElementById('conditions')
        let checkErr = checkbox.checked ? '' : validation_term

        if (checkErr === '') {
          setErr((data) => ({ ...data, checkErr: '' }))
          setContactData((data) => ({ ...data, [id]: value }))
          setisCheckValidated(true)
          Setsubmitstyle(true)
        } else {
          setErr((data) => ({ ...data, checkErr: checkErr }))
          setisCheckValidated(false)
          setContactData((data) => ({ ...data, [id]: value }))
          Setsubmitstyle(false)
        }
      }
    }
  }

  const decodeCaptcha = (value) => {
    let hash = 5381
    value = value

    for (let i = 0; i < value.length; i++) {
      hash = (hash << 5) + hash + value.charCodeAt(i)
    }
    return hash
  }

  const submitContactdata = (e) => {

    let checkbox = document.getElementById('conditions')
    if (selected_locationRef.current === '') {
      setErr((data) => ({ ...data, locationErr: validation_location }))
      setisLocationValidated(false)
      setContactData((data) => ({
        ...data,
        location: selected_locationRef.current,
      }))
    }
    if (selected_naturedRef.current === '') {
      setErr((data) => ({ ...data, natureErr: validation_nature }))
      setisNatureValidated(false)
      setContactData((data) => ({
        ...data,
        nature: selected_naturedRef.current,
      }))
    }
    if (selected_hearRef.current === '') {
      setErr((data) => ({ ...data, hearErr: validation_hear }))
      setisHearValidated(false)
      setContactData((data) => ({ ...data, hear: selected_hearRef.current }))
    }
    if (
      isLocationValidated &&
      isHearValidated &&
      isNatureValidated &&
      isNameValidated &&
      isMailValidated &&
      isMessageValidated &&
      isTelephoneValidated &&
      // isCaptchaValidated &&
      isCheckValidated &&
      contactData.name.length >= 3 &&
      contactData.email.length > 0 &&
      contactData.message.length >= 20 &&
      checkbox.checked
    ) {
      console.log('contact data is', contactData)
      setButtonText(
        data.contacts_contact_form
          ? data.contacts_contact_form.contact_message_sent
          : 'Message sent',
      )
      document.getElementById('message').setAttribute('disabled', 'disabled')
      document.getElementById('name').setAttribute('disabled', 'disabled')
      document.getElementById('email').setAttribute('disabled', 'disabled')
      document.getElementById('conditions').setAttribute('disabled', 'disabled')
      document.getElementById('phone').setAttribute('disabled', 'disabled')
      if (submitRef.current != null) {
        submitRef.current.setAttribute('disabled', 'disabled')
      }
      Settoggle(false)

      nameRef.current.value = ''
      mailRef.current.value = ''
      messageRef.current.value = ''
      phoneRef.current.value = ''
      selected_hearRef.current = ''
      selected_locationRef.current = ''
      selected_naturedRef.current = ''
      checkbox.checked = false
      // inputcaptchaRef.current.value = ''

      //not this remove onclick attribute to them
      //   button.removeAttribute("onclick");
      locationRef.current.removeAttribute('onClick')

      Setlocationdropdown(false)
      Setheardropdown(false)
      Setnaturedropdown(false)
      axiosInstance
        .post(`${devapiUrl}addcontactdetails`, contactData)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              document.getElementById('message').removeAttribute('disabled')
              document.getElementById('name').removeAttribute('disabled')
              document.getElementById('email').removeAttribute('disabled')
              document.getElementById('conditions').removeAttribute('disabled')
              document.getElementById('phone').removeAttribute('disabled')
              if (submitRef.current != null) {
                submitRef.current.removeAttribute('disabled')
              }
              Settoggle(true)
              setContactData({
                name: '',
                email: '',
                message: '',
                phone: '',
                // captcha: '',
              })

              setButtonText(
                data.contacts_contact_form
                  ? data.contacts_contact_form.contacts_usubmit
                  : 'Submit',
              )
              throwNotification()
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      if (
        namepattern.test(contactData.name) &&
        contactData.name.trim().length > 0
      ) {
        setIsNameValidated(true)
        setErr((data) => ({ ...data, nameErr: '' }))
      } else {
        setIsNameValidated(false)
        setErr((data) => ({ ...data, nameErr: validation_name }))
      }

      if (
        emailPattern.test(contactData.email) &&
        contactData.email.length > 0
      ) {
        setMailValidated(true)
        setErr((data) => ({ ...data, mailErr: '' }))
      } else {
        setMailValidated(false)
        setErr((data) => ({ ...data, mailErr: validation_email }))
      }
      if (contactData.message.length >= 20) {
        setErr((data) => ({ ...data, messageErr: '' }))
        setMessageValidated(true)
      } else {
        setErr((data) => ({ ...data, messageErr: validation_message }))
        setMessageValidated(false)
      }
      if (contactData.phone.trim() === '') {
        setErr((data) => ({ ...data, phoneErr: '' }))
        setIsTelephoneValidated(true)
      } else {
        if (contactData.phone.match(/^\+?[^\d\n]*(\d[^\d\n]*){6,15}$/)) {
          setErr((data) => ({ ...data, phoneErr: '' }))
          setIsTelephoneValidated(true)
        } else {
          setErr((data) => ({ ...data, phoneErr: validation_phone }))
          setIsTelephoneValidated(false)
        }
      }
      if (checkbox.checked) {
        setErr((data) => ({ ...data, checkErr: '' }))
        setisCheckValidated(true)
      } else {
        setErr((data) => ({ ...data, checkErr: validation_term }))
        setisCheckValidated(false)
      }
    }
  }
  var oldlang
  var language = getLanguage()
  // let countback =0;
  //   useEffect(()=>{

  //     window.addEventListener("popstate",(event)=>{

  //       console.log('count bac is ',countback,event)

  //       onBackButtonEvent(event,oldlang);
  //       countback++;
  //     })
  //     oldlang=getLanguage()
  //   },[langcode])

  useEffect(() => {
    //  console.log('language is ',language)
    getcontactdata(language)
    getlocationdata(language)
  }, [langcode])

  const handleInputKeyDown = (event) => {
    event.preventDefault()

    // Get the current value of the input field
    const currentValue = event.target.value

    // Get the keyCode of the pressed key
    const keyCode = event.keyCode || event.which

    // Get the current cursor position
    const cursorPosition = event.target.selectionStart

    // Get the character that was typed
    const char = String.fromCharCode(keyCode)

    // Construct the new value with the typed character in uppercase
    const newValue =
      currentValue.substring(0, cursorPosition) +
      char.toUpperCase() +
      currentValue.substring(cursorPosition)
  }

  const getlocationdata = (lang) => {
    // let array = []
    axiosInstance.get(`${devapiUrl}${lang}/locations`).then((res) => {
      Setlocdata(res.data.locations)
      // selected_locationRef.current = res.data.locations[0].location_name;
      // res.data.locations.forEach((x, index) => {
      //   array.push(x.location_name)
      // })
      // Setdropdown3(array)
      //   Setcontactloading(true)

      dispatch(changeLoaderState(false))
      if (bool === false && langcode === getLanguage()) {
        setTimeout(() => {
          animation()
        }, 500)
      }
      bool = true
      let param = window.location.href.split('/')
      var ele = document.getElementById(`${param[param.length - 1]}`)

      if (ele !== null) ele.scrollIntoView()
    })
  }
  const getcontactdata = (lang) => {
    axiosInstance.get(`${devapiUrl}${lang}/side-pages/contact`).then((res) => {
      // dispatch(servicedata(res.data.services))
      // dispatch(changeLanguage(lang))
      Setdata(res.data.contact)

      Setvalidation_email(
        res.data.contact.contacts_contact_form.contacts_validation_messages
          .email_validation_message,
      )
      Setvalidation_message(
        res.data.contact.contacts_contact_form.contacts_validation_messages
          .add_a_message_validation_message,
      )
      Setvalidation_hear(
        res.data.contact.contacts_contact_form.contacts_validation_messages
          .how_did_you_hear_about_us_validation_message,
      )
      Setvalidation_nature(
        res.data.contact.contacts_contact_form.contacts_validation_messages
          .nature_of_enquiry_validation_message,
      )
      Setvalidation_location(
        res.data.contact.contacts_contact_form.contacts_validation_messages
          .location_validation_message,
      )
      Setvalidation_name(
        res.data.contact.contacts_contact_form.contacts_validation_messages
          .name_validation_message,
      )
      Setvalidation_phone(
        res.data.contact.contacts_contact_form.contacts_validation_messages
          .phone_number_validation_message,
      )
      Setvalidation_term(
        res.data.contact.contacts_contact_form.contacts_validation_messages
          .our_terms_and_conditions_validation_message,
      )
      setButtonText(
        res.data.contact.contacts_contact_form
          ? res.data.contact.contacts_contact_form.contacts_usubmit
          : 'Submit',
      )
      Setdropdown1(
        res.data.contact.contacts_contact_form.contacts_unature_of_enquiry_info
          .contacts_nature_of_enquiry_values,
      )
      setIitialEnquiryList(
        res.data.contact.contacts_contact_form.contacts_unature_of_enquiry_info
          .contacts_nature_of_enquiry_values,
      )
      Setdropdown2(
        res.data.contact.contacts_contact_form
          .conatcts_uhow_did_you_hear_about_us_info
          .contacts_how_did_you_hear_about_us_values,
      )
      setInitialHearAboutUsList(
        res.data.contact.contacts_contact_form
          .conatcts_uhow_did_you_hear_about_us_info
          .contacts_how_did_you_hear_about_us_values,
      )

      // selected_hearRef.current = dropdown2[0];
      // selected_naturedRef.current = dropdown1[0];

      dispatch(changeLoaderState(false))
    })
  }
  // selected_hearRef.current = dropdown2[0];
  // selected_naturedRef.current = dropdown1[0];
  const handleLocationSearch = (e) => {
    const filtered = country.filter((c) =>
      c?.name?.toLowerCase().includes(e.target.value.toLowerCase()),
    )
    setCountryList([...filtered])
  }
  const handleEnquirySearch = (e) => {
    const filtered = initialEnquiryList?.filter((i) =>
      i?.toLowerCase().includes(e.target.value?.toLowerCase()),
    )
    Setdropdown1(filtered)
  }
  const handleHearAboutUsSearch = (e) => {
    const filtered = initialHearAboutUsList?.filter((i) =>
      i?.toLowerCase().includes(e.target.value?.toLowerCase()),
    )
    Setdropdown2(filtered)
  }

  const throwNotification = () => {
    toast.success(
      'Thank you for providing the details, will get back to you soon!',
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      },
    )
  }
  useEffect(() => {
    let param = window.location.href.split('/')
    if (param.length === 5) {
      if (![...langCodes, 'contactus'].includes(param[3]) && param[3] !== '') {
        setIsNotFound(true)
      } else {
        setIsNotFound(false)
      }
    }
  }, [])
  // if (isNotFound) {
  //   return <Notfound />
  // }
  return (
    <>
      <div className="contactPage">
        <div className="TopSection">
          <span className="leftshadow"></span>
          <div className="mapbg">
            <img
              src={
                data
                  ? data.contacts_background_image
                  : '../img/contact/side-ap.png'
              }
              alt="side"
            />
            <span className="rightshadow"></span>
          </div>
          <div className="Headsection container">
            <div className="headTitle">
              <div className="contact-heading">
                <h1 className="headTag headTag1">
                  {data.contacts_title
                    ? data.contacts_title.contacts_title_tell_us
                    : 'Tell1 Us'}
                </h1>
              </div>
              <div className="contact-heading">
                <h1 className="headTag headTag2 second">
                  {data.contacts_title
                    ? data.contacts_title.contacts_title_your_story
                    : 'Your Story'}
                </h1>
              </div>
            </div>
          </div>

          <div className="contact_card">
            <div className="contact_sideline">
              <div className="contactslideup_line">
                <div className="sidelinecontent">
                  <span className="filldot"></span>
                  <p className="text">
                    01{' '}
                    {data.contacts_contact_form
                      ? data.contacts_contact_form.contacts_sidemenu_contact_us
                      : 'Contact us'}
                  </p>
                  <span className="outlinedot"></span>
                </div>
              </div>
            </div>
            <div
              className={
                show === false ? 'location_sideline' : 'location_sideline1'
              }
            >
              <div className="locationslideup_line">
                <div className="sidelinecontent">
                  <span className="outlinedot"></span>
                  <span className="filldot"></span>
                  <p className="text">02 {'Our Locations'}</p>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="contact_field">
                      <label className="required-field" htmlFor="name">
                        {data.contacts_contact_form
                          ? data.contacts_contact_form.contacts_uname
                          : 'Name'}
                      </label>
                      <div className="position-relative">
                        <input
                          ref={nameRef}
                          type="text"
                          className=""
                          id="name"
                          placeholder=""
                          onChange={(e) => handlechange(e)}
                          tabIndex={1}
                        />
                        <span className="invalid">
                          {err.nameErr === '' ? '' : validation_name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="contact_field">
                      <label className="required-field" htmlFor="email">
                        {data.contacts_contact_form
                          ? data.contacts_contact_form.contacts_uemail
                          : 'Email'}
                      </label>
                      <div className="position-relative">
                        <input
                          ref={mailRef}
                          type="email"
                          className=""
                          id="email"
                          placeholder=""
                          onBlur={(e) => handlechange(e)}
                          tabIndex={2}
                        />
                        <span className="invalid">
                          {err.mailErr === '' ? '' : validation_email}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="contact_field">
                      <label className="" htmlFor="phone">
                        {data.contacts_contact_form
                          ? data.contacts_contact_form.contacts_utelephone
                          : 'Telephone'}
                      </label>
                      <div className="position-relative">
                        <input
                          ref={phoneRef}
                          type="phone"
                          className=""
                          id="phone"
                          placeholder=""
                          maxLength={20}
                          onChange={(e) => handlechange(e)}
                          tabIndex={3}
                        />
                        <span className="invalid">
                          {err.phoneErr === '' ? '' : validation_phone}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6" tabIndex={4} ref={locationDivRef}>
                    <div className="contact_field">
                      <label
                        className="required-field location-label "
                        htmlFor="location"
                        onClick={() => {
                          Setlocationdropdown(!locationdropdown)
                        }}
                      >
                        {data.contacts_contact_form
                          ? data.contacts_contact_form.contacts_locations
                          : 'Location'}
                      </label>
                      <div
                        ref={locationRef}
                        className="position-relative"
                        onClick={(e) => {
                          if (
                            e.target.tagName === 'INPUT' ||
                            e.target.classList.contains('invalid')
                          ) {
                            return
                          } else {
                            if (toggle) {
                              Setlocationdropdown(!locationdropdown)
                              setTimeout(() => {
                                locationInputRef?.current?.focus()
                              })
                            }
                          }
                        }}
                      >
                        <div className="contact_field_dropdown">
                          <div className="dropdown">
                            <p className="selected">
                              {selected_locationRef.current}
                            </p>
                            <div
                              className="dropdown_container"
                              style={{
                                display: locationdropdown ? 'block' : 'none',
                              }}
                            >
                              {country?.length > 3 && (
                                <input
                                  type="search"
                                  placeholder="Search"
                                  onChange={handleLocationSearch}
                                  id="location"
                                  maxLength={45}
                                  ref={locationInputRef}
                                  onBlur={(e) => {
                                    setTimeout(() => {
                                      e.target.value = ''

                                      setCountryList(country)
                                    }, 500)
                                  }}
                                />
                              )}
                              <div className="dropdown_list">
                                {countryList
                                  ? countryList?.map((x, i) => {
                                      return (
                                        <p
                                          key={i}
                                          onClick={(e) =>
                                            handleLocationclick(e)
                                          }
                                        >
                                          {x.name}
                                        </p>
                                      )
                                    })
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <svg
                            width="15"
                            height="10"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.50781 1.25391L5.25391 5.50781L1 1.25391"
                              stroke="#FEFBF7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <span
                          style={{ paddingTop: '0.9rem' }}
                          className="invalid"
                        >
                          {err.locationErr === '' ? '' : validation_location}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6"
                    tabIndex={5}
                    ref={natureOfEnquiryDivRef}
                  >
                    <div className="contact_field">
                      <label
                        className="required-field nature-label"
                        onClick={() => Setnaturedropdown(!naturedropdown)}
                        htmlFor="enquiry"
                      >
                        {data.contacts_contact_form
                          ? data.contacts_contact_form
                              .contacts_unature_of_enquiry_info
                              .contacts_unature_of_enquiry
                          : 'Nature1 of Enquiry'}
                      </label>
                      <div
                        ref={natureRef}
                        className="position-relative"
                        onClick={(e) => {
                          if (
                            e.target.tagName === 'INPUT' ||
                            e.target.classList.contains('invalid')
                          ) {
                            return
                          } else {
                            if (toggle) {
                              Setnaturedropdown(!naturedropdown)
                              setTimeout(() => {
                                enquiryInputRef?.current?.focus()
                              })
                            }
                          }
                        }}
                      >
                        <div className="contact_field_dropdown">
                          <div className="dropdown">
                            <p className="selected">
                              {selected_naturedRef.current}
                            </p>
                            <div
                              className="dropdown_container"
                              style={{
                                display: naturedropdown ? 'block' : 'none',
                              }}
                            >
                              {initialEnquiryList?.length > 3 && (
                                <input
                                  type="search"
                                  placeholder="Search"
                                  onChange={handleEnquirySearch}
                                  id="enquiry"
                                  maxLength={30}
                                  ref={enquiryInputRef}
                                  onBlur={(e) => {
                                    setTimeout(() => {
                                      e.target.value = ''

                                      Setdropdown1(initialEnquiryList)
                                    }, 500)
                                  }}
                                />
                              )}
                              <div className="dropdown_list">
                                {dropdown1.map((x, index) => {
                                  return (
                                    <p
                                      key={index}
                                      onClick={(e) => handleNatureclick(e)}
                                    >
                                      {x}
                                    </p>
                                  )
                                })}
                              </div>
                            </div>
                            {/* <div style={{display:naturedropdown?'block':'none'}}  className="dropdown_list">
                                                            <p onClick={(e)=>handleNatureclick(e)}>London</p>
                                                            <p  onClick={(e)=>handleNatureclick(e)}>Paris</p>
                                                            <p  onClick={(e)=>handleNatureclick(e)}>Los Angeles</p>
                                                        </div> */}
                          </div>
                          <svg
                            width="15"
                            height="10"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.50781 1.25391L5.25391 5.50781L1 1.25391"
                              stroke="#FEFBF7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <span
                          style={{ paddingTop: '0.9rem' }}
                          className="invalid"
                        >
                          {err.natureErr === '' ? '' : validation_nature}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6"
                    tabIndex={6}
                    ref={hearAboutUsDivRef}
                  >
                    <div className="contact_field">
                      <label
                        className="required-field hear-label"
                        onClick={() => {
                          Setheardropdown(!heardropdown)
                        }}
                        htmlFor="hearAboutUs"
                      >
                        {data.contacts_contact_form
                          ? data.contacts_contact_form
                              .conatcts_uhow_did_you_hear_about_us_info
                              .contacts_uhow_did_you_hear_about_us_label
                          : 'How did you hear about us?'}
                      </label>
                      <div
                        ref={hearRef}
                        className="position-relative"
                        onClick={(e) => {
                          if (
                            e.target.tagName === 'INPUT' ||
                            e.target.classList.contains('invalid')
                          ) {
                            return
                          } else {
                            if (toggle) {
                              Setheardropdown(!heardropdown)
                              setTimeout(() => {
                                hearAboutInputRef?.current?.focus()
                              })
                            }
                          }
                        }}
                      >
                        <div
                          id="hear"
                          onBlur={(e) => handlechange(e)}
                          className="contact_field_dropdown"
                        >
                          <div className="dropdown">
                            <p className="selected">
                              {selected_hearRef.current}
                            </p>

                            <div
                              className="dropdown_container"
                              style={{
                                display: heardropdown ? 'block' : 'none',
                              }}
                            >
                              {initialHearAboutUsList?.length > 3 && (
                                <input
                                  type="search"
                                  placeholder="Search"
                                  onChange={handleHearAboutUsSearch}
                                  id="hearAboutUs"
                                  maxLength={30}
                                  ref={hearAboutInputRef}
                                  onBlur={(e) => {
                                    setTimeout(() => {
                                      e.target.value = ''

                                      Setdropdown2(initialHearAboutUsList)
                                    }, 500)
                                  }}
                                />
                              )}
                              <div
                                style={{
                                  display: heardropdown ? 'block' : 'none',
                                }}
                                id="hear-dropdown"
                                className="dropdown_list"
                              >
                                {dropdown2.map((x, index) => {
                                  return (
                                    <p
                                      key={index}
                                      onClick={(e) => handleHearclick(e)}
                                    >
                                      {x}
                                    </p>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                          <svg
                            width="15"
                            height="10"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.50781 1.25391L5.25391 5.50781L1 1.25391"
                              stroke="#FEFBF7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <span
                          style={{ paddingTop: '0.9rem' }}
                          className="invalid"
                        >
                          {err.hearErr === '' ? '' : validation_hear}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="contact_field mb-2">
                      <label className="required-field" htmlFor="message">
                        {data.contacts_contact_form
                          ? data.contacts_contact_form.contacts_uadd_a_message
                          : 'Add a message'}
                      </label>
                      <div className="position-relative">
                        <textarea
                          ref={messageRef}
                          className=""
                          id="message"
                          onBlur={(e) => handlechange(e)}
                          rows={4}
                          maxLength={500}
                          tabIndex={7}
                        ></textarea>
                        <span className="invalid">
                          {err.messageErr === '' ? '' : validation_message}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                {/* <div className="row">
                  <div className="col-lg-12 captchaalignment">
                    <div ref={captchaRef}></div>
                    <div className="contact_field">
                      <label className="required-field" htmlFor="name">
                        {'Captcha Text'}
                      </label>
                      <div className="position-relative">
                        <input
                          ref={inputcaptchaRef}
                          type="text"
                          style={{
                            textTransform: 'uppercase',
                            marginTop: '0.7rem',
                            paddingBottom: '0.2rem',
                            letterSpacing: '2px',
                            width: '100%',
                          }}
                          className=""
                          id="captcha"
                          maxlength="5"
                          placeholder=""
                          onBlur={(e) => handlechange(e)}
                      
                          tabIndex={8}
                        />
                        <span style={{ width: '110%' }} className="invalid">
                          {err.captchaErr === '' ? '' : err.captchaErr}
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div
                  className="form-group mt-2"
                  style={{
                    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
                    userSelect: 'none',
                  }}
                >
                  <input
                    type="checkbox"
                    id="conditions"
                    onClick={(e) => handlechange(e)}
                  />
                  <label htmlFor="conditions">
                    {data.contacts_contact_form
                      ? data.contacts_contact_form.contacts_i_have_read_the
                      : 'I have read the'}
                    <a
                      target="_blank"
                      href={
                        langcode === 'en'
                          ? 'https://www.ptw.com/pdf/ptw_privacy_policy.pdf'
                          : `https://www.ptw.com/${langcode}/pdf/ptw_privacy_policy.pdf`
                      }
                    >
                      {data.contacts_contact_form
                        ? data.contacts_contact_form.contacts_privacy_notice
                        : 'Privacy Notice'}{' '}
                    </a>
                    {/* {data.contacts_contact_form
                      ? data.contacts_contact_form.contacts_and_agree_to_the
                      : 'and agree to the'}
                    <a
                      target="_blank"
                      href={
                        langcode === 'en'
                          ? 'https://www.ptw.com/pdf/ptw_new_website_terms_of_usage_2018.pdf'
                          : `https://www.ptw.com/${langcode}/pdf/ptw_new_website_terms_of_usage_2018.pdf`
                      }
                      // href={langcode === 'en' ? `/terms` : `/${langcode}/terms`}
                    >
                      {data.contacts_contact_form
                        ? data.contacts_contact_form.contacts_terms_conditions
                        : 'Terms & Conditions'}
                    </a> */}
                    <span className="required-field" />
                  </label>
                  <span className="invalid">
                    {err.checkErr === '' ? '' : validation_term}
                  </span>
                </div>
                <div className="custombtn pt-2 mt-4">
                  <Button
                    ref={submitRef}
                    text={[buttonText, submitstyle]}
                    className={''}
                    // disabled={submitstyle === true ? false : true}
                    onClick={(e) => submitContactdata(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="locations" className="LocationSection">
          <h1 className="largeHead">
            {data
              ? data?.contacts_contact_form?.contact_locations_heading
              : 'Locations'}
          </h1>
        </div>

        <div className="container location_container">
          <div className="accordion accordion-flush" id="locationParent">
            {locdata
              ? locdata.map((data, index) => {
                  return (
                    // <></>

                    <div
                      id={index}
                      key={index}
                      className={
                        index === 0
                          ? `accordion-item accordion-item-${
                              index + 1
                            } rotate-cross`
                          : `accordion-item accordion-item-${index + 1}`
                      }
                    >
                      <div className="accordion-header">
                        <div
                          className="accordion-button collapsed d-flex justify-content-between"
                          type="button"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <h3>{data.location_name}</h3>
                            </div>
                            <div className="col-lg-3 d-lg-block d-none mt-2">
                              <a
                                href={`mailto:${
                                  data[Object.keys(data)[1]]
                                    ? data[Object.keys(data)[1]].location_email
                                    : 'londo11n@sideglobal.com'
                                }`}
                                className=""
                              >
                                {data[Object.keys(data)[1]]
                                  ? data[Object.keys(data)[1]].location_email
                                  : 'londo11n@sideglobal.com'}
                              </a>
                            </div>
                            <div className="col-lg-3 d-lg-block d-none mt-2">
                              <a
                                href={`tel:${
                                  data[Object.keys(data)[1]]
                                    ? data[Object.keys(data)[1]].contact_number
                                    : '+44(0)20 7631 480011'
                                }`}
                                className=""
                              >
                                {data[Object.keys(data)[1]]
                                  ? data[Object.keys(data)[1]].contact_number
                                  : '+44(0)20 7631 480011'}
                              </a>
                            </div>
                          </div>
                          <svg
                            className="collapse_icon"
                            onClick={(e) => handleaccordion(index + 1)}
                            width="29"
                            height="29"
                            viewBox="0 0 29 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            data-bs-toggle="collapse"
                            data-bs-target="#location_london"
                            aria-expanded="false"
                            aria-controls="location_london"
                          >
                            <circle
                              cx="14.5098"
                              cy="14.7188"
                              r="14"
                              fill="#94DB16"
                            />
                            <line
                              x1="8.41992"
                              y1="14.2188"
                              x2="19.9473"
                              y2="14.2188"
                              stroke="black"
                            />
                            <line
                              x1="14.2129"
                              y1="8.36035"
                              x2="14.2129"
                              y2="19.8877"
                              stroke="black"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        id="location_london"
                        className={
                          index === 0
                            ? `accordion-collapse collapse location-${
                                index + 1
                              } show`
                            : `accordion-collapse collapse location-${
                                index + 1
                              } `
                        }
                        data-bs-parent="#locationParent"
                      >
                        <div className="accordion-body">
                          <div className="mbl_links d-lg-none d-inline-flex flex-column">
                            <a
                              href={`mailto:${
                                data[Object.keys(data)[1]]
                                  ? data[Object.keys(data)[1]].location_email
                                  : 'londo11n@sideglobal.com'
                              }`}
                              className="mb-3"
                            >
                              {data[Object.keys(data)[1]]
                                ? data[Object.keys(data)[1]].location_email
                                : 'londo11n@sideglobal.com'}
                            </a>
                            <a
                              href={`tel:${
                                data[Object.keys(data)[1]]
                                  ? data[Object.keys(data)[1]].contact_number
                                  : '+44(0)20 7631 480011'
                              }`}
                              className="mb-3"
                            >
                              {data[Object.keys(data)[1]]
                                ? data[Object.keys(data)[1]].contact_number
                                : '+44(0)20 7631 480011'}
                            </a>
                          </div>
                          <div className="row mx-0">
                            <div className="offset-lg-4 col-lg-8 p-0">
                              <div className="locationSlider d-flex">
                                <div className="locationSlider_swiper">
                                  <Swiper
                                    slidesPerView={'1'}
                                    modules={[Navigation]}
                                    loop={true}
                                    navigation={{
                                      prevEl: `.prevarrow${index}`,
                                      nextEl: `.nextarrow${index}`,
                                    }}
                                    onSwiper={(s) => {}}
                                    className={`mySwiper myswiper${index}`}
                                  >
                                    {data[Object.keys(data)[1]].location_images
                                      ? data[
                                          Object.keys(data)[1]
                                        ].location_images.map((x, i) => {
                                          return (
                                            <Fragment key={i}>
                                              <SwiperSlide key={i}>
                                                <div className="swiperImg">
                                                  <img
                                                    src={
                                                      x
                                                        ? x.location_image
                                                        : '../img/ws-banner.png'
                                                    }
                                                    alt="side"
                                                  />
                                                </div>
                                              </SwiperSlide>
                                            </Fragment>
                                          )
                                        })
                                      : ''}
                                  </Swiper>

                                  <div className="swiper_arrows swiper_arrows1 arrows d-lg-none d-flex">
                                    <SliderButtons
                                      //   onClick={(e) => prevSlide(index + 1)}
                                      className={`prevarrow prevarrow${index}`}
                                      width={16}
                                      height={11}
                                      path={
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M15.8945 5.9999L15.8945 5.4999L15.3945 5.4999L2.60523 5.4999L6.17191 1.93322L6.52547 1.57966L5.81836 0.872558L5.46481 1.22611L1.04448 5.64644L0.690922 5.99999L1.04448 6.35355L5.46481 10.7739L5.81836 11.1274L6.52547 10.4203L6.17191 10.0668L2.60504 6.4999L14.8945 6.4999L14.8945 9.16263L14.8945 9.66263L15.8945 9.66263L15.8945 9.16263L15.8945 5.9999Z"
                                          fill="#FEFBF7"
                                        />
                                      }
                                    />
                                    <SliderButtons
                                      //   onClick={(e) => nextSlide(index + 1)}
                                      className={`nextarrow nextarrow${index}`}
                                      width={16}
                                      height={11}
                                      path={
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                                          fill="white"
                                        />
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="right_location">
                                  <div className="swiper_arrows arrows d-lg-flex d-none">
                                    <SliderButtons
                                      //   onClick={(e) => prevSlide(index + 1)}
                                      className={`prevarrow prevarrow${index}`}
                                      //   className="prevarrow"
                                      width={16}
                                      height={11}
                                      path={
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M15.8945 5.9999L15.8945 5.4999L15.3945 5.4999L2.60523 5.4999L6.17191 1.93322L6.52547 1.57966L5.81836 0.872558L5.46481 1.22611L1.04448 5.64644L0.690922 5.99999L1.04448 6.35355L5.46481 10.7739L5.81836 11.1274L6.52547 10.4203L6.17191 10.0668L2.60504 6.4999L14.8945 6.4999L14.8945 9.16263L14.8945 9.66263L15.8945 9.66263L15.8945 9.16263L15.8945 5.9999Z"
                                          fill="#FEFBF7"
                                        />
                                      }
                                    />
                                    <SliderButtons
                                      //   onClick={(e) => nextSlide(index + 1)}
                                      className={`nextarrow nextarrow${index}`}
                                      width={16}
                                      height={11}
                                      path={
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                                          fill="white"
                                        />
                                      }
                                    />
                                  </div>
                                  <div className="location_details mt-3">
                                    {data[Object.keys(data)[1]]
                                      ? parse(
                                          data[Object.keys(data)[1]]
                                            .location_address,
                                        )
                                      : '+44(0)20 7631 480011'}
                                    {/* <p>Unit A12</p>
                                                    <p>Tileyard London</p>
                                                    <p>105 Blundell Street - King's Cross</p>
                                                    <p>London N7 9BN</p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              : ''}
          </div>
        </div>

        <div className="cta_section d-flex align-items-center">
          <div className="col-lg-2 d-lg-flex d-none">
            <div className="blur_stripes left">
              <div className="stripe stripe1"></div>
              <div className="stripe stripe2"></div>
              <div className="stripe stripe3"></div>
            </div>
          </div>
          <div className="col-lg-8 col-12 position-relative">
            <div className="hover_overlay_anim">
              <img
                src={
                  data.contacts_bottom_section
                    ? data.contacts_bottom_section.conatcts_bottom_ubanner_image
                    : '../img/ws-banner-bkp.jpg'
                }
                alt="side"
              />
              <div id="contact_stripes" className="hover_overlay">
                <div className="block mbl-none trail-block1">
                  <span className=""></span>
                  <span className=""></span>
                </div>
                <div className="block mbl-none trail-block2">
                  <span className=""></span>
                  <span className=""></span>
                </div>
                <div className="block mbl-none trail-block3">
                  <span className=""></span>
                  <span className=""></span>
                </div>
                <div className="block trail-block4">
                  <span className=""></span>
                  <span className=""></span>
                </div>
                <div className="block trail-block5">
                  <span className=""></span>
                  <span className=""></span>
                </div>
                <div className="block trail-block6">
                  <span className="contact-hover-1"></span>
                  <span className="contact-hover-2"></span>
                </div>
                <div className="block trail-block7">
                  <span className=""></span>
                  <span className=""></span>
                </div>
                <div className="block trail-block8">
                  <span className=""></span>
                  <span className=""></span>
                </div>
                <div className="block mbl-none trail-block9">
                  <span className=""></span>
                  <span className=""></span>
                </div>
                <div className="block mbl-none trail-block10">
                  <span className=""></span>
                  <span className=""></span>
                </div>
                <div className="block mbl-none trail-block11">
                  <span className=""></span>
                  <span className=""></span>
                </div>
              </div>
            </div>
            <div className="cta_content">
              <p className="sub_tagline">
                {data.contacts_bottom_section
                  ? data.contacts_bottom_section
                      .contacts_bottom_ubanner_caption_title
                  : 'lore1m ipsum sit amet'}
              </p>
              <h1>
                {data.contacts_bottom_section
                  ? data.contacts_bottom_section
                      .contacts_bottom_ubanner_caption_description.sub_caption1
                  : 'Lorem Ipsum'}
              </h1>
              <h1>
                {data.contacts_bottom_section
                  ? data.contacts_bottom_section
                      .contacts_bottom_ubanner_caption_description.sub_caption2
                  : 'About ptw'}
                <span>
                  {data.contacts_bottom_section
                    ? data.contacts_bottom_section
                        .contacts_bottom_ubanner_caption_description
                        .sub_caption3
                    : 'Here'}
                </span>
              </h1>
            </div>
            <a
              href={
                langcode === 'en'
                  ? `https://www.ptw.com/careers`
                  : `https://www.ptw.com/${langcode}/careers`
              }
              target="_blank"
              className="cta_circle"
            >
              <p>
                {data.contacts_bottom_section
                  ? data.contacts_bottom_section.contacts_uhover_button
                      .button_label1
                  : 'lore1m'}
                <span>
                  {data.contacts_bottom_section
                    ? data.contacts_bottom_section.contacts_uhover_button
                        .button_label2
                    : 'ipsum1'}
                </span>
              </p>
            </a>
          </div>
          <div className="col-lg-2 d-lg-flex d-none">
            <div className="blur_stripes right">
              <div className="stripe stripe1"></div>
              <div className="stripe stripe2"></div>
              <div className="stripe stripe3"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  )
}
export default Contactus
