import '../../styles/news/news.css'
import TestomonialAndContact from '../homepage/section8'
import Footer from '../../components/footer/footer'
import { useEffect, useState } from 'react'
import { newsListData } from './newsjson'
import NewsSmallCard from './Components/newssmallcard'
import NewsBigCard from './Components/newsbigcard'
import anime from 'animejs'
import axiosInstance from '../../services/axiosInstance'
import Pagination from '../../constants/pagination/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { changeLoaderState } from '../../actions/lngactions'
import { devapiUrl } from '../../constants/devapi'
import { getLanguage } from '../../constants/urls'
import { langCodes } from '../../constants/country'
import Notfound from '../NotFound/Notfound'

var language = getLanguage()

const Oldloader = () => {
  return (
    <div
      className="loader-container main-body"
      style={{
        '--loader-dot-color': 'white',
        '--loader-dot-size': '50px',
        '--loader-dot-spacing': '25px',
      }}
    >
      <div className="loader-dots" style={{ marginTop: '20px' }}>
        <div className="loader-dot moving-dot"></div>
        <div className="loader-dot fixed-dot"></div>
        <div className="loader-dot fixed-dot"></div>
        <div className="loader-dot fixed-dot"></div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        style={{ display: 'none' }}
      >
        <defs>
          <filter id="gooey">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
            />
          </filter>
        </defs>
      </svg>
    </div>
  )
}

const News = () => {
  const lang = useSelector((state) => state.lang)
  const dispatch = useDispatch()
  const [newsList, setNewsListData] = useState(newsListData[0])
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [isNotFound, setIsNotFound] = useState(false)

  useEffect(() => {
    dispatch(changeLoaderState(true))
    language = getLanguage()
    document.title = 'SIDE - News'
    window.scrollTo({ top: 0, left: 0 })
    getNewsData(1, language, 'FIRST')
  }, [lang])
  const animation = () => {
    let head1 = document.querySelector('.news-section-1')
    let head2 = document.querySelector('.news-section-2')
    if (head1 !== null) head1.style.opacity = 0
    if (head2 !== null) head2.style.opacity = 0
    if (true) {
      if (head1 !== null) head1.style.opacity = 1
      if (head2 !== null) head2.style.opacity = 1
      anime
        .timeline({ loop: false })
        // .add({
        //   targets: '.news-section2-item3',
        //   opacity: 0,
        //   duration: 1000,
        // })
        .add({
          targets: '.news-section2-item3',
          opacity: [0, 1],
          easing: 'easeOutExpo',
          duration: 5400,
        })
      anime.timeline({ loop: false }).add({
        targets: '.news-header h1',
        translateY: [200, 0],
        easing: 'easeOutExpo',
        duration: 5400,
      })
    }
  }

  const getNewsData = (pageNo = 1, lang = 'en', f) => {
    let obj = {
      lang: language,
      pageno: pageNo,
      recordsperpage: 14,
    }
    axiosInstance
      .post(`${devapiUrl}news`, obj)
      .then((res) => {
        setNewsListData(res.data)
        setCurrentPage(pageNo)
        setTimeout(() => {
          dispatch(changeLoaderState(false))
          animation()
        }, 1500)

        if (f !== 'FIRST') {
          updatedToProjectsPosition()
          setLoader(false)
        } else {
          window.scrollTo({ top: 0, left: 0 })
        }
      })
      .catch((err) => {
        console.error('Error from news API', err)
        setTimeout(() => {
          dispatch(changeLoaderState(false))
        }, 1500)
      })
  }

  const updatedToProjectsPosition = () => {
    let ele = document.getElementById('newslist')
    if (ele !== null) {
      setTimeout(() => {
        ele.scrollIntoView()
        window.scrollBy(0, -100)
      }, 0)
    }
  }

  const onPaginationChange = (pageNumber) => {
    updatedToProjectsPosition()
    setLoader(true)
    getNewsData(pageNumber)
  }
  useEffect(() => {
    let param = window.location.href.split('/')
    if (param.length === 5) {
      if (!langCodes.includes(param[3]) && param[3] !== '') {
        setIsNotFound(true)
      } else {
        setIsNotFound(false)
      }
    }
  }, [])
  // if (isNotFound) {
  //   return <Notfound />
  // }
  return (
    <>
      <div className="newspage">
        <div className="news-container">
          <div className="container topSection">
            <span className="leftshadow"></span>
            <div className="news-section-1">
              <div className="news-header">
                <div style={{ overflow: 'hidden' }}>
                  <h1 className="ftext">
                    {newsList.news_header1 ? newsList.news_header1 : ''}
                  </h1>
                </div>
                <div style={{ overflow: 'hidden' }}>
                  <h1 className="stext">
                    {newsList.news_header2 ? newsList.news_header2 : ''}
                  </h1>
                </div>
              </div>
            </div>
            <div className="news-section-2">
              <div className="news-header-content col-lg-5 col-12">
                <div className="news-section2-item3">
                  <span />
                  <p className="subHeading">
                    {newsList.new_sub_heading ? newsList.new_sub_heading : ''}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="newslist">
            <div className="newslist_section">
              <div className="newslist_border top_border border_lines"></div>
              {loader ? (
                <Oldloader />
              ) : newsList.news?.length > 0 ? (
                <div className="container position-relative">
                  <div className="newslist_border left_border border_lines"></div>
                  <div className="newslist_border right_border border_lines"></div>
                  <div className="position-relative">
                    {newsList.news.length <= 2 ? (
                      <>
                        <div className="news-section-6 row">
                          <div class="newsection_border left_border_3 border_lines"></div>
                          {newsList.news.map((_, i) => {
                            return (
                              <div className="news-sec-subsec-2">
                                <NewsBigCard
                                  language={language}
                                  key={i}
                                  data={_}
                                  staticdata={newsList}
                                />
                              </div>
                            )
                          })}
                        </div>
                        <div class="newslist_border bottom_border border_lines"></div>{' '}
                      </>
                    ) : (
                      ''
                    )}
                    {newsList.news.length === 3 ? (
                      <>
                        {' '}
                        <div className="newslist_border left_border_2_three_cards border_lines"></div>
                        <div className="newslist_border left_border_3_three_cards border_lines"></div>
                      </>
                    ) : (
                      <>
                        {' '}
                        <div className="newslist_border left_border_2 border_lines"></div>
                        <div className="newslist_border left_border_3 border_lines"></div>
                        <div className="newslist_border left_border_4 border_lines"></div>
                      </>
                    )}
                    <div className="news-section-5 row ">
                      {newsList.news.length === 3
                        ? newsList.news.slice(0, 4).map((_, i) => {
                            return (
                              <NewsSmallCard
                                language={language}
                                key={i}
                                className={`item${i + 1} col-12 col-lg-6`}
                                data={_}
                                staticdata={newsList}
                              />
                            )
                          })
                        : ''}
                      {newsList.news.length >= 4
                        ? newsList.news.slice(0, 4).map((_, i) => {
                            return (
                              <NewsSmallCard
                                language={language}
                                key={i}
                                className={`item${i + 1} col-12 col-lg-6`}
                                data={_}
                                staticdata={newsList}
                              />
                            )
                          })
                        : ''}
                    </div>

                    <div className="news-section-6 row">
                      <div className="news-sec-subsec-1">
                        <div className="news-sec-subsec-1-box-1 row">
                          <div className="news-sec-sub_border bottom_border border_lines"></div>
                          {newsList.news.length >= 6
                            ? newsList.news.slice(4, 6).map((_, i) => {
                                return (
                                  <NewsSmallCard
                                    language={language}
                                    key={i}
                                    className={`item${i + 1} col-12 col-lg-6`}
                                    data={_}
                                    staticdata={newsList}
                                  />
                                )
                              })
                            : ''}
                        </div>
                        <div className="news-sec-subsec-1-box-2 row">
                          {newsList.news.length >= 8
                            ? newsList.news.slice(6, 8).map((_, i) => {
                                return (
                                  <NewsSmallCard
                                    language={language}
                                    key={i}
                                    className={`item${i + 1} col-12 col-lg-6`}
                                    data={_}
                                    staticdata={newsList}
                                  />
                                )
                              })
                            : ''}
                        </div>
                      </div>
                      <div className="news-sec-subsec-2">
                        {newsList.news.length >= 9
                          ? newsList.news.slice(8, 9).map((_, i) => {
                              return (
                                <NewsBigCard
                                  language={language}
                                  key={i}
                                  data={_}
                                  staticdata={newsList}
                                />
                              )
                            })
                          : ''}
                      </div>
                    </div>
                  </div>

                  <div className="news-section-6 row">
                    <div className="newsection_border top_border border_lines"></div>
                    <div className="newsection_border left_border_3 border_lines"></div>
                    <div className="news-sec-subsec-2">
                      {newsList.news.length >= 10
                        ? newsList.news.slice(9, 10).map((_, i) => {
                            return (
                              <NewsBigCard
                                language={language}
                                key={i}
                                data={_}
                                staticdata={newsList}
                              />
                            )
                          })
                        : ''}
                    </div>
                    <div className="news-sec-subsec-1">
                      <div className="news-sec-subsec-1-box-1 row">
                        {newsList.news.length >= 12
                          ? newsList.news.slice(10, 12).map((_, i) => {
                              return (
                                <NewsSmallCard
                                  language={language}
                                  key={i}
                                  className={`item${i + 1} col-12 col-lg-6`}
                                  data={_}
                                  staticdata={newsList}
                                />
                              )
                            })
                          : ''}
                      </div>
                      <div className="news-sec-subsec-1-box-2 row">
                        {newsList.news.length >= 14
                          ? newsList.news.slice(12, 14).map((_, i) => {
                              return (
                                <NewsSmallCard
                                  language={language}
                                  key={i}
                                  className={`item${i + 1} col-12 col-lg-6`}
                                  data={_}
                                  staticdata={newsList}
                                />
                              )
                            })
                          : ''}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="newslist_border bottom_border border_lines"></div>
            </div>
          </div>
        </div>
        {newsList.news?.length > 0 ? (
          <div className="d-flex align-items-center justify-content-center pagination">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(newsList.totalrecords / 14)}
              onPaginationValueChange={onPaginationChange}
            />
          </div>
        ) : (
          ''
        )}
        <TestomonialAndContact />
        <Footer />
      </div>
    </>
  )
}

export default News
