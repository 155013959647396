import { Swiper, SwiperSlide } from 'swiper/react'
import { useState, useEffect, useRef } from 'react'
import Contact from '../contact/contact'
import anime from 'animejs'
import SliderButtons from '../Buttons/SliderButtons'
import axiosInstance from '../../services/axiosInstance'
import { useDispatch, useSelector } from 'react-redux'
import { devapiUrl } from '../../constants/devapi'
import { getLanguage } from '../../constants/urls'
import { footerdata } from '../../actions/lngactions'

const TestomonialAndContact = () => {
  const sec8Ref = useRef(null)
  const dispatch = useDispatch()
  const count = useRef(0)
  const [swipe, setSwipe] = useState(null)
  const [tellUsData, setTellUsData] = useState([])
  const lang = useSelector((state) => state.lang)
  useEffect(() => {
    getTellUsYourStory(getLanguage())
  }, [lang])

  const getTellUsYourStory = (lang) => {
    axiosInstance
      .get(`${devapiUrl}${lang}/tellusyourstory`)
      .then((res) => {
        setTellUsData(res.data)
        dispatch(footerdata(res.data))
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const nextSlide = () => {
    swipe.slideNext()
  }

  const prevSlide = () => {
    swipe.slidePrev()
  }

  useEffect(() => {
    var textanime = anime.timeline({ loop: false, autoplay: false })
    textanime
      .add({
        targets: '.sec_line_8',
        translateY: [0],
        easing: 'easeOutExpo',
        duration: 0,
      })
      .add({
        targets: '.sec_line_8',
        translateY: [200, 0],
        easing: 'easeOutExpo',
        duration: 1500,
        delay: 0,
      })
    window.addEventListener('scroll', (e) => {
      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio === 0) {
              count.current = 0
            }
            if (entry.intersectionRatio > 0.42) {
              if (count.current === 0) textanime.restart()
              count.current = 1
            }
          })
        },
        { threshold: 1 },
      )

      if (sec8Ref.current != null) observer.observe(sec8Ref.current)
      e.preventDefault()
    })
  }, [])
  return (
    <div className="section8">
      <div className="container">
        <div className="section8Width row ">
          <div ref={sec8Ref} className="leftcontent col-md-6">
            <div className="verticalLine left"></div>
            <div className="verticalLine center"></div>
            <div className="sec_head_8">
              <label>{tellUsData ? tellUsData.tellus_story_and : 'AND'}</label>
              <h5 className="sec_line_8">
                {tellUsData
                  ? tellUsData?.tellusyourstory?.tellus_story_testimonial_title
                      ?.tellus_story_what_they
                  : ''}
              </h5>
            </div>
            <div className="sec_head_8">
              <h5 className="sec_line_8">
                {' '}
                {tellUsData
                  ? tellUsData?.tellusyourstory?.tellus_story_testimonial_title
                      ?.tellus_story_say_about_us
                  : ''}
              </h5>
            </div>
            <div className="testimonial_slider">
              <Swiper
                slidesPerView={'1'}
                loop={true}
                className="mySwiper"
                onSwiper={(s) => {
                  setSwipe(s)
                }}
              >
                {tellUsData?.tellusyourstory ? (
                  <>
                    {tellUsData?.tellusyourstory?.tellus_story_testimonial_info.map(
                      (_, i) => {
                        return (
                          <SwiperSlide key={i}>
                            <p className="content">
                              <i>{_.tellus_testimonial_description}</i>
                            </p>
                            <p className="tagline">
                              <span />
                              {_.tellus_testimonial_author}
                            </p>
                          </SwiperSlide>
                        )
                      },
                    )}
                  </>
                ) : (
                  <SwiperSlide></SwiperSlide>
                )}
                <div className="arrows swiper_arrows">
                  <SliderButtons
                    className={'prevarrow'}
                    onClick={(e) => prevSlide(e)}
                    width={16}
                    height={11}
                    path={
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.8945 5.9999L15.8945 5.4999L15.3945 5.4999L2.60523 5.4999L6.17191 1.93322L6.52547 1.57966L5.81836 0.872558L5.46481 1.22611L1.04448 5.64644L0.690922 5.99999L1.04448 6.35355L5.46481 10.7739L5.81836 11.1274L6.52547 10.4203L6.17191 10.0668L2.60504 6.4999L14.8945 6.4999L14.8945 9.16263L14.8945 9.66263L15.8945 9.66263L15.8945 9.16263L15.8945 5.9999Z"
                        fill="#FEFBF7"
                        stroke="#fefbf7"
                        strokeWidth="0.5"
                      />
                    }
                  />
                  <SliderButtons
                    className={'nextarrow'}
                    onClick={(e) => nextSlide(e)}
                    width={16}
                    height={11}
                    path={
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                        fill="#fefbf7"
                        stroke="#fefbf7"
                        strokeWidth="0.5"
                      />
                    }
                  />
                </div>
              </Swiper>
            </div>
          </div>

          <div className="bottom_contact rightcontent col-md-6">
            <Contact />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestomonialAndContact
