const initialState = {
  lang: 'en',
  loadingState: false,
}

export const langChanger = (state = initialState, action) => {
  switch (action.type) {
    case 'changeLanguage':
      return {
        ...state,
        lang: action.lang,
      }

    case 'changeLoaderState':
      return {
        ...state,
        loadingState: action.loadingState,
      }
    case 'language_data':
      return {
        ...state,
        data: action.data,
      }
    case 'project_data':
      return {
        ...state,
        projectdata: action.projectdata,
      }
    case 'contact_data':
      return {
        ...state,
        contactdata: action.contactdata,
      }
    case 'terms_data':
      return {
        ...state,
        termsdata: action.termsdata,
      }
    case 'service_data':
      return {
        ...state,
        servicedata: action.servicedata,
      }
    case 'home_data':
      return {
        ...state,
        homedata: action.homedata,
      }
    case 'footer_data':
      return {
        ...state,
        footerdata: action.footerdata,
      }
    default:
      return state
  }
}
