import '../../styles/oldloader/oldloader.css'
const Oldloader = () => {
  return (
    // <div className="main-body">
    //   <div
    //     className="loader"
    //     style={{ zIndex: 9999, backgroundColor: 'black' }}
    //   >
    //     <span className="item-1"></span>
    //     <span className="item-2"></span>
    //     <span className="item-3"></span>
    //     <span className="item-4"></span>
    //     <span className="item-5"></span>
    //     <span className="item-6"></span>
    //     <span className="item-7"></span>
    //   </div>
    // </div>
    <div
      className="loader-container main-body"
      style={{
        '--loader-dot-color': 'white',
        '--loader-dot-size': '35px',
        '--loader-dot-spacing': '25px',
      }}
    >
      <div className="loader-dots">
        <div className="loader-dot moving-dot"></div>
        <div className="loader-dot fixed-dot"></div>
        <div className="loader-dot fixed-dot"></div>
        <div className="loader-dot fixed-dot"></div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        style={{ display: 'none' }}
      >
        <defs>
          <filter id="gooey">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
            />
          </filter>
        </defs>
      </svg>
    </div>
  )
}
export default Oldloader
