import React, { useEffect, useState, useRef } from 'react'
import { StackedCarousel, ResponsiveContainer } from './slidecode/index'
import { useSelector } from 'react-redux'
import axiosInstance from '../services/axiosInstance'
import { Slide } from './slide'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import anime from 'animejs'
import { devapiUrl } from './devapi'
import { getLanguage } from './urls'

const dataDes = [
  {
    banner_image: '',
    title: '',
  },
  {
    banner_image: '/img/projectimg/cyberpunk2077.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/sifu.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/dl2.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/godofwar_ragnarok.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/plague.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/sifu.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/deadisland2.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/godofwar_ragnarok.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/plague.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/plague.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/sifu.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/deadisland2.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/godofwar_ragnarok.jpg',
    title: '',
  },
  {
    banner_image: '/img/projectimg/plague.jpg',
    title: '',
  },
]

const CardExample = () => {
  const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0)
  const [slideNumber, setSlideNumber] = useState(
    window.innerWidth <= 991 ? 9 : 15,
  )
  const [data, setData] = useState(dataDes)
  const [totalSlides, setTotalSlides] = useState()
  const [numarr, setnumarr] = useState([])
  const lang = useSelector((state) => state.lang)
  const sidelineplay = useRef(null)
  const home = useSelector((state) => state.homedata)
  const onCenterSlideDataIndexChange = (newIndex) => {
    anime({
      targets: '.discription .slidetext',
      translateY: [145, 0],
      duration: 1000,
      opacity: [0, 1],
      delay: 300,
    })
    let x, y
    x = anime.timeline({ loop: false })
    x.add({
      targets: `.project-letter${centerSlideDataIndex + 1}`,
      opacity: 1,
      duration: 0,
    })
    x.add({
      targets: `.project-letter${centerSlideDataIndex + 1}`,
      translateY: [0, -45],
      easing: 'easeOutExpo',
      delay: (el, i) => 50 * i,
    })
    y = anime.timeline({ loop: false })
    y.add({
      targets: `.project-letter${newIndex + 1}`,
      opacity: 1,
      duration: 0,
    }).add({
      targets: `.project-letter${newIndex + 1}`,
      translateY: [45, 0],
      easing: 'easeOutExpo',

      delay: (el, i) => 50 * i,
    })

    x.restart()
    y.restart()

    if (newIndex > centerSlideDataIndex) {
      //right swipe

      anime({
        targets: '.crousal-pin',
        translateX: [15, -11],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.pin-y',
        translateX: [15, -11],
        opacity: [1],
        scale: [1, 1.2],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.pin-y0',
        translateX: [15, -13],

        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.pin-y1',
        translateX: [15, -11],
        scale: [1, 0.6],
        opacity: [1],
        //  opacity:[1,0.4],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.pin-y2',
        translateX: [15, -11],
        scale: [1, 0.8],
        opacity: [1, 0.3],
        //  opacity:[1,0.4],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.trial-pin-1',
        translateX: [10, -15],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.trial-pin-3',
        translateX: [10, -15],
        opacity: [1, 0.1],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.trial-pin-2',
        translateX: [0],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.pin-x2',
        translateX: [15, -11],
        scale: [1, 0.6],
        opacity: [1],
        //  opacity:[1,0.4],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.pin-x0',
        translateX: [15, -11],
        opacity: [1, 0.1],
        scale: [1, 1.7],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.pin-x1',
        translateX: [15, -11],
        opacity: [1, 0.3],

        duration: 1500,
        easing: 'easeOutSine',
      })
      // anime({
      //   targets:'.pin-y',
      //   scale:[1,1.5],
      //   translateX:[-1]
      //   duration:1500,
      //   easing:'easeOutSine',
      // })
    } else if (newIndex < centerSlideDataIndex) {
      //left swipe
      anime({
        targets: '.trial-pin-1',
        translateX: [15, 35],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.trial-pin-2',
        translateX: [0, 15],
        opacity: [1, 0.3],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.trial-pin-3',
        translateX: [0],
        duration: 1500,
        easing: 'easeOutSine',
      })

      anime({
        targets: '.crousal-pin',
        translateX: [-15, 8],
        opacity: [1, 0.3],
        // scale:[1,1.5],
        duration: 1500,
        easing: 'easeOutSine',
      })

      anime({
        targets: '.pin-x0',
        translateX: [-15, 8],
        scale: [1, 0.5],
        opacity: 1,
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.pin-x2',
        translateX: [-15, 11],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.pin-x1',
        translateX: [-15, 8],
        scale: [1, 1.2],
        opacity: [1],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.pin-y1',
        translateX: [-15, 8],
        scale: [1, 1.4],
        opacity: [1, 0.1],
        duration: 1500,
        easing: 'easeOutSine',
      })
      anime({
        targets: '.pin-y0',
        translateX: [-15, 8],
        scale: [1, 0.6],
        opacity: [1],
        duration: 1500,
        easing: 'easeOutSine',
      })
    }
    setCenterSlideDataIndex(newIndex)
  }
  const ref = React.useRef(StackedCarousel)
  var sideline = () => {
    anime({
      loop: false,
      targets: '.sec-1-slideup',
      opacity: [0, 1],
      translateY: [200, 0],
      easing: 'easeOutExpo',
      duration: 5400,
      delay: 100,
    })
  }

  var hoverslide = document.querySelectorAll('.card-card')
  for (let i = 0; i < hoverslide.length; i++) {
    if (window.pageYOffset === 0) {
      hoverslide[i].classList.remove('apply-hover')
    }
    hoverslide[i].addEventListener('mouseover', function () {
      if (window.pageYOffset > 0) {
        hoverslide[i].classList.add('apply-hover')
      }
    })
    hoverslide[i].addEventListener('mouseleave', function () {
      if (window.pageYOffset > 0) {
        setTimeout(() => {
          hoverslide[i].classList.remove('apply-hover')
        }, 200)
      }
    })
  }

  useEffect(() => {
    var hoverslide = document.querySelectorAll('.card-card')
    for (let i = 0; i < hoverslide.length; i++) {
      if (window.pageYOffset === 0) {
        hoverslide[i].classList.remove('apply-hover')
      }
      hoverslide[i].addEventListener('mouseover', function () {
        if (window.pageYOffset > 0) {
          hoverslide[i].classList.add('apply-hover')
        }
      })
      hoverslide[i].addEventListener('mouseleave', function () {
        if (window.pageYOffset > 0) {
          setTimeout(() => {
            hoverslide[i].classList.remove('apply-hover')
          }, 200)
        }
      })
    }
    // return (() => {
    //   for (let i = 0; i < hoverslide.length; i++) {
    //     if (window.pageYOffset === 0) {
    //       hoverslide[i].classList.remove('apply-hover')
    //     }
    //     hoverslide[i].removeEventListener('mouseover', function () {
    //       if (window.pageYOffset > 0) {
    //         hoverslide[i].classList.add('apply-hover')
    //       }
    //     })
    //     hoverslide[i].removeEventListener('mouseleave', function () {
    //       if (window.pageYOffset > 0) {
    //         setTimeout(() => {
    //           hoverslide[i].classList.remove('apply-hover')
    //         }, 200)
    //       }
    //     })
    //   }
    // })
  })

  useEffect(() => {
    const trial = () => {
      if (window.pageYOffset > 0) {
        var ele = document.getElementById('scrollEnable')
        if (ele !== null) {
          if (ele.classList.contains('maxim-slide') === false) {
            document.querySelector('.sec-1-slideup').style.display = 'block'
            sideline()
          }
          ele.classList.add('maxim-slide')

          ele.classList.remove('minim-slide')
        }
      }
    }
    const trial02 = () => {
      var ele = document.getElementById('scrollEnable')
      if (ele !== null) {
        ele.classList.add('minim-slide')
        ele.classList.remove('maxim-slide')
        // var carousal=document.querySelector('.crousal-container');
        // if(carousal!=null)
        // carousal.style.display='none';
      }
    }

    window.addEventListener('scroll', (e) => {
      if (window.pageYOffset === 0) {
        trial02()
      } else
        setTimeout(() => {
          trial()
          anime({
            loop: false,
            targets: '.react-stacked-center-carousel-slide-0 .slidetext',
            translateY: [345, 0],
            duration: 100,
            opacity: [0, 1],
            delay: 300,

            // easing: 'easeInOutQuad',
            // begin: () => {
            //   var sReact_stacked = document.querySelector(
            //     '.react-stacked-center-carousel-slide-0 .slidetext',
            //   )
            //   if (sReact_stacked !== null) {
            //     // sReact_stacked.style.opacity = 0
            //     sReact_stacked.style.display = 'block'
            //   }
            // },
          })
        }, 400)
    })

    window.addEventListener('resize', () => {
      let width = window.innerWidth
      if (width <= 991) {
        setSlideNumber(9)
      } else {
        setSlideNumber(15)
      }
    })
    setTimeout(() => {
      callApiData(getLanguage())
    }, 1500)
  }, [lang])

  const callApiData = (language) => {
    axiosInstance
      .get(`${devapiUrl}${language}/homeprojects/`)
      .then((res) => {
        var data = res.data.projects
        let arr = data.slice(0, 15)
        setData(arr)
        setTotalSlides(arr.length)
        let arr2 = []
        for (let i = 1; i <= arr.length; i++) {
          let s = ''
          if (i < 10) {
            s += '0'
          }
          s += i
          arr2.push(s)
        }

        setnumarr(arr2)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const [orientation, setOrientation] = useState(true)

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(false)
      setTimeout(() => {
        setOrientation(true)
      }, 300)
    }

    window.addEventListener('orientationchange', handleOrientationChange)

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [])
  return (
    <>
      <div
        ref={sidelineplay}
        className="card projectsSlider_custom minim-slide section_data"
        id="scrollEnable"
        style={{ width: '100% !important' }}
      >
        <div className="sec-1-slideup">
          <div className="sidelinecontent">
            <span className="filldot"></span>
            <p className="text">
              01{' '}
              {home
                ? home.top_banner.home_featured_project
                : '01 featured projects'}
            </p>
            <span className="line"></span>
            <span className="outlinedot"></span>
            <span className="outlinedot"></span>
            <span className="outlinedot"></span>
          </div>
        </div>
        <div className="sliderShadow">
          <img src="/audio/img/home-hero-shadow.png" alt="Side" />
        </div>
        {orientation && (
          <div style={{ width: '100%', position: 'relative' }}>
            <ResponsiveContainer
              carouselRef={ref}
              render={(width, carouselRef) => {
                return (
                  <StackedCarousel
                    ref={carouselRef}
                    slideComponent={Slide}
                    // slideWidth={100}
                    carouselWidth={width}
                    data={data}
                    maxVisibleSlide={15}
                    disableSwipe
                    // customScales={[1, 0.85, 0.7, 0.55]}
                    currentVisibleSlide={slideNumber}
                    onActiveSlideChange={onCenterSlideDataIndexChange}
                    transitionTime={4}
                  />
                )
              }}
            />

            {/* aryan integradtion for crousel */}
            <div className="IndicatorsPosition">
              <div className="crousal-container">
                {totalSlides && (
                  <span className="project-wrap crousal-left-count">
                    {numarr.map((num, index) => {
                      return (
                        <span key={index} className="project-animetext">
                          {num
                            .toString()
                            .split('')
                            .map((d, i) => {
                              return (
                                <span
                                  key={i}
                                  className={`project-number project-letter${index + 1}`}
                                >
                                  {d}
                                </span>
                              )
                            })}
                        </span>
                      )
                    })}
                  </span>

                  //             <p className="crousal-left-count">
                  //               {/* {' '} */}
                  //               {(centerSlideDataIndex + 1).toString().padStart(2, '0').split('').map((d, i) => {
                  //             return(
                  //  <span className='project-letter'>{d}</span>
                  //             )

                  //               })}

                  //             </p>
                )}

                <div className="crousal-wrap">
                  <span className="trial-pin-2"></span>
                  <span className="crousal-pin pin-x"></span>
                  <span className="crousal-pin pin-x0"></span>
                  <span className="crousal-pin pin-x1"></span>
                  <span className="crousal-pin pin-x2"></span>
                  <span className="trial-pin-1"></span>
                  <span className="crousal-center-button"></span>
                  <span className="crousal-pin pin-y0"></span>
                  <span className="crousal-pin pin-y "></span>
                  <span className="crousal-pin pin-y1"></span>
                  <span className="crousal-pin pin-y2"></span>
                  <span className="trial-pin-3"></span>
                </div>
                {totalSlides && (
                  <p className="crousal-right-count">{totalSlides}</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CardExample
