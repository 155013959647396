import { Link } from 'react-router-dom'
import { getDateFormat } from '../../../constants/dateconvert'

const NewsBigCard = ({
  data,
  className1,
  className2,
  staticdata,
  language,
}) => {
  return (
    <>
      <div className={className1 ? className1 : 'news-sec-subsec-2-box-1'}>
        <p>
          {getDateFormat(
            data.published_date ? data.published_date : new Date(),
            'MMM DD, YYYY',
          )}
        </p>
        {/* <div className="news--bigcard-border"></div>
        <p>{data.duration ? data.duration : '15 Min'}</p>
        <div className="news--bigcard-border"></div>
        <p>{data.news_category ? data.news_category : 'Category'}</p> */}
      </div>
      <div className={className2 ? className2 : 'news-sec-subsec-2-box-2'}>
        <div className="item3-large">
          <div className="news-image">
            <Link
              to={
                data.news_url
                  ? language === 'en'
                    ? `/audio/news/${data.news_url}`
                    : `/${language}/audio/news/${data.news_url}`
                  : null
              }
              state={data}
            >
              <img
                src={
                  data.news_images.news_image1_400x248
                    ? data?.news_images?.news_image1_400x248
                    : data?.news_images?.news_image1_275x170
                }
                alt=""
              />
            </Link>
            {/* <img src="/temp_img/Rectangle 1243.svg" alt="" /> */}
          </div>
          <div className="news-details-large">
            <h4>{data.title ? data.title : ''}</h4>
          </div>
          <div className="news-footer">
            <div className="read">
              <Link
                to={
                  data.news_url
                    ? language === 'en'
                      ? `/audio/news/${data.news_url}`
                      : `/${language}/audio/news/${data.news_url}`
                    : null
                }
                state={data}
              >
                {staticdata
                  ? staticdata.news_content_read_article
                  : 'Read Article1'}{' '}
                <span>&#8594;</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewsBigCard
