import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
const Section6 = () => {
  const home = useSelector((state) => state.homedata)
  const sec6ref = useRef(null)
  useEffect(() => {
    let oldValue = 0
    var img1 = document.querySelector('.sec-6-i1')
    var img2 = document.querySelector('.sec-6-i2')
    var img3 = document.querySelector('.sec-6-i3')
    var img4 = document.querySelector('.sec-6-i4')
    var img5 = document.querySelector('.sec-6-i5')
    var img6 = document.querySelector('.sec-6-i6')

    //  console.log(img1.style.top);

    // calling scroll event
    window.addEventListener('scroll', (e) => {
      //------Parallax Animation-------------------//

      //-----------------Location card Parallax animation----------//
      var domsec6 = document.getElementById('animclipsec6')

      var animclip6 =
        domsec6 !== null ? domsec6.getElementsByTagName('div') : null
      var windowYScroll = window.scrollY

      if (animclip6 !== null) {
        if (
          (windowYScroll > 2800 && windowYScroll < 3500) ||
          (windowYScroll > 4500 && windowYScroll < 5000)
        ) {
          for (var i = 0; i < animclip6.length; i++) {
            var elem = animclip6[i]
            if (i === 0) {
              elem.children[0].style.height = '13%'
              elem.children[1].style.height = '0%'
            } else if (i === 1) {
              elem.children[0].style.height = '5%'
              elem.children[1].style.height = '12%'
            } else if (i === 2) {
              elem.children[0].style.height = '0%'
              elem.children[1].style.height = '21%'
            } else if (i === 3) {
              elem.children[0].style.height = '14%'
              elem.children[1].style.height = '4%'
            } else if (i === 4) {
              elem.children[0].style.height = '0%'
              elem.children[1].style.height = '18%'
            } else {
              elem.children[0].style.height = '12%'
              elem.children[1].style.height = '6%'
            }
          }
        }
      }

      if (animclip6 !== null) {
        if (
          (windowYScroll > 3500 && windowYScroll < 4000) ||
          (windowYScroll > 4000 && windowYScroll < 4500) ||
          windowYScroll > 5000
        ) {
          for (var i = 0; i < animclip6.length; i++) {
            var elem = animclip6[i]
            if (i === 0) {
              elem.children[0].style.height = '8%'
              elem.children[1].style.height = '8%'
            } else if (i === 1) {
              elem.children[0].style.height = '0%'
              elem.children[1].style.height = '20%'
            } else if (i === 2) {
              elem.children[0].style.height = '12%'
              elem.children[1].style.height = '5%'
            } else if (i === 3) {
              elem.children[0].style.height = '8%'
              elem.children[1].style.height = '10%'
            } else if (i === 4) {
              elem.children[0].style.height = '3%'
              elem.children[1].style.height = '17%'
            } else {
              elem.children[0].style.height = '7%'
              elem.children[1].style.height = '10%'
            }
          }
        }
      }

      let newValue = window.pageYOffset
      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.boundingClientRect.top > 0) {
              // console.log("BELOW") // do things if below
              var topVal1 = parseFloat(img1.style.top)
              var topVal2 = parseFloat(img2.style.top)
              var topVal3 = parseFloat(img3.style.top)
              var topVal4 = parseFloat(img4.style.top)
              var topVal5 = parseFloat(img5.style.top)
              var topVal6 = parseFloat(img6.style.top)
              var speed1 = 0.11
              var speed2 = 0.13
              var speed3 = 0.15
              var speed3 = 0.17
              var speed4 = 0.19
              var speed5 = 0.21
              var speed6 = 0.23
              if (topVal1 <= 9) {
                topVal1 = 9
              } else {
                topVal1 = topVal1 - speed1
              }

              if (topVal2 >= 0) {
                topVal2 = 0
              } else {
                topVal2 = topVal2 + speed2
              }
              if (topVal3 <= 14.5) topVal3 = 14.5
              else topVal3 = topVal3 - speed3

              if (topVal4 >= 8.6) topVal4 = 8.6
              else topVal4 = topVal4 + speed4
              if (topVal5 >= 0) {
                topVal5 = 0
              } else {
                topVal5 = topVal5 + speed5
              }
              if (topVal6 <= 7.6) {
                topVal6 = 7.6
              } else {
                topVal6 = topVal6 - speed6
              }
              img1.style.top = topVal1 + '%'
              img2.style.top = topVal2 + '%'
              img3.style.top = topVal3 + '%'
              img4.style.top = topVal4 + '%'
              img5.style.top = topVal5 + '%'
              img6.style.top = topVal6 + '%'
            } else {
              if (entry.intersectionRatio >= 0.9) {
                // sideline.play();
                img1.style.top = '9%'
                img2.style.top = '0%'
                img3.style.top = '14.5%'
                img4.style.top = '8.6%'
                img5.style.top = '0%'
                img6.style.top = '7.6%'
              } else {
                var topVal1 = parseFloat(img1.style.top)
                var topVal2 = parseFloat(img2.style.top)
                var topVal3 = parseFloat(img3.style.top)
                var topVal4 = parseFloat(img4.style.top)
                var topVal5 = parseFloat(img5.style.top)
                var topVal6 = parseFloat(img6.style.top)
                // console.log(topVal6)
                //setting some speed
                var speed = 0.15
                var speed1 = 0.11
                var speed2 = 0.13
                var speed3 = 0.15
                var speed3 = 0.17
                var speed4 = 0.19
                var speed5 = 0.21
                var speed6 = 0.23
                if (oldValue - newValue < 0) {
                  if (topVal1 >= 20) topVal1 = 20
                  else topVal1 = topVal1 + speed1

                  if (topVal2 <= -10) topVal2 = -10
                  else topVal2 = topVal2 - speed2

                  if (topVal3 >= 25) topVal3 = 25
                  else topVal3 = topVal3 + speed3

                  if (topVal4 <= -3) topVal4 = -3
                  else topVal4 = topVal4 - speed4

                  if (topVal5 <= -11) topVal5 = -11
                  else topVal5 = topVal5 - speed5

                  if (topVal6 >= 12) topVal6 = 12
                  else topVal6 = topVal6 + speed6

                  // console.log('down scroll');
                } else if (oldValue - newValue > 0) {
                  if (topVal1 <= 9) {
                    topVal1 = 9
                  } else {
                    topVal1 = topVal1 - speed1
                  }

                  if (topVal2 >= 0) {
                    topVal2 = 0
                  } else {
                    topVal2 = topVal2 + speed2
                  }
                  if (topVal3 <= 14.5) topVal3 = 14.5
                  else topVal3 = topVal3 - speed3

                  if (topVal4 >= 8.6) topVal4 = 8.6
                  else topVal4 = topVal4 + speed4
                  if (topVal5 >= 0) {
                    topVal5 = 0
                  } else {
                    topVal5 = topVal5 + speed5
                  }
                  if (topVal6 <= 7.6) {
                    topVal6 = 7.6
                  } else {
                    topVal6 = topVal6 - speed6
                  }

                  // console.log('up scroll');
                }
              }

              oldValue = newValue

              //Set new top value for element
              img1.style.top = topVal1 + '%'
              img2.style.top = topVal2 + '%'
              img3.style.top = topVal3 + '%'
              img4.style.top = topVal4 + '%'
              img5.style.top = topVal5 + '%'
              img6.style.top = topVal6 + '%'
            }
          })
        },
        { threshold: 1 },
      )

      if (sec6ref.current != null) observer.observe(sec6ref.current)

      e.preventDefault()
    })
  }, [])

  return (
    <div ref={sec6ref} className="section6 oneScroll">
      <div id="sec-6-ppt" className="stripes" style={{ display: 'none' }}>
        <img className="sec-6-i1" src="/audio/img/ws1.svg" alt="" />
        <img className="sec-6-i2" src="/audio/img/ws2.svg" alt="" />
        <img className="sec-6-i3" src="/audio/img/ws3.svg" alt="" />
        <img className="sec-6-i4" src="/audio/img/ws4.svg" alt="" />
        <img className="sec-6-i5" src="/audio/img/ws5.svg" alt="" />
        <img className="sec-6-i6" src="/audio/img/ws6.svg" alt="" />
      </div>

      <div id="sec-6-ppt" className="side_stripes">
        <img
          src={home ? home.home_botton_image : '/img/ws-banner.jpg'}
          alt="side"
        />
        <div id="animclipsec6" className="stripes">
          <div className="block trail-block1">
            <span></span>
            <span></span>
          </div>
          <div className="block trail-block2">
            <span></span>
            <span></span>
          </div>
          <div className="block trail-block3">
            <span></span>
            <span></span>
          </div>
          <div className="block trail-block4">
            <span></span>
            <span></span>
          </div>
          <div className="block trail-block5">
            <span></span>
            <span></span>
          </div>
          <div className="block trail-block6">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section6
