import React, { useState, useEffect } from 'react'
import '../../styles/project/projects.css'
import { useNavigate } from 'react-router-dom'
import '../../styles/project/projects.css'
import axiosInstance from '../../services/axiosInstance'
import { useRef } from 'react'
import anime from 'animejs'
import { forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Pagination from '../../constants/pagination/Pagination'
import { devapiUrl } from '../../constants/devapi'
import { changeLoaderState } from '../../actions/lngactions'
import { getLanguage } from '../../constants/urls'

const Oldloader = () => {
  return (
    <div
      className="loader-container main-body"
      style={{
        '--loader-dot-color': 'white',
        '--loader-dot-size': '50px',
        '--loader-dot-spacing': '25px',
      }}
    >
      <div className="loader-dots" style={{ marginTop: '20px' }}>
        <div className="loader-dot moving-dot"></div>
        <div className="loader-dot fixed-dot"></div>
        <div className="loader-dot fixed-dot"></div>
        <div className="loader-dot fixed-dot"></div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        style={{ display: 'none' }}
      >
        <defs>
          <filter id="gooey">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
            />
          </filter>
        </defs>
      </svg>
    </div>
  )
}

const Projectlist_card = forwardRef((props, ref) => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  let langcode = useSelector((state) => state.lang)
  // state component
  const [sorting, setsorting] = useState([])
  const split = useRef(null)
  const orderRef = useRef(null)
  const [filter1, Setfilter1] = useState([])
  const [filter2, Setfilter2] = useState([])
  const [filter3, Setfilter3] = useState([])
  const [activepageIndex, setActivepageIndex] = useState(1)
  const [show, setshow] = useState(false)
  const [gametype, setgametype] = useState([])
  const [services, setservices] = useState([])
  const [genre, setgenre] = useState([])
  const [projects, setprojects] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [loader, setLoader] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [noProjectsMessage, setNoProjectMessage] = useState('')
  const pageno = useRef(1)
  const sideline2ref = useRef()
  const allproject_count = useRef(1)
  const allprojectsRef = useRef(null)
  const data = props.data || []
  const filterRef = useRef(null)
  const order = useRef(
    data.project_static_content_all_projects_section
      ? data.project_static_content_all_projects_section
          .projects_static_content_newest_to_oldest
      : 'Newest To Oldest',
  )
  const sort = useRef('')
  const filter1Ref = useRef(null)
  const filter2Ref = useRef(null)
  const filter3Ref = useRef(null)
  const [orderactive, Setorderactive] = useState(false)
  const [pageproject, setpageproject] = useState([])
  const lang = useSelector((state) => state)
  const viewRef = useRef(null)
  useEffect(() => {
    updatedata(currentPage, getLanguage(), 'FIRST')

    if (ref != null) ref.current = 1
    if (split.current != null)
      split.current.innerHTML = split.current.textContent.replace(
        /\S/g,
        "<span className='allprojects-letters'>$&</span>",
      )

    window.addEventListener('scroll', (e) => {
      var textanime1 = anime.timeline({
        loop: false,
        autoplay: false,
      })
      textanime1
        .add({
          targets: '.projectlist-allprojects .allprojects-letters',
          translateY: [0],
          duration: 0,
        })
        .add({
          targets: '.projectlist-allprojects .allprojects-letters',
          translateY: [45, 0],
          easing: 'linear',
          duration: 1000,
          //stagger:anime.stagger(50),
          delay: (el, i) => 50 * i,
          endDelay: 500,
        })

      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio === 0) {
              allproject_count.current = 0
            }
            if (entry.intersectionRatio === 1) {
              if (allproject_count.current === 0) {
                textanime1.restart()
                allproject_count.current = 1
              }
            }
          })
        },
        { threshold: 1 },
      )

      if (allprojectsRef.current != null)
        observer.observe(allprojectsRef.current)
      e.preventDefault()
    })
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [services, gametype, sorting, genre, currentPage])
  const handleClickOutside = (event) => {
    if (orderRef.current && !orderRef.current.contains(event.target)) {
      Setorderactive(false)
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        !filter1Ref.current.contains(event.target) &&
        !filter2Ref.current.contains(event.target) &&
        !filter3Ref.current.contains(event.target)
      ) {
        var filter1 = document.getElementById('filter1')
        var filter2 = document.getElementById('filter2')
        var filter3 = document.getElementById('filter3')
        if (filter1 != null) filter1.style.display = 'none'
        if (filter2 != null) filter2.style.display = 'none'
        if (filter3 != null) filter3.style.display = 'none'
        filter1 = document.getElementById('mygenre')
        filter2 = document.getElementById('myservice')
        filter3 = document.getElementById('mygame')
        if (filter1 != null) filter1.className = 'projectlist-3-link'
        if (filter2 != null) filter2.className = 'projectlist-3-link'
        if (filter3 != null) filter3.className = 'projectlist-3-link'
        if (document.getElementById('myreset') != null)
          document.getElementById('myreset').className = 'projectlist-3-link'
      }
    }
  }

  const updatedata = (pageNo = 1, language1, f) => {
    axiosInstance
      .post(`${devapiUrl}project/`, {
        lang: language1,
        sorting: sort.current !== '' ? sort.current : 'N2O',
        pageno: pageNo,
        recordsperpage: 8,
        game_type: gametype,
        services: services,
        genre: genre,
      })
      .then((res) => {
        setCurrentPage(pageNo)
        setpageproject(res.data.projects)
        let totalRecords = res.data.totalrecords
        if (totalRecords > 0) {
          setNoProjectMessage(`No Projects Found`)
        }
        setTotalPages(Math.ceil(totalRecords / 8))
        setCurrentPage(pageNo)
        dispatch(changeLoaderState(false))
        setLoader(false)
        if (f !== 'FIRST') {
          updatedToProjectsPosition()
        }
      })
      .catch((err) => {
        dispatch(changeLoaderState(false))
      })
  }
  const opendropdown = () => {
    if (show === false)
      document.getElementById('myDropdown').style.display = 'block'
    else document.getElementById('myDropdown').style.display = 'none'
    setshow(!show)
  }

  const reset = (e) => {
    //clicked link is genre
    if (e.target.id.trim() === 'mygenre') {
      document.getElementById('filter2').style.display = 'none'
      //setting other id unactive classname
      document.getElementById('mygame').className = 'projectlist-3-link'
      document.getElementById('myservice').className = 'projectlist-3-link'
      document.getElementById('filter1').style.display = 'none'

      const ele = document.getElementById('mygenre')
      if (ele.className === 'projectlist-3-link') {
        document.getElementById('filter3').style.display = 'block'
        ele.className = 'projectlist-3-link active'
      } else {
        document.getElementById('filter3').style.display = 'none'
        ele.className = 'projectlist-3-link'
      }
      if (document.getElementById('myreset') != null)
        document.getElementById('myreset').className = 'projectlist-3-link'
    }

    //clicked link is game
    if (e.target.id.trim() === 'mygame') {
      document.getElementById('filter2').style.display = 'none'

      //setting other id unactive classname
      document.getElementById('mygenre').className = 'projectlist-3-link'
      document.getElementById('myservice').className = 'projectlist-3-link'

      //reseting active link in filter 2
      // var a2 = document.getElementById("filter2").getElementsByTagName("a");
      // for (var i = 0; i < a2.length; i++) {
      //   var elem = a2[i];
      //   elem.className = "apl-link";
      // }
      if (document.getElementById('myreset') != null)
        document.getElementById('myreset').className = 'projectlist-3-link'

      document.getElementById('filter3').style.display = 'none'

      //reseting active link in filter 3
      // var a3 = document.getElementById("filter3").getElementsByTagName("a");
      // for (var i = 0; i < a3.length; i++) {
      //   var elem = a3[i];
      //   elem.className = "apl-link";
      // }

      const ele = document.getElementById('mygame')
      if (ele.className === 'projectlist-3-link') {
        ele.className = 'projectlist-3-link active'
        document.getElementById('filter1').style.display = 'block'
      } else {
        ele.className = 'projectlist-3-link'
        document.getElementById('filter1').style.display = 'none'
      }
    }

    //cliked link is service
    if (e.target.id.trim() === 'myservice') {
      if (document.getElementById('myreset') != null)
        document.getElementById('myreset').className = 'projectlist-3-link'
      document.getElementById('filter3').style.display = 'none'

      //setting other id unactive classname
      document.getElementById('mygame').className = 'projectlist-3-link'
      document.getElementById('mygenre').className = 'projectlist-3-link'
      //reseting active link in filter 3
      // var a3 = document.getElementById("filter3").getElementsByTagName("a");
      // for (var i = 0; i < a3.length; i++) {
      //   var elem = a3[i];
      //   elem.className = "apl-link";
      // }

      document.getElementById('filter1').style.display = 'none'

      //reseting active link in filter 1
      //  var a1 = document.getElementById("filter1").getElementsByTagName("a");
      //  for (var i = 0; i < a1.length; i++) {
      //    var elem = a1[i];
      //    elem.className = "apl-link";
      //  }

      const ele = document.getElementById('myservice')
      if (ele.className === 'projectlist-3-link') {
        ele.className = 'projectlist-3-link active'
        document.getElementById('filter2').style.display = 'block'
      } else {
        ele.className = 'projectlist-3-link'
        document.getElementById('filter2').style.display = 'none'
      }
    }

    e.preventDefault()
  }

  const cross = (e) => {
    //clicked span is genre
    if (e.target.id.trim() === 'mygenrespan') {
      document.getElementById('filter2').style.display = 'none'

      //setting other id unactive classname
      document.getElementById('mygame').className = 'projectlist-3-link'
      document.getElementById('myservice').className = 'projectlist-3-link'

      //resting active link in filter 2
      // var a2 = document.getElementById("filter2").getElementsByTagName("a");
      // for (var i = 0; i < a2.length; i++) {
      //   var elem = a2[i];
      //   elem.className = "apl-link";
      // }
      document.getElementById('filter1').style.display = 'none'
      //reseting active link in filter 1
      // var a1 = document.getElementById("filter1").getElementsByTagName("a");
      // for (var i = 0; i < a1.length; i++) {
      //   var elem = a1[i];
      //   elem.className = "apl-link";
      // }

      const ele = document.getElementById('mygenre')
      if (ele.className === 'projectlist-3-link') {
        document.getElementById('filter3').style.display = 'block'
        ele.className = 'projectlist-3-link active'
      } else {
        document.getElementById('filter3').style.display = 'none'
        ele.className = 'projectlist-3-link'
      }
      if (document.getElementById('myreset') != null)
        document.getElementById('myreset').className = 'projectlist-3-link'
    }

    //clicked span is game
    if (e.target.id.trim() === 'mygamespan') {
      if (document.getElementById('myreset') != null)
        document.getElementById('myreset').className = 'projectlist-3-link'

      document.getElementById('filter2').style.display = 'none'

      //setting other id unactive classname
      document.getElementById('mygenre').className = 'projectlist-3-link'
      document.getElementById('myservice').className = 'projectlist-3-link'
      //reseting active link in filter 2
      // var a2 = document.getElementById("filter2").getElementsByTagName("a");
      // for (var i = 0; i < a2.length; i++) {
      //   var elem = a2[i];
      //   elem.className = "apl-link";
      // }

      document.getElementById('filter3').style.display = 'none'

      //reseting active link in filter 3
      // var a3 = document.getElementById("filter3").getElementsByTagName("a");
      // for (var i = 0; i < a3.length; i++) {
      //   var elem = a3[i];
      //   elem.className = "apl-link";
      // }

      const ele = document.getElementById('mygame')
      if (ele.className === 'projectlist-3-link') {
        ele.className = 'projectlist-3-link active'
        document.getElementById('filter1').style.display = 'block'
      } else {
        ele.className = 'projectlist-3-link'
        document.getElementById('filter1').style.display = 'none'
      }
    }

    //clicked span is service
    if (e.target.id.trim() === 'myservicespan') {
      if (document.getElementById('myreset') != null)
        document.getElementById('myreset').className = 'projectlist-3-link'

      document.getElementById('filter3').style.display = 'none'
      //setting other id unactive classname
      document.getElementById('mygame').className = 'projectlist-3-link'
      document.getElementById('mygenre').className = 'projectlist-3-link'

      document.getElementById('filter1').style.display = 'none'

      const ele = document.getElementById('myservice')
      if (ele.className === 'projectlist-3-link') {
        ele.className = 'projectlist-3-link active'
        document.getElementById('filter2').style.display = 'block'
      } else {
        ele.className = 'projectlist-3-link'
        document.getElementById('filter2').style.display = 'none'
      }
    }

    e.preventDefault()
  }

  const handlezooming = (e, index) => {
    for (let i = 0; i < pageproject.length; i++) {
      document.querySelector(
        `.project-list-img${pageproject[i].id}`,
      ).style.transform = 'scale(1)'
    }
    document.querySelector(`.project-list-img${index}`).style.transform =
      'scale(1.05)'

    e.preventDefault()
  }
  const handlezoomoff = (e, index) => {
    document.querySelector(`.project-list-img${index}`).style.transform =
      'scale(1)'

    e.preventDefault()
  }

  const handlereset = (e) => {
    setCurrentPage(1)
    // document.getElementById('filter1').style.display = 'none'
    // document.getElementById('filter2').style.display = 'none'
    // document.getElementById('filter3').style.display = 'none'
    setgametype([])
    setgenre([])
    setservices([])
    //setting other id unactive classname
    document.getElementById('mygenre').className = 'projectlist-3-link'
    document.getElementById('myservice').className = 'projectlist-3-link'
    document.getElementById('mygame').className = 'projectlist-3-link'
    if (e.target.classList.contains('projectlist-3-link')) {
      e.target.className = 'projectlist-3-link active'

      pageno.current = 1
      setActivepageIndex(pageno.current)
      sort.current = ''
      order.current = data.project_static_content_all_projects_section
        ? data.project_static_content_all_projects_section
            .projects_static_content_newest_to_oldest
        : 'NEWEST TO OLDEST'
      setsorting('N2O')
      sort.current = 'N2O'
      var a = document.getElementById('myDropdown').getElementsByTagName('a')
      for (var i = 0; i < a.length; i++) {
        var elem = a[i]
        elem.className = 'drpc-link'
      }

      // resting active link in filter 1
      var a2 = document.getElementById('filter1').getElementsByTagName('a')
      for (var i = 0; i < a2.length; i++) {
        var elem = a2[i]
        elem.className = 'apl-link'
      }
      // resting active link in filter 2
      var a2 = document.getElementById('filter2').getElementsByTagName('a')
      for (var i = 0; i < a2.length; i++) {
        var elem = a2[i]
        elem.className = 'apl-link'
      }
      // resting active link in filter 3
      var a2 = document.getElementById('filter3').getElementsByTagName('a')
      for (var i = 0; i < a2.length; i++) {
        var elem = a2[i]
        elem.className = 'apl-link'
      }
    } else {
      e.target.className = 'projectlist-3-link'
    }
    updatedToProjectsPosition()
    setLoader(true)
  }

  const handleservice = (e) => {
    setCurrentPage(1)
    var text = e.target.innerHTML
    // setgametype([]);
    // setgenre([]);

    if (e.target.className === 'apl-link') {
      e.target.className = 'apl-link active'
      if (!services.includes(text.toUpperCase())) {
        // ✅ only runs if value not in array
        // arr.push(str);
        services.push(text.toUpperCase())
      }
    } else {
      e.target.className = 'apl-link'
      services.splice(services.indexOf(text.toUpperCase()), 1)
    }
    updatedToProjectsPosition()
    setLoader(true)
    updatedata(1, getLanguage())
    e.preventDefault()
  }
  const handlegametype = (e) => {
    setCurrentPage(1)
    var text = e.target.innerHTML
    // setservices([]);
    // setgenre([]);
    if (e.target.className === 'apl-link') {
      e.target.className = 'apl-link active'
      if (!gametype.includes(text.toUpperCase())) {
        // ✅ only runs if value not in array
        // arr.push(str);
        gametype.push(text.toUpperCase())
      }
    } else {
      e.target.className = 'apl-link'
      gametype.splice(gametype.indexOf(text.toUpperCase()), 1)
    }

    //gametype.push(text.toUpperCase());
    updatedToProjectsPosition()
    setLoader(true)
    updatedata(1, getLanguage())
    e.preventDefault()
  }
  const handlegenre = (e) => {
    setCurrentPage(1)
    var text = e.target.innerHTML
    // setgametype([]);
    // setservices([]);
    if (e.target.className === 'apl-link') {
      e.target.className = 'apl-link active'
      if (!genre.includes(text.toUpperCase())) {
        // ✅ only runs if value not in array
        // arr.push(str);
        genre.push(text.toUpperCase())
      }
    } else {
      e.target.className = 'apl-link'
      genre.splice(genre.indexOf(text.toUpperCase()), 1)
    }

    //  genre.push(text.toUpperCase());
    updatedToProjectsPosition()
    setLoader(true)
    updatedata(1, getLanguage())
    e.preventDefault()
  }

  const viewproject = (e, url) => {
    e.persist()
    localStorage.setItem('isCardClicked', true)
    let lang = getLanguage()
    if (lang === 'en') navigate(`/audio/projects/${url}`)
    else navigate(`/${lang}/audio/projects/${url}`)
  }

  var PageSize = 8
  var totalPage = Math.ceil(projects.length / PageSize)
  var pages = []
  for (let i = 1; i <= totalPage; i++) pages.push(i)
  const updatepage = async (pageNo, index) => {
    updatedata(pageNo, getLanguage())
  }

  const handlesorting = async (e) => {
    var a = document.getElementById('myDropdown').getElementsByTagName('a')
    for (var i = 0; i < a.length; i++) {
      var elem = a[i]
      elem.className = 'drpc-link'
    }
    if (e.target.className === 'drpc-link')
      e.target.className = 'drpc-link active'
    else e.target.className = 'drpc-link'

    var clickedtext = e.target.innerHTML
    if (
      clickedtext.trim().toUpperCase() ==
      data.project_static_content_all_projects_section
        .projects_static_content_alphabetical_order
    ) {
      setsorting('ALPHA')
      sort.current = 'ALPHA'
      order.current = data.project_static_content_all_projects_section
        ? data.project_static_content_all_projects_section
            .projects_static_content_alphabetical_order
        : 'Alphabet45ical order'
    }
    if (
      clickedtext.trim().toUpperCase() ==
      data.project_static_content_all_projects_section
        .projects_static_content_newest_to_oldest
    ) {
      setsorting('N2O')
      sort.current = 'N2O'
      order.current = data.project_static_content_all_projects_section
        ? data.project_static_content_all_projects_section
            .projects_static_content_newest_to_oldest
        : 'NEWEST TO OLDEST'
      // order.current="NEWEST TO OLDEST"
    }
    if (
      clickedtext.trim().toUpperCase() ==
      data.project_static_content_all_projects_section
        .projects_static_content_oldest_to_newest
    ) {
      //console.log('check-3');
      setsorting('O2N')
      sort.current = 'O2N'
      order.current = data.project_static_content_all_projects_section
        ? data.project_static_content_all_projects_section
            .projects_static_content_oldest_to_newest
        : 'OLDEST TO NEWEST'

      // order.current="OLDEST TO NEWEST"
    }
    //setsorting(e.target.innerHTML);.then(console.logthen(console.log(sorting))
    setCurrentPage(1)
    updatedToProjectsPosition()
    setLoader(true)
    updatedata(1, getLanguage())
    Setorderactive(false)
    // Setloading(false)
    // setTimeout(() => {
    //   Setloading(true)
    // }, 2000);
    // opendropdown();
    // console.log('here is', order.current)
  }
  //console.log(trial)

  const onPaginationChange = (currentPage) => {
    // dispatch(changeLoaderState(true))
    updatedToProjectsPosition()
    setLoader(true)
    updatedata(currentPage, getLanguage())
  }

  const updatedToProjectsPosition = () => {
    let ele = document.getElementById('projectlist')
    if (ele !== null) {
      setTimeout(() => {
        ele.scrollIntoView()
      }, 0)
    }
  }

  useEffect(() => {
    getfilterdata()
    updatedata(1, getLanguage(), 'FIRST')
  }, [langcode])
  const getfilterdata = (lang = 'en') => {
    axiosInstance.get(`${devapiUrl}${lang}/projectfilters`).then((res) => {
      // dispatch(servicedata(res.data.services))
      // dispatch(changeLanguage(lang))
      Setfilter1(res.data.project.game_type)
      Setfilter2(res.data.project.services)
      Setfilter3(res.data.project.genre)

      // console.log('filter data in projectpage is ', res.data.project.game_type)
    })
  }
  var sideline2 = anime.timeline({ loop: false, autoplay: false }).add({
    targets: '.allproject_sideline',
    translateY: [900, 480],
    easing: 'easeOutExpo',
    duration: 7400,
    delay: 100,
  })
  let count1 = 0
  useEffect(() => {
    let observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          // console.log(entry.intersectionRatio)
          if (entry.intersectionRatio === 1 && count1 === 0) {
            sideline2.play()
            count1 = count1 + 1
          } else if (entry.intersectionRatio === 0) {
            count1 = 0
          } else count1 = count1 + 1
        })
      },
      { threshold: 1 },
    )
    window.addEventListener('scroll', (e) => {
      if (sideline2ref.current != null) observer.observe(sideline2ref.current)
    })
  })
  return (
    <>
      <section id="projectlist" className="projectlist-sec-3 m-0">
        <div ref={sideline2ref} className="allproject_sideline">
          <div className="sidelinecontent-2">
            <span className="outlinedot-1"></span>
            <span className="filldot-1"></span>
            <p>
              02{' '}
              {data.project_static_content_all_projects_section
                ? data.project_static_content_all_projects_section
                    .project_static_content_all_projects
                : 'all projects'}
            </p>
          </div>
        </div>
        <div className="container position-relative">
          {/* <div className="banner_bottomleft_border border_lines"></div>
          <div className="banner_bottomright_border border_lines"></div> */}
          <div className="row card_row">
            <div ref={allprojectsRef} className="col-12 mb-4">
              <div className="projectlist-allprojects">
                <h6 ref={split}>
                  {data.project_static_content_all_projects_section
                    ? data.project_static_content_all_projects_section
                        .project_static_content_all_projects
                    : 'All PROJECTS'}
                </h6>
              </div>
              <div
                id="project-view"
                className="d-flex align-items-center pb-3 justify-content-between "
              >
                <div ref={filterRef} className="projectlist-3-lists-box">
                  <a
                    id="mygame"
                    onClick={(e) => {
                      reset(e)
                    }}
                    className={'projectlist-3-link'}
                  >
                    {data.project_static_content_all_projects_section
                      ? data.project_static_content_all_projects_section
                          .projects_static_content_game_type
                      : 'Game type'}

                    <span id="mygamespan" onClick={(e) => cross(e)}>
                      +
                    </span>
                  </a>
                  <a
                    id="myservice"
                    onClick={(e) => {
                      reset(e)
                    }}
                    className={'projectlist-3-link'}
                  >
                    {data.project_static_content_all_projects_section
                      ? data.project_static_content_all_projects_section
                          .project_static_content_services
                      : 'Services'}

                    <span
                      id="myservicespan"
                      onClick={(e) => {
                        cross(e)
                      }}
                    >
                      +
                    </span>
                  </a>
                  <a
                    id="mygenre"
                    onClick={(e) => {
                      reset(e, 3)
                    }}
                    className={'projectlist-3-link'}
                  >
                    {data.project_static_content_all_projects_section
                      ? data.project_static_content_all_projects_section
                          .projects_static_content_genre
                      : 'Genre'}

                    <span
                      id="mygenrespan"
                      onClick={(e) => {
                        cross(e)
                      }}
                    >
                      +
                    </span>
                  </a>
                  <a
                    id="myreset"
                    onClick={(e) => handlereset(e)}
                    className={'projectlist-3-link reset'}
                  >
                    {data.project_static_content_all_projects_section
                      ? data.project_static_content_all_projects_section
                          .project_static_content_reset
                      : 'Reset'}
                    {/* {projectdata.projectdata?} */}

                    {/* <span
                      id="mygenrespan"
                    >
                      +
                    </span> */}
                  </a>
                </div>
                <div ref={orderRef} className="project-category-subsec-2">
                  <button
                    onClick={(e) => Setorderactive(!orderactive)}
                    className="dropbtn view-all"
                    style={{ paddingBottom: '10px' }}
                  >
                    <span
                      className="d-none d-lg-inline btn-flip"
                      data-back={order.current}
                      data-front={order.current}
                    ></span>
                    <img
                      className="animate-img icon d-none d-lg-inline"
                      style={{ paddingBottom: '10px' }}
                      src="/audio/temp_img/bottom-arrow.svg"
                      alt="bottom-arrow"
                    />
                    <img
                      className="icon d-block d-lg-none me-2"
                      src="/audio/temp_img/mobile-filter-icon.svg"
                      alt="bottom-arrow"
                    />
                  </button>
                  <div
                    style={{ display: orderactive ? 'block' : 'none' }}
                    id="myDropdown"
                    className="dropdown-content"
                  >
                    <a
                      className="drpc-link"
                      onClick={(e) => {
                        handlesorting(e)
                      }}
                    >
                      {data.project_static_content_all_projects_section
                        ? data.project_static_content_all_projects_section
                            .projects_static_content_newest_to_oldest
                        : 'Newest to oldest'}
                    </a>
                    <a
                      className="drpc-link"
                      onClick={(e) => {
                        handlesorting(e)
                      }}
                    >
                      {data.project_static_content_all_projects_section
                        ? data.project_static_content_all_projects_section
                            .projects_static_content_alphabetical_order
                        : 'Alphabetical order'}
                      {/* Alphabetical order */}
                    </a>

                    <a
                      className="drpc-link"
                      onClick={(e) => {
                        handlesorting(e)
                      }}
                    >
                      {data.project_static_content_all_projects_section
                        ? data.project_static_content_all_projects_section
                            .projects_static_content_oldest_to_newest
                        : 'oldest to Newest'}
                    </a>
                  </div>
                </div>
              </div>
              <div
                ref={filter1Ref}
                id="filter1"
                className="all-projects-list-items "
              >
                {filter1 ? (
                  <>
                    {filter1.map((x, index) => {
                      return (
                        <a
                          key={index}
                          onClick={(e) => handlegametype(e)}
                          className="apl-link"
                        >
                          {x}
                        </a>
                      )
                    })}
                  </>
                ) : null}
              </div>
              <div
                ref={filter2Ref}
                id="filter2"
                className="all-projects-list-items "
              >
                {filter2 ? (
                  <>
                    {filter2.map((x, index) => {
                      return (
                        <a
                          key={index}
                          onClick={(e) => handleservice(e)}
                          className="apl-link"
                        >
                          {x}
                        </a>
                      )
                    })}
                  </>
                ) : null}
              </div>
              <div
                ref={filter3Ref}
                id="filter3"
                className="all-projects-list-items "
              >
                {filter3 ? (
                  <>
                    {filter3.map((x, index) => {
                      return (
                        <a
                          key={index}
                          onClick={(e) => handlegenre(e)}
                          className="apl-link"
                        >
                          {x}
                        </a>
                      )
                    })}
                  </>
                ) : null}
              </div>
            </div>
            {loader ? (
              <div className="col-12 mb-4">
                <Oldloader />
              </div>
            ) : totalPages > 0 ? (
              pageproject.map((project, index) => (
                <div
                  key={index}
                  ref={viewRef}
                  id="try"
                  className="col-12 col-lg-6"
                >
                  <div className={'projectlist-sec-3-item'}>
                    <div className="img">
                      <div
                        key={index}
                        onClick={(e) => viewproject(e, project.project_url)}
                        onMouseLeave={(e) => handlezoomoff(e, project.id)}
                        onMouseOver={(e) => handlezooming(e, project.id)}
                        className="view-project"
                      >
                        {data.project_static_content_topsection
                          ? data.project_static_content_topsection
                              .project_static_content_view
                          : 'View'}
                        <br />
                        {data.project_static_content_topsection
                          ? data.project_static_content_topsection
                              .project_static_content_project
                          : 'View'}
                      </div>
                      {project?.is_banner_image ? (
                        <img
                          className={`project-list-img${project.id}`}
                          src={project.banner_image}
                          alt="project-list-1"
                        />
                      ) : (
                        <video
                          controls={false}
                          className={`project-list-img${project.id} project-video-thumbnail`}
                        >
                          <source
                            src={`${project?.banner_image}#0.01`}
                            type="video/mp4"
                          />
                        </video>
                      )}
                    </div>
                    <div className="projectlist-s3-dels">
                      <h4>{project.title}</h4>
                      <h5>{project.released_year}</h5>
                    </div>
                    <p>{project.project_developer}</p>
                  </div>
                </div>
              ))
            ) : (
              <div style={{ textAlign: 'center' }}>{noProjectsMessage}</div>
            )}
          </div>
        </div>
      </section>
      {/* )} */}
      {/* Pagination start */}
      {totalPages > 0 ? (
        <div className="d-flex align-items-center justify-content-center pagination">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPaginationValueChange={onPaginationChange}
          />
        </div>
      ) : null}
    </>
  )
})

export default Projectlist_card
