const Rightbutton = (props) => {
  return (
    <>
      <button
        className={props.className}
        name=""
        onClick={(e) => (props.onClick ? props.onClick() : '')}
      >
        {/* <span className="animateText">{props.text}</span>
        <span className="animateText2">{props.text}</span> */}

        <span
          className="arrowcircle"
          onClick={(e) => (props.onClick ? props.onClick() : '')}
        >
          <span className="bg_fill"></span>
          <span className="bg_fill"></span>
          <svg
            width={16}
            height={11}
            viewBox="0 0 16 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
              fill="white"
            />
          </svg>
        </span>
      </button>
    </>
  )
}
export default Rightbutton
