import { useEffect, useState } from 'react'
import '../../styles/Privacy/Privacy.css'
import axiosInstance from '../../services/axiosInstance'
import Footer from '../../components/footer/footer'
import { useSelector } from 'react-redux'
import { devapiUrl } from '../../constants/devapi'
import parse from 'html-react-parser'
import { useDispatch } from 'react-redux'
import { changeLoaderState } from '../../actions/lngactions'
import { clientUrl, getLanguage } from '../../constants/urls'

let url = '',
  privacy,
  terms

const PrivacyAndTermsAndConditions = () => {
  let langcode = useSelector((state) => state.lang)
  const dispatch = useDispatch()
  const [pageData, setPageData] = useState('')

  useEffect(() => {
    dispatch(changeLoaderState(true))
    window.scrollTo({ top: 0, left: 0 })
    let headerUrl = window.location.href.split('/')
    url = headerUrl[headerUrl.length - 1]
    let language = getLanguage()
    privacy = clientUrl.privacy.split('/')[1]
    terms = clientUrl.terms.split('/')[1]
    if (url === privacy) {
      document.title = `Side - Privacy`
      getPageData(language, privacy)
    } else if (url === terms) {
      document.title = `Side - Terms`
      getPageData(language, `terms-and-condition`)
    }
  }, [langcode])

  const getPageData = (lang = 'en', apiUrl) => {
    axiosInstance
      .get(`${devapiUrl}${lang}/side-pages/${apiUrl}`)
      .then((res) => {
        if (url === privacy) {
          setPageData(res.data.privacy)
        } else if (url === terms) {
          setPageData(res.data.terms_and_condition)
        }
        dispatch(changeLoaderState(false))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      <div className="privacyPage">
        <span className="leftshadow"></span>
        <div className="container privacyContainer">
          <div className="innerwidth">
            <div className="verticalLine left"></div>
            <div className="verticalLine right"></div>

            <h3 className="headingTag">
              {pageData
                ? url === privacy
                  ? pageData?.privacy_page_heading
                  : url === terms
                    ? pageData?.terms_page_title
                    : ''
                : ''}
            </h3>
            {pageData
              ? url === privacy
                ? parse(pageData.privacy_policy_description)
                : url === terms
                  ? parse(pageData.terms_page_description)
                  : ''
              : ''}
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default PrivacyAndTermsAndConditions
