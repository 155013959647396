import { Link } from 'react-router-dom'
import '../../styles/news/newsdescription.css'
import TestomonialAndContact from '../homepage/section8'
import Footer from '../../components/footer/footer'
import '../homepage/section8'
import { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../services/axiosInstance'
import anime from 'animejs'
import parse from 'html-react-parser'
import { getDateFormat } from '../../constants/dateconvert'
import { useDispatch, useSelector } from 'react-redux'
import { changeLoaderState } from '../../actions/lngactions'
import { devapiUrl } from '../../constants/devapi'
import Notfound from '../NotFound/Notfound'
import { getLanguage } from '../../constants/urls'

let language = getLanguage()

const socialMediaPlatforms = {
  facebook: 'FACEBOOK',
  x: 'TWITTER',
  linkedin: 'LINKEDIN',
}

const NewsDescription = () => {
  const lang = useSelector((state) => state.lang)
  let bool = false
  const dispatch = useDispatch()
  const [newsData, setNewsData] = useState([])
  const [isNotFound, setIsNotFound] = useState(false)
  const imgRef = useRef(null)
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
    dispatch(changeLoaderState(true))
    let newsTitle = 'Side - ' + getUrl()
    document.title = newsTitle
  }, [])

  useEffect(() => {
    dispatch(changeLoaderState(true))
    language = getLanguage()
    getNewsDescriptionData(getUrl(), language)
  }, [lang])

  const getUrl = () => {
    let param = window.location.href.split('/')
    let url = param[param.length - 1]
    // console.log({ param })
    return url
  }
  const scalinganimation = () => {
    anime({
      targets: '.news-main-img',
      translateX: [0, 0],
      scale: [0, 1],
      // delay: anime.stagger(30),
      duration: 2000,
      easing: 'easeOutQuart',
    })
  }
  const getNewsDescriptionData = (url, lang = 'en') => {
    axiosInstance
      .get(`${devapiUrl}${lang}/news/${url}`)
      .then((res) => {
        // console.log('Responce from server, NEWS Details : ', res)
        setIsNotFound(false)
        setNewsData(res.data)
        setTimeout(() => {
          dispatch(changeLoaderState(false))
          if (bool === false) scalinganimation()
          bool = true
        }, 1500)
      })
      .catch((err) => {
        console.error(
          'There is a Error while Fetching Data, Please try again in sometime.!',
        )
        if (err?.response?.status === 404) {
          setIsNotFound(true)
        }

        setTimeout(() => {
          dispatch(changeLoaderState(false))
        }, 1500)
      })
  }
  if (isNotFound) {
    return <Notfound />
  }
  const configureShareUrlBasedOnSocialMedia = (topic) => {
    let url = window.location.href
    let generatedUrl = ``
    if (topic === socialMediaPlatforms.facebook) {
      generatedUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
    } else if (topic === socialMediaPlatforms.x) {
      generatedUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent('News Description')}`
    } else if (topic === socialMediaPlatforms.linkedin) {
      generatedUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`
    }
    return generatedUrl
  }

  return (
    <>
      <div className="news_desc_page">
        <div className="container">
          <span className="leftshadow"></span>
          <div className="news-desc-section-1">
            <div className="news-desc-heading">
              <h1>{newsData.title ? newsData.title : ''}</h1>
            </div>
          </div>

          <div className="news-desc-section-2 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="news-desc-date me-5">
                <h4>
                  {newsData.news_date_title ? newsData.news_date_title : 'Date'}
                </h4>
                <p>
                  {getDateFormat(
                    newsData.published_date
                      ? newsData.published_date
                      : new Date(),
                    'DD.MM.YYYY',
                  )}
                </p>
              </div>
            </div>
            <div className="news-desc-social-icon">
              {newsData?.news_share_in_social_media_links
                ? newsData?.news_share_in_social_media_links?.map(
                    (data, index) => {
                      return (
                        <a
                          key={index}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={configureShareUrlBasedOnSocialMedia(
                            data?.news_social_media,
                          )}
                        >
                          <img
                            style={{ height: '20px', width: '20px' }}
                            className="icon"
                            src={data?.news_social_media_icon}
                            alt="icon"
                          />
                        </a>
                      )
                    },
                  )
                : ''}
            </div>
          </div>

          <div className="news-desc-sect-3">
            <div className="imgorvid">
              <div className="top_border border_lines"></div>
              <div className="right_border border_lines"></div>
              <div className="bottom_border border_lines"></div>
              <div className="left_border border_lines"></div>
              <div className="news-main-img">
                <img
                  ref={imgRef.current}
                  src={
                    newsData.news_images
                      ? newsData.news_images.news_image_1300x530
                        ? newsData.news_images.news_image_1300x530
                        : ''
                      : ''
                  }
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="news-desc-sec-4">
            <div className="right_border border_lines"></div>
            {parse(
              newsData.news_description_1 ? newsData.news_description_1 : '',
            )}

            {parse(
              newsData.news_description_2 ? newsData.news_description_2 : '',
            )}
            {newsData?.news_images?.news_video !== null ? (
              <div className="desc_imgorvideo">
                <video src={newsData?.news_images?.news_video} controls />
              </div>
            ) : null}

            <div
              className="desc_imgorvideo"
              style={{
                display:
                  newsData?.news_images?.news_video_url === null ? 'none' : ' ',
              }}
            >
              {newsData?.news_images?.news_video_url
                ? parse(newsData?.news_images?.news_video_url)
                : null}
            </div>
            <div className="backtonews_desk d-lg-flex d-none">
              <div className="back-to-news">
                <span
                  style={{ transform: 'translateY(2px)' }}
                  className="dot me-2"
                ></span>
                <span>
                  {newsData.news_back_to_news
                    ? newsData.news_back_to_news
                    : 'BACK TO NEWS'}
                </span>
              </div>
              <div className="circle-arrow">
                <Link
                  to={
                    language === 'en'
                      ? `/audio/news`
                      : `/${language}/audio/news`
                  }
                >
                  <button className="dot-1"></button>
                </Link>
              </div>
            </div>
            <div className="news-desc-sec-4-quote">
              {newsData.news_testimonial
                ? newsData.news_testimonial.map((_, i) => {
                    return (
                      <p key={i} className="content">
                        <br />
                        <br />
                        {parse(_.news_testimonial_dsecription)}
                        <p className="tagline">
                          <span />
                          {_.news_testimonial_name},{' '}
                          {_.news_testimonial_company}
                        </p>
                      </p>
                    )
                  })
                : ''}
            </div>
          </div>
          <div className="news-desc-sec-5 d-lg-none d-flex flex-column align-items-center justify-content-center">
            <div className="back-to-news">
              <span className="dot"></span>
              &nbsp;
              <span>
                {newsData.news_back_to_news
                  ? newsData.news_back_to_news
                  : 'BACK TO NEWS'}
              </span>
            </div>
            <div className="circle-arrow mt-4">
              <Link
                to={
                  language === 'en' ? `/audio/news` : `/${language}/audio/news`
                }
              >
                <div className="dot-1"> </div>
              </Link>
            </div>
          </div>
        </div>
        <TestomonialAndContact />
        <Footer />
      </div>
    </>
  )
}

export default NewsDescription
