import { useEffect } from 'react'
import Section1 from '../homepage/section1'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../services/axiosInstance'
import { devapiUrl } from '../../constants/devapi'
import { homedata } from '../../actions/lngactions'
import { getLanguage } from '../../constants/urls'

const MainHome = ({ projectRef }) => {
  const lang = useSelector((state) => state.lang)
  const dispatch = useDispatch()
  useEffect((e) => {
    window.scrollTo({ top: 0, left: 0 })
    document.title = 'SIDE - Home'
    AOS.init()
  }, [])

  useEffect(() => {
    getHomedata(getLanguage())
  }, [lang])

  const getHomedata = (lang) => {
    axiosInstance
      .get(`${devapiUrl}${lang}/side-pages/home`, {})
      .then((res) => {
        dispatch(homedata(res.data.home))
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div className="home_section staticScroll" id="home-section">
      <Section1 projectRef={projectRef} />
    </div>
  )
}

export default MainHome
