import React, { useEffect, useRef, useState } from 'react'
import SplitType from 'split-type'
import anime from 'animejs'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axiosInstance from '../../services/axiosInstance'
import { devapiUrl } from '../../constants/devapi'
import { getLanguage } from '../../constants/urls'

const ProjectList = (props) => {
  const projectdata = useSelector((state) => state)
  const navigate = useNavigate()
  const nameRef = useRef(null)
  const imgRef = useRef(null)
  const titleRef = useRef(null)
  const featureRef = useRef(null)
  const viewRef = useRef(null)
  const otherimgRef = useRef(null)
  const serviceRef = useRef(null)
  const iscardClicked = useRef(false)
  const [projects, Setprojects] = useState([])
  const data = props.data || []
  const view = useRef('')

  // const projects = props.featuredproject || [];
  const [idx, setIdx] = useState(0)
  const [selectedProject, setSelectedProject] = useState(projects[idx])
  const [space, setspace] = useState(window.innerWidth <= 991 ? true : false)
  let langcode = projectdata.lang
  useEffect(() => {
    featured_api()
  }, [langcode])

  let featureanim = false
  let featureanim2 = false
  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.scrollY >= 100 && window.scrollY <= 1000) {
        if (featureanim2 === false) {
          anime.timeline({ loop: false }).add({
            targets: '.feature_sideline2',
            translateY: [500, 200],
            easing: 'easeOutExpo',
            duration: 7400,
            delay: 100,
          })
        }
        featureanim2 = true
      } else {
        featureanim2 = false
      }

      if (window.scrollY >= 350 && window.scrollY <= 1300) {
        if (featureanim === false) {
          anime.timeline({ loop: false }).add({
            targets: '.feature_sideline',
            translateY: [700, 100],
            easing: 'easeOutExpo',
            duration: 7400,
            delay: 100,
          })
        }
        featureanim = true
      } else {
        featureanim = false
      }
    })
  }, [])
  useEffect(() => {
    // console.log('data is ', data)
    if (projects.length > 0) {
      setIdx(0)
      setSelectedProject(projects[0])
    }
    // console.log(projects);
    window.addEventListener('resize', () => {
      let width = window.innerWidth
      // console.log(width)
      if (width <= 991) {
        setspace(true)
      } else {
        setspace(false)
      }
    })
  }, [projects])

  useEffect(() => {
    if (!selectedProject?.title) return
    const ourText1 = new SplitType(featureRef.current, { types: 'chars' })
    // console.log('outText1', ourText1)
    const ourText2 = new SplitType(titleRef.current, {
      types: 'chars,words,lines',
    })
    const ourText3 = new SplitType(serviceRef.current, { types: 'chars' })
    const ourText4 = new SplitType(viewRef.current, { types: 'chars' })
    const chars1 = ourText1.chars
    const chars2 = ourText2.chars
    const chars3 = ourText3.chars
    const chars4 = ourText4.chars

    anime({
      targets: chars1,
      translateY: [45, 0],
      easing: 'linear',
      duration: 1000,
      //stagger:anime.stagger(50),
      delay: (el, i) => 50 * i,
      easing: 'easeOutQuart',
    })
    anime({
      targets: chars2,
      translateY: [20, -0],
      opacity: [0, 1],
      // delay: anime.stagger(40),
      delay: (el, i) => 50 * i,
      duration: 1000,
      easing: 'easeOutQuart',
    })
    // anime({
    //     targets: chars2,
    //     translateY: [45, 0],
    //     easing:'linear',
    //     duration: 1000,
    //     //stagger:anime.stagger(50),
    //     delay: (el, i) => 50 * i,
    //     easing: 'easeOutQuart',
    // });
    anime({
      targets: chars3,
      translateY: [45, 0],
      easing: 'linear',
      duration: 1000,
      //stagger:anime.stagger(50),
      delay: (el, i) => 50 * i,
      easing: 'easeOutQuart',
    })
    anime({
      targets: chars4,
      translateY: [45, 0],
      easing: 'linear',
      duration: 1000,
      //stagger:anime.stagger(50),
      delay: (el, i) => 50 * i,
      easing: 'easeOutQuart',
    })
    anime({
      targets: imgRef.current,
      translateX: [0, 0],
      scale: [0.5, 1],
      // delay: anime.stagger(30),
      duration: 2000,
      easing: 'easeOutQuart',
    })
    anime({
      targets: otherimgRef.current,
      translateX: [100, -0],
      opacity: [0, 0.2],
      delay: anime.stagger(30),
      duration: 500,
      easing: 'easeOutQuart',
    })

    // setTimeout(() => {
    //   SplitType.revert('.project-name')
    // }, 2500)

    // console.log('seleted projects',selectedProject)
    return () => SplitType.revert('.project-name')
  }, [selectedProject?.title])

  const handleNextProjectChange = () => {
    if (projects.length) {
      setIdx((idx) => {
        if (idx === projects.length - 1) {
          setSelectedProject(projects[0])
          return 0
        } else {
          setSelectedProject(projects[idx + 1])
          return idx + 1
        }
      })
    }
  }

  const handlePrevProjectChange = () => {
    if (projects.length) {
      setIdx((idx) => {
        if (idx === 0) {
          setSelectedProject(projects[projects.length - 1])
          return projects.length - 1
        } else {
          setSelectedProject(projects[idx - 1])
          return idx - 1
        }
      })
    }
  }

  const viewproject = (e, url) => {
    iscardClicked.current = true
    localStorage.setItem('isCardClicked', iscardClicked.current)
    e.preventDefault()
    let navigatingUrl =
      getLanguage() === 'en'
        ? `/audio/projects/${url}`
        : `/${getLanguage()}/audio/projects/${url}`
    navigate(navigatingUrl)
  }

  //aryan function

  const slide_to_idx = (i, index) => {
    const temp = [...projects.keys()].filter(
      (k) => projects[k].title === i.title,
    )

    setSelectedProject(projects[parseInt(temp)])
    setIdx(parseInt(temp))
  }
  const filteredProjects = []
  var i = 0
  if (idx === projects.length - 1) {
    i = 0
  } else {
    i = idx + 1
  }
  for (let count = 0; count < 3; count++) {
    if (i === projects.length) i = 0
    filteredProjects.push(projects[i])
    // console.log('i',i);
    i = i + 1
  }

  // const filteredProjects = projects.filter(i => i?.title !== selectedProject?.title);

  // console.log("filteredProjects" , filteredProjects);

  const featured_api = (langcode = 'en') => {
    axiosInstance
      .get(`${devapiUrl}${langcode}/featuredproject`)
      .then((res) => {
        //console.log(res.data)
        // console.log(res.data)
        Setprojects(res.data.slice(0, 4))
        view.current = data.project_static_content_topsection
        // setfeaturedproject(res.data.slice(0, 4));
        //setdata(res.data.slice(0,4))
      })
      .catch((err) => {
        console.log(err)
      })
    // console.log(data)
  }

  return (
    <section className="projectlist-sec-2 m-0" key={idx}>
      <div className="banner_topborder border_lines"></div>
      <div className="banner_bottomborder border_lines"></div>
      {/* <div className="banner_bottomleft_border border_lines"></div> */}
      <div className="container-fluid">
        <div className="row align-items-center row_reverse_lg">
          <div className="col-12 col-lg-6 position-relative">
            <div className="feature_sideline">
              <div className="sidelinecontent-2">
                <span className="filldot-1"></span>
                <p>
                  01{' '}
                  {data.project_static_content_topsection
                    ? data.project_static_content_topsection
                        .project_static_content_featured_projects
                    : 'FEATURED PROJECTS'}
                </p>
                <span className="line-1"></span>
                <span className="outlinedot-1"></span>
                {/* <span className="outlinedot-1"></span>  */}
              </div>
            </div>
            <div className="banner_right_border border_lines"></div>

            <div
              style={{ boxSizing: 'border-box' }}
              className="projectlist-sec-2-subsec-1"
              ref={nameRef}
            >
              <div ref={featureRef} className="feature project-animation-wrap">
                <p>
                  {data.project_static_content_topsection
                    ? data.project_static_content_topsection
                        .project_static_content_featured_projects
                    : 'FEATURED PROJECTS'}
                </p>
              </div>

              <h4
                ref={titleRef}
                className="project-name project-animation-wrap"
              >
                {selectedProject?.title}
                {/* <span
                  style={{ whiteSpace: 'nowrap' }}
                  className="d-block project-animation-wrap"
                >
                  {selectedProject?.publisher}
                </span> */}
              </h4>

              <div ref={serviceRef} className="projectlist-2-unstyled">
                <span className="line"></span>
                {(selectedProject?.services || []).map((service, index) => {
                  return (
                    <span
                      key={index}
                      className="d-block project-animation-wrap"
                    >
                      {service}
                    </span>
                  )
                })}
              </div>

              <a
                ref={viewRef}
                href="#projectlist"
                style={{ display: 'inline-block' }}
                className="view-all project-animation-wrap"
              >
                {data.project_static_content_topsection
                  ? data.project_static_content_topsection
                      .projets_static_content_view_all_project_button
                  : ''}
                <img
                  className="icon"
                  src="/audio/temp_img/bottom-arrow.svg"
                  alt="bottom-arrow"
                />
              </a>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="d-flex flex-column justify-content-end">
              <div
                className="d-flex align-items-center justify-content-between project_slider"
                style={{ position: 'relative' }}
              >
                <div className="projectlist-sec-2-subsec-2">
                  <img
                    src={selectedProject?.featured_image}
                    ref={imgRef}
                    alt={selectedProject?.title}
                  />

                  {selectedProject?.project_url && (
                    <div
                      onClick={(e) =>
                        viewproject(e, selectedProject?.project_url)
                      }
                      className="view-project"
                    >
                      <p>
                        {data.project_static_content_topsection
                          ? data.project_static_content_topsection
                              .project_static_content_view
                          : 'view'}
                        <br />
                        {space === true ? <span> </span> : ''}
                        {data.project_static_content_topsection
                          ? data.project_static_content_topsection
                              .project_static_content_project
                          : 'project'}
                      </p>
                    </div>
                  )}
                </div>
                <>
                  {/* <div className="d-flex align-items-center" style={{ width: '400px' }}> */}
                  {filteredProjects.map((i, idx) => {
                    return (
                      <div
                        onClick={() => {
                          slide_to_idx(i, idx)
                        }}
                        className="projectlist-sec-2-child"
                        ref={otherimgRef}
                        key={idx}
                      >
                        <img
                          src={i?.banner_image || '/temp_img/spiderman.svg'}
                          alt={i?.title || 'spiderman'}
                        />
                      </div>
                    )
                    // return (
                    //     <div onClick={()=>{slide_to_idx(i)}}className="projectlist-sec-2-child" ref={otherimgRef} style={{ opacity: 0.2, width: '65px', height: (idx % 2 === 0 ? '33.125rem' : '26rem'), margin: '0 30px' }}>
                    //         <img src={i?.banner_image || "/temp_img/spiderman.svg"} style={{ filter: 'grayscale(100%)', objectFit: 'cover', width: '100%', height: '100%' }} alt={i?.title || "spiderman"} key={idx} />
                    //     </div>
                    // )
                  })}
                  {/* </div> */}
                </>
                <div className="side-arrow">
                  <div
                    className="side-left arrow-circle"
                    onClick={() => handlePrevProjectChange()}
                  >
                    <span className="bg_fill"></span>
                    <span className="bg_fill"></span>
                    <img src="/audio/temp_img/left-arrow.svg" alt="side"></img>
                  </div>

                  {/* <p style={{ margin: '2rem 3rem 2rem 1.5rem' }}> */}
                  <p>{idx < 10 ? `0${idx + 1}` : idx + 1}</p>

                  <div
                    className="side-right arrow-circle"
                    onClick={() => handleNextProjectChange()}
                  >
                    <span className="bg_fill"></span>
                    <span className="bg_fill"></span>
                    <img src="/audio/temp_img/Union.svg" alt="side" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ProjectList
