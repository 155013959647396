import { Routes, Route } from 'react-router-dom'
import ProjectDescription from './components/projects/projectdescription'
import MainHome from './components/main/main'
import Navbar from './components/navbar/navbar'
import About from './components/about/about'
import Services from './components/services/services'
import Teams from './components/Teams/Teams'
import Projects from './components/projects/projects'
import { clientUrl } from './constants/urls'
import News from './components/news/news'
import NewsDescription from './components/news/newsdescription'
import Contactus from './components/contactus/contactus'
import Location from './components/location/location'
import { Provider } from 'react-redux'
import store from './store'
import { useRef, useState, useEffect } from 'react'
import Notfound from './components/NotFound/Notfound'
import CookieConsent from 'react-cookie-consent'
import PrivacyAndTermsAndConditions from './components/Privacy/Privacy'
import NetworkErrorPage from './components/NetworkError/NetworkErrorPage'
import Footer from './components/footer/footer'
const App = () => {
  const projectRef = useRef(0)

  const [isOnline, setIsOnline] = useState(true)

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine)
    }

    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [])
  if (!isOnline) {
    return (
      <>
        <Provider store={store}>
          <Navbar />
          <NetworkErrorPage />
          <Footer />
        </Provider>
      </>
    )
  }
  return (
    <>
      <Provider store={store}>
        <CookieConsent
          buttonStyle={{
            color: '#FFFFFF',
            backgroundColor: '#94db16',
            fontSize: '1rem',
            fontFamily: 'IBMPlexMono-Regular',
            fontWeight: '600',
          }}
          style={{ fontSize: '1rem', fontFamily: 'IBMPlexMono-Regular' }}
          location="bottom"
          buttonText="Got it"
          declineButtonText="Decline"
          cookieName="myAppCookieConsent"
          containerClasses="cookie-consent-container"
          buttonClasses="cookie-accept-button"
          declineButtonClasses="cookie-decline-button"
          contentClasses="cookie-content"
          expires={150}
        >
          Our website uses cookies to enhance your experience. To find out what
          cookies we use and why, click the following link:{' '}
          <a
            target="_blank"
            href="https://sideglobal.com/cookiespopup/sidewebsiteprivacypolicy2018.pdf"
            style={{ color: '#94db16', textDecoration: 'none' }}
          >
            More info
          </a>
        </CookieConsent>
        <Navbar />
        <Routes>
          <Route
            exact
            path={clientUrl.preLoader}
            element={<MainHome projectRef={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.home}
            element={<MainHome projectRef={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.about}
            element={<About ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.aboutWithLang}
            element={<About ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.contactus}
            element={<Contactus ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.contactusWithLang}
            element={<Contactus ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.locationWithLang}
            element={<Contactus ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.location}
            element={<Contactus ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.services}
            element={<Services ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.servicesWithLang}
            element={<Services ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.servicesSubservices}
            element={<Services ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.servicesSubservicesWithLang}
            element={<Services ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.servicesSuperSubservicesWithLang}
            element={<Services ref={projectRef} />}
          />

          <Route
            exact
            path={clientUrl.servicesDescriptionWithLang}
            element={<Services ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.servicesDescription}
            element={<Services ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.projects}
            element={<Projects ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.projectsWithLang}
            element={<Projects ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.projectsDescriptionWithLang}
            element={<ProjectDescription ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.projectsDescription}
            element={<ProjectDescription ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.news}
            element={<News ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.newsWithLang}
            element={<News ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.newsDescription}
            element={<NewsDescription />}
          />
          <Route
            exact
            path={clientUrl.newsDescriptionWithLang}
            element={<NewsDescription />}
          />
          <Route
            exact
            path={clientUrl.location}
            element={<Location ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.locationWithLang}
            element={<Location ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.teams}
            element={<Teams ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.teamsdetail}
            element={<Teams ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.teamsWithLang}
            element={<Teams ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.privacy}
            element={<PrivacyAndTermsAndConditions ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.privacyWithLang}
            element={<PrivacyAndTermsAndConditions ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.terms}
            element={<PrivacyAndTermsAndConditions ref={projectRef} />}
          />
          <Route
            exact
            path={clientUrl.termsWithLang}
            element={<PrivacyAndTermsAndConditions ref={projectRef} />}
          />
          <Route exact path="*" element={<Notfound />} />
        </Routes>
      </Provider>
    </>
  )
}

export default App
