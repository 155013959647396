import Button from '../Buttons/Button'
import AudioPortal from './audio/audio'
import { useEffect, useRef } from 'react'
import anime from 'animejs'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clientUrl } from '../../constants/urls'
const Section5 = () => {
  const home = useSelector((state) => state.homedata)
  const lang = useSelector((state) => state.lang)
  const sec5ref = useRef(null)
  const sidelineplay = useRef(null)
  const tryRef = useRef(null)
  const navigate = useNavigate()
  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      //-----------------Location card Parallax animation----------//
      var dom = document.getElementById('locationanimclip')
      var locationanimclip =
        dom !== null ? dom.getElementsByTagName('div') : null
      var windowYScroll = window.scrollY

      if (locationanimclip !== null) {
        if (
          (windowYScroll > 2000 && windowYScroll < 2500) ||
          (windowYScroll > 3000 && windowYScroll < 3500)
        ) {
          // console.log({ locationanimclip })
          for (let i = 0; i < locationanimclip.length; i++) {
            let elem = locationanimclip[i]
            if (i === 0) {
              elem.children[0].style.height = '25%'
              elem.children[1].style.height = '15%'
            } else if (i === 1) {
              elem.children[0].style.height = '20%'
              elem.children[1].style.height = '8%'
            } else if (i === 2) {
              elem.children[0].style.height = '25%'
              elem.children[1].style.height = '5%'
            } else if (i === 3) {
              elem.children[0].style.height = '20%'
              elem.children[1].style.height = '12%'
            } else if (i === 4) {
              elem.children[0].style.height = '30%'
              elem.children[1].style.height = '8%'
            }
          }
        }

        if (
          (windowYScroll > 2500 && windowYScroll < 3000) ||
          (windowYScroll > 3500 && windowYScroll < 4000)
        ) {
          //   console.log("asdfsdf", windowYScroll)  ;
          for (let i = 0; i < locationanimclip.length; i++) {
            let elem = locationanimclip[i]
            if (i === 0) {
              elem.children[0].style.height = '20%'
              elem.children[1].style.height = '20%'
            } else if (i === 1) {
              elem.children[0].style.height = '30%'
              elem.children[1].style.height = '2%'
            } else if (i === 2) {
              elem.children[0].style.height = '22%'
              elem.children[1].style.height = '11%'
            } else if (i === 3) {
              elem.children[0].style.height = '14%'
              elem.children[1].style.height = '22%'
            } else if (i === 4) {
              elem.children[0].style.height = '35%'
              elem.children[1].style.height = '0%'
            }
          }
        }
      }
      //----------------------------codes ends here for location card sec5----------//
      e.preventDefault()
    })
  })

  useEffect(() => {
    var sideline = anime.timeline({ autoplay: false, loop: false }).add({
      targets: '.sec-5-slideup',
      translateY: [600, 0],
      easing: 'easeOutExpo',
      duration: 5400,
      delay: 100,
    })
    var first = anime.timeline({ autoplay: false, loop: false })
    first
      .add({
        targets: '.sec_line_5',
        translateY: 0,
        duration: 0,
      })
      .add({
        targets: '.sec_line_5',
        translateY: [200, 0],
        easing: 'easeOutExpo',
        duration: 1000,
        delay: 0,
      })
    let observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          // console.log('entry ratio ', entry.intersectionRatio)
          if (entry.intersectionRatio === 1) {
            sideline.play()
            first.play()
          }
        })
      },
      { threshold: 1 },
    )

    window.addEventListener('scroll', (e) => {
      if (tryRef.current != null) observer.observe(tryRef.current)
      e.preventDefault()
    })
  })
  const navigateToLocationUs = () => {
    navigate(lang === 'en' ? clientUrl.about : `/${lang}/about`)
  }
  return (
    <div className="section5 oneScroll" id="scrollContainer">
      <div className="sec-5-slideup slideup">
        <div className="sidelinecontent">
          <span className="outlinedot"></span>
          <span className="outlinedot"></span>
          <span className="filldot"></span>
          <p>
            03 {home ? home.home_section3.home_section3_locations : `Locations`}
          </p>
          <span className="line"></span>
          <span className="outlinedot"></span>
        </div>
      </div>
      <div className="section5width">
        <div className="inline_progress">
          <div className="sec_head_5">
            <h1 className="sec_line_5">
              {home ? home.home_section3.home_section3_many : `Many`}
            </h1>
          </div>
          <div className="sec_head_5">
            <h1 className="sec_line_5">
              {home ? home.home_section3.home_section3_locations : `Locations`}{' '}
              <span />
            </h1>
          </div>
          <div className="desk_progress">
            <AudioPortal
              animation={false}
              audio={home?.home_section3?.home_section3_audio}
            />
          </div>
        </div>
        <div className="sidecontent">
          <div ref={sec5ref} className="leftcontent">
            <div className="locationAnim_wrapper">
              <div className="locationAnim">
                <img
                  src={
                    home
                      ? home.home_section3.home_section3_image
                      : '/img/many-locations.jpg'
                  }
                  alt=""
                />
                <div className="overlay" id="locationanimclip">
                  <div className="block trail-block1">
                    <span></span>
                    <span></span>
                  </div>
                  <div className="block trail-block2">
                    <span></span>
                    <span></span>
                  </div>
                  <div className="block trail-block3">
                    <span></span>
                    <span></span>
                  </div>
                  <div className="block trail-block4">
                    <span></span>
                    <span></span>
                  </div>
                  <div className="block trail-block5">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rightcontent">
            <div ref={tryRef} className="title_wrap">
              <AudioPortal
                animation={false}
                audio={home?.home_section3?.home_section3_audio}
              />
              <div className="sec_head_5">
                <h5 className="sec_line_5">
                  {home ? home.home_section3.home_section3_one_great : ''}{' '}
                </h5>
              </div>
              <div className="sec_head_5">
                <h5 className="sec_line_5">
                  {' '}
                  <span>
                    {' '}
                    {home ? home.home_section3.home_section3_tilent_pool : ''}
                  </span>
                </h5>
              </div>
            </div>
            <div className="content_width">
              <p ref={sidelineplay} className="content">
                {home
                  ? home.home_section3.home_section3_description
                  : `With state-of-the-art facilities in London, Paris, Montreal,
                Shanghai, and Los Angeles, plus a global network of partner
                studios across Europe, Asia, and Latin America, SIDE delivers the
                highest-quality game voiceover recording in the location that best
                suits your needs.`}
              </p>
              <div className="custombtn desk_displaybtn">
                <Button
                  onClick={() => {
                    navigateToLocationUs()
                  }}
                  text={
                    home
                      ? home.home_section3.home_section3_see_our_location
                      : 'see our locations'
                  }
                />
              </div>
              <div className="custombtn mbl_displaybtn">
                <Button
                  onClick={() => {
                    navigateToLocationUs()
                  }}
                  text={
                    home
                      ? home.home_section3.home_section3_our_locations
                      : 'our locations'
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section5
