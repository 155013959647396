import { NavLink } from 'react-router-dom'
import { clientUrl, getLanguage } from '../../constants/urls'
import { useDispatch, useSelector } from 'react-redux'
import { changeLanguage } from '../../actions/lngactions'
import { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../services/axiosInstance'
import { devapiUrl } from '../../constants/devapi'
import Oldloader from '../../constants/oldloader/oldloader'
const langCode = ['en', 'fr-ca', 'zh-chs', 'zh-cht', 'ja', 'ko']
const langCode1 = ['zh-chs', 'zh-cht', 'ja', 'ko', 'fr-ca']

const lan = [
  {
    value: 'en',
    name: 'English',
    displayName: 'EN',
    languageName: 'English',
    dropdownName: 'EN',
    browserLanguage: 'en',
  },
  {
    value: 'fr-ca',
    name: 'Canadian French',
    displayName: 'FR',
    languageName: '(Français)',
    dropdownName: 'fr-ca',
    browserLanguage: 'fr-ca',
  },
  {
    value: 'ja',
    name: 'Japanese',
    displayName: 'JA',
    languageName: '日本語',
    dropdownName: '日本語',
    browserLanguage: 'ja',
  },
  {
    value: 'ko',
    name: 'Korean',
    displayName: 'KOK',
    languageName: '한국어',
    dropdownName: '한국어',
    browserLanguage: 'ko',
  },
  {
    value: 'zh-chs',
    name: 'Simplified Chinese',
    displayName: 'ZH-HANS',
    languageName: '简体中文',
    dropdownName: '简体中文',
    browserLanguage: 'zh-CN',
  },
  {
    value: 'zh-cht',
    name: 'Traditional Chinese',
    displayName: 'ZH-HANT',
    languageName: '繁體中文',
    dropdownName: '繁體中文',
    browserLanguage: 'zh-TW',
  },
]

const defaultLang = [
  {
    lang: 'en' || '',
    menu_about: 'ABOUT',
    menu_services: 'SERVICES',
    menu_projects: 'PROJECTS ',
    menu_news: 'NEWS',
    menu_contact: 'CONTACT',
  },
  {
    lang: 'zh-cht',
    menu_about: '關於我們',
    menu_services: '服務',
    menu_projects: '專案',
    menu_news: '新聞',
    menu_contact: '聯絡我們',
  },
  {
    lang: 'zh-chs',
    menu_about: '关于',
    menu_services: '服务',
    menu_projects: '项目',
    menu_news: '新闻',
    menu_contact: '联系方式',
  },
  {
    lang: 'ja',
    menu_about: '当社について',
    menu_services: 'サービス',
    menu_projects: 'プロジェクト',
    menu_news: 'ニュース',
    menu_contact: '連絡先',
  },
  {
    lang: 'ko',
    menu_about: 'SIDE에 대하여',
    menu_services: '서비스',
    menu_projects: '프로젝트',
    menu_news: '뉴스',
    menu_contact: '연락',
  },
  {
    lang: 'fr-ca',
    menu_about: 'À propos',
    menu_contact: 'Contact',
    menu_news: 'Actualités',
    menu_projects: 'Projets',
    menu_services: 'Services',
  },
]
function getDescription(langCode) {
  const langObj = defaultLang.find((item) => item.lang === langCode)
  return langObj ? langObj : []
}
const Navbar = () => {
  const staticData = getDescription(getLanguage())
  const loadingState = useSelector((state) => state.loadingState)
  const dispatch = useDispatch()
  const [selectedValue, setValue] = useState('en')
  const [value1, setvalue1] = useState('')
  const [servicemenu, setServiceMenu] = useState([])
  const [active, setActive] = useState(false)
  const [headerdata, setHeaderData] = useState(false)
  const [languageDropDown, setLanguageDropDown] = useState('EN')
  const [displayMenu, setDisplayMenu] = useState(false)
  const [servicesMenu, setServicesMenu] = useState(false)
  const location = useLocation()
  const [footerData, setFooterData] = useState([])
  const mobilemenuRef = useRef(null)
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [webservice, Setwebservice] = useState(false)
  const dropdownRef = useRef(null)
  const webservicedropdownRef = useRef(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 990)

  var root = document.getElementById('root')
  useEffect(() => {
    setValue(getLang())
    setvalue1(getLang1())

    root.className = getLang()
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])
  useEffect(() => {
    settingBrowserLanguage()
    const handleLanguageChange = () => {
      settingBrowserLanguage()
      getnavbardata(getLanguage())
    }

    window.addEventListener('languagechange', handleLanguageChange)
    return () => {
      window.removeEventListener('languagechange', handleLanguageChange)
    }
  }, [])

  useEffect(() => {
    handleloading()
    const languageValue = getLanguage()
    const selectedLanguage = lan.find((lang) => lang.value === languageValue)
    getnavbardata(getLanguage())
    if (selectedLanguage) {
      setLanguageDropDown(selectedLanguage.dropdownName)
      setValue(selectedLanguage.value)
      dispatch(changeLanguage(selectedLanguage.value))
      if (selectedLanguage.value === 'en') setvalue1('')
      else setvalue1(selectedLanguage.value)
    }
    let link = document.querySelector('.about_link')
    let mbl_link = document.querySelector('.mbl_about_link')
    if (
      location.pathname.includes('/team') ||
      location.pathname.includes('/about')
    ) {
      if (link != null) {
        link.classList.add('active')
      }
      if (mbl_link != null) {
        mbl_link.classList.add('active')
      }
      // your Code
    } else {
      if (link != null) {
        link.classList.remove('active')
      }
      if (mbl_link != null) {
        mbl_link.classList.remove('active')
      }
    }
  }, [location])

  const settingBrowserLanguage = () => {
    let url = window.location.href.split('/')
    let lang = url[3] ? url[3] : ''
    let index = lan.findIndex((obj) => obj.value === lang)
    if (index !== -1 && lang.length > 1) {
      onlanguagechange(lan[index].value, lan[index].dropdownName)
    } else if (checkingBrowserLanguage().bool) {
      onlanguagechange(
        checkingBrowserLanguage().value,
        checkingBrowserLanguage().dropdownName,
      )
    } else if (index === -1 && lang === '') {
      onlanguagechange(lan[0].value, lan[0].dropdownName)
    } else {
      onlanguagechange(lan[0].value, lan[0].dropdownName)
    }
  }

  const checkingBrowserLanguage = () => {
    let obj = {}
    for (let i = 0; i < lan.length; i++) {
      if (lan[i].browserLanguage === navigator.language) {
        obj = lan[i]
        obj.bool = true
      }
    }
    return obj
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActive(false)
    }
    if (
      webservicedropdownRef.current &&
      !webservicedropdownRef.current.contains(event.target)
    )
      Setwebservice(false)
  }

  const getnavbardata = (lang) => {
    axiosInstance
      .get(`${devapiUrl}${lang}/side-pages/header`)
      .then((res) => {
        setHeaderData(res.data.header)
        setServiceMenu(res.data.header.services_menu)
        setFooterData(res.data.header.footer_social_media_links)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const onlanguagechange = (value, name) => {
    dispatch(changeLanguage(value))
    setLanguageDropDown(name)
    // getnavbardata(value)
    setActive(false)
    setValue(value)
    if (value === 'en') setvalue1('')
    else setvalue1(value)
    root.className = value

    var oldurl = location.pathname
    for (let i = 0; i < langCode1.length; i++) {
      oldurl = oldurl.replace(`/${langCode1[i]}`, '')
    }
    if (oldurl === '/') {
      oldurl = ''
    }
    value === 'en'
      ? navigate(`../${oldurl}`, { replace: true })
      : navigate(`../${value}${oldurl}`, { replace: true })
  }

  const getLang = () => {
    let param = window.location.href.split('/')
    let url = param[param.length - 1]
    if (langCode.includes(url)) {
      return url
    } else {
      return 'en'
    }
  }
  const getLang1 = () => {
    let param = window.location.href.split('/')
    for (let i = 0; i < param.length; i++) {
      if (langCode1.includes(param[i])) return param[i]
    }
    return ''
  }

  const toUpper = (text) => {
    return text.toUpperCase()
  }
  const handledropdown = (e) => {
    setDisplayMenu(!displayMenu)
  }
  const handlelanguagedropdown = (e) => {
    setActive(!active)
  }
  const handleloading = () => {
    let param = window.location.href.split('/')
    if (param.includes('services')) {
      let ele = document.querySelector('.webservice')

      if (ele != null) ele.classList.add('active')
      let ele2 = document.querySelector('.service-span')
      if (ele2 != null) ele2.classList.add('active')
    } else {
      let ele = document.querySelector('.webservice')
      if (ele != null) ele.classList.remove('active')
      let ele2 = document.querySelector('.service-span')
      if (ele2 != null) ele2.classList.remove('active')
    }
    if (displayMenu) setDisplayMenu(false)
    if (servicesMenu) setServicesMenu(false)
  }

  const handleservices = (url) => {
    url = url.replaceAll(/\s/g, '').toLowerCase()
    if (value1 === '' || value1 === null) navigate(`/audio/services/${url}`)
    else navigate(`/${value1}/audio/services/${url}`)
    Setwebservice(!webservice)
    setServicesMenu(!servicesMenu)
    setDisplayMenu(!displayMenu)
  }
  useEffect(() => {
    setLoader(loadingState)
    if (loadingState) {
      document.body.style.position = 'fixed'
    } else {
      document.body.style.position = ''
    }
  }, [loadingState])

  useEffect(() => {
    if (displayMenu && isMobile) {
      document.body.style.position = 'fixed'
    } else {
      document.body.style.position = ''
    }
  }, [displayMenu, isMobile])

  function handleWindowSizeChange() {
    if (window.innerWidth < 990) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return (
    <>
      {loader ? <Oldloader /> : ''}

      <>
        <nav className="navbar" style={{ zIndex: loader ? 0 : 1111 }}>
          <div className="container-fluid p-0">
            <a
              className="navbar-brand"
              onClick={handleloading}
              href={getLang1() === '' ? '/audio' : `/${getLang1()}/audio`}
            >
              <img src={'/audio/img/Side_logo.png'} alt="" />
            </a>
            <div className="navbar-collapse desktopNav" id="">
              <ul className="navbar-nav mb-2 mb-lg-0">
                <li className="nav-item nav-item_sm_mbl">
                  <NavLink
                    className="nav-link about_link"
                    aria-current="page"
                    onClick={handleloading}
                    to={{ pathname: `${value1}${clientUrl.about}` }}
                  >
                    {headerdata
                      ? toUpper(headerdata.menu_about)
                      : toUpper(staticData.menu_about)}
                  </NavLink>
                </li>
                <li
                  ref={webservicedropdownRef}
                  className="nav-item nav-item_sm_mbl nav-item_services dropdown"
                >
                  <NavLink
                    className="nav-link webservice dropdown-toggle"
                    onClick={() => {
                      handleloading(Setwebservice(!webservice))
                    }}
                    to={{ pathname: `${value1}${clientUrl.services}` }}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={
                      webservice
                        ? {
                            color: '#94DB16',
                            transform: 'translateY(-0.1rem)',
                          }
                        : {}
                    }
                  >
                    {headerdata
                      ? toUpper(headerdata.menu_services)
                      : toUpper(staticData.menu_services)}
                  </NavLink>
                </li>
                <li className="nav-item nav-item_sm_mbl">
                  <NavLink
                    className="nav-link"
                    onClick={handleloading}
                    to={{ pathname: `${value1}${clientUrl.projects}` }}
                  >
                    {headerdata
                      ? toUpper(headerdata.menu_projects)
                      : toUpper(staticData.menu_projects)}
                  </NavLink>
                </li>
                <li className="nav-item nav-item_sm_mbl">
                  <NavLink
                    className="nav-link"
                    onClick={handleloading}
                    to={{ pathname: `${value1}${clientUrl.news}` }}
                  >
                    {headerdata
                      ? toUpper(headerdata.menu_news)
                      : toUpper(staticData.menu_news)}
                  </NavLink>
                </li>
                <li className="nav-item nav-item_sm_mbl">
                  <NavLink
                    className="nav-link"
                    onClick={handleloading}
                    to={{ pathname: `${value1}${clientUrl.contactus}` }}
                  >
                    {headerdata
                      ? toUpper(headerdata.menu_contact)
                      : toUpper(staticData.menu_contact)}
                  </NavLink>
                </li>
                <li
                  ref={dropdownRef}
                  className="nav-item nav-item_lang  dropdown"
                  onClick={(e) => handlelanguagedropdown(e)}
                >
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={(e) => e.preventDefault()}
                    style={
                      active
                        ? {
                            color: '#94DB16',
                            transform: 'translateY(-0.1rem)',
                          }
                        : {}
                    }
                  >
                    {languageDropDown
                      ? toUpper(languageDropDown)
                      : languageDropDown}
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.50781 1.25391L5.25391 5.50781L1 1.25391"
                        stroke={active ? '#94DB16' : '#FEFBF7'}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                  <div
                    className="lang_dropdown"
                    style={{ display: active ? 'block' : 'none' }}
                  >
                    {headerdata ? (
                      headerdata.header_languages.map((data, index) => {
                        return (
                          <p
                            className={
                              selectedValue === data.code ? 'active' : ''
                            }
                            onClick={(e) =>
                              onlanguagechange(data.code, data.value)
                            }
                            value={data.code}
                            key={index}
                          >
                            {data.label}
                          </p>
                        )
                      })
                    ) : (
                      <>
                        {lan.map((_, index) => {
                          return (
                            <p
                              className={
                                selectedValue === _.value ? 'active' : ''
                              }
                              onClick={(e) =>
                                onlanguagechange(_.value, _.dropdownName)
                              }
                              value={_.value}
                              key={index}
                            >
                              {_.name} {_.languageName === 'English' ? '' : _.languageName}
                            </p>
                          )
                        })}
                      </>
                    )}
                  </div>
                </li>
              </ul>
            </div>

            <div
              className={
                displayMenu
                  ? 'mobile_headerright menu_opened'
                  : 'mobile_headerright'
              }
            >
              <div className="mbl_menu_icon" onClick={(e) => handledropdown(e)}>
                <img className="bar_img" src="/audio/img/hamburger.svg" alt="side" />
                <svg
                  className="close_menu"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.74857 9.06966L16.4685 16.7896L17.1756 16.0825L9.45568 8.36255L17.1189 0.699294L16.4118 -0.0078125L8.74857 7.65545L1.08751 -0.0056193L0.380403 0.701487L8.04147 8.36255L0.3237 16.0803L1.03081 16.7874L8.74857 9.06966Z"
                    fill="white"
                  ></path>
                </svg>
              </div>

              <div
                ref={mobilemenuRef}
                className="mobile_nav"
                style={{ display: displayMenu ? 'flex' : 'none' }}
              >
                <div className="top_sec">
                  <div className="mbl-nav-item">
                    <NavLink
                      className="nav-link mbl_about_link"
                      aria-current="page"
                      to={clientUrl.about}
                      onClick={(e) => setDisplayMenu(!displayMenu)}
                    >
                      {headerdata
                        ? toUpper(headerdata.menu_about)
                        : toUpper(staticData.menu_about)}
                    </NavLink>
                  </div>

                  <div
                    className={
                      servicesMenu
                        ? 'mbl-nav-item nav-item_services dropdown expanded'
                        : 'mbl-nav-item nav-item_services dropdown'
                    }
                  >
                    <span
                      className="nav-link service-span dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={(e) => {
                        e.preventDefault()

                        if (e.target.tagName.toLowerCase() === 'a') {
                          navigate(`${value1}${clientUrl.services}`)
                          setDisplayMenu(!displayMenu)

                          return
                        }

                        setServicesMenu(!servicesMenu)
                        let ele = document.querySelector('.service-span')
                        if (ele != null) {
                          if (servicesMenu === false)
                            ele.classList.add('active')
                          else ele.classList.remove('active')
                        }
                      }}
                    >
                      {headerdata
                        ? toUpper(headerdata.menu_services)
                        : toUpper(staticData.menu_services)}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 50 50"
                        width="26px"
                        height="30px"
                        id="Layer_1"
                        version="1.1"
                        viewBox="0 0 50 50"
                      >
                        <rect fill="none" height="50" width="50" />
                        <line
                          fill="none"
                          stroke={servicesMenu === true ? '#94db16' : '#fff'}
                          strokeMiterlimit="10"
                          strokeWidth="4"
                          x1="9"
                          x2="41"
                          y1="25"
                          y2="25"
                        />
                        <line
                          fill="none"
                          stroke={servicesMenu === true ? '#94db16' : '#fff'}
                          strokeMiterlimit="10"
                          strokeWidth="4"
                          x1="25"
                          x2="25"
                          y1="9"
                          y2="41"
                        />
                      </svg>
                    </span>

                    <div
                      className="service_dropdown_mobile"
                      style={{ display: servicesMenu ? 'block' : 'none' }}
                    >
                      {servicemenu
                        ? servicemenu.map((data, index) => {
                            return (
                              <p
                                style={{
                                  cursor: 'pointer',
                                  textTransform: 'uppercase',
                                }}
                                onClick={() => {
                                  handleservices(data.service_url)
                                }}
                                key={`${index}-${data.service_url}`}
                              >
                                {data.service}
                              </p>
                            )
                          })
                        : ''}
                    </div>
                  </div>

                  <div className="mbl-nav-item">
                    <NavLink
                      className="nav-link"
                      // to={clientUrl.projects}
                      to={{ pathname: `${value1}${clientUrl.projects}` }}
                      onClick={(e) => setDisplayMenu(!displayMenu)}
                    >
                      {headerdata
                        ? toUpper(headerdata.menu_projects)
                        : staticData.menu_projects}
                    </NavLink>
                  </div>
                  <div className="mbl-nav-item">
                    <NavLink
                      className="nav-link"
                      // to={clientUrl.news}
                      to={{ pathname: `${value1}${clientUrl.news}` }}
                      onClick={(e) => setDisplayMenu(!displayMenu)}
                    >
                      {headerdata
                        ? toUpper(headerdata.menu_news)
                        : toUpper(staticData.menu_news)}
                    </NavLink>
                  </div>

                  <div className="mbl-nav-item">
                    <NavLink
                      className="nav-link"
                      // to={clientUrl.contactus}
                      to={{ pathname: `${value1}${clientUrl.contactus}` }}
                      onClick={(e) => setDisplayMenu(!displayMenu)}
                    >
                      {headerdata
                        ? toUpper(headerdata.menu_contact)
                        : staticData.menu_contact}
                    </NavLink>
                  </div>
                </div>
                <div className="bottom_sec">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noreferrer"
                    href={
                      footerData
                        ? footerData?.footer_x_link
                        : 'https://twitter.com/SideGlobal'
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="15"
                      height="15"
                      viewBox="0 0 50 50"
                      style={{ fill: '#FFFFFF', marginLeft: '0rem' }}
                    >
                      <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
                    </svg>
                    {/* {footerData
                      ? footerData?.footer_social_media_links?.footer_x
                      : 'X'} */}
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <line
                          x1="4.37789"
                          y1="11.0219"
                          x2="10.9647"
                          y2="4.43515"
                          stroke="white"
                        />
                        <path
                          d="M4.15918 4.37793H11.0059V11.2247"
                          stroke="white"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noreferrer"
                    href={
                      footerData
                        ? footerData?.footer_instagram_link
                        : 'https://www.instagram.com/sideglobal/'
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="15"
                      style={{ fill: '#FFFFFF', marginLeft: '0rem' }}
                      height="15"
                      viewBox="0 0 26 26"
                    >
                      <path d="M 7.546875 0 C 3.390625 0 0 3.390625 0 7.546875 L 0 18.453125 C 0 22.609375 3.390625 26 7.546875 26 L 18.453125 26 C 22.609375 26 26 22.609375 26 18.453125 L 26 7.546875 C 26 3.390625 22.609375 0 18.453125 0 Z M 7.546875 2 L 18.453125 2 C 21.527344 2 24 4.46875 24 7.546875 L 24 18.453125 C 24 21.527344 21.53125 24 18.453125 24 L 7.546875 24 C 4.472656 24 2 21.53125 2 18.453125 L 2 7.546875 C 2 4.472656 4.46875 2 7.546875 2 Z M 20.5 4 C 19.671875 4 19 4.671875 19 5.5 C 19 6.328125 19.671875 7 20.5 7 C 21.328125 7 22 6.328125 22 5.5 C 22 4.671875 21.328125 4 20.5 4 Z M 13 6 C 9.144531 6 6 9.144531 6 13 C 6 16.855469 9.144531 20 13 20 C 16.855469 20 20 16.855469 20 13 C 20 9.144531 16.855469 6 13 6 Z M 13 8 C 15.773438 8 18 10.226563 18 13 C 18 15.773438 15.773438 18 13 18 C 10.226563 18 8 15.773438 8 13 C 8 10.226563 10.226563 8 13 8 Z"></path>
                    </svg>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="15"
                      height="15"
                      style={{ fill: '#FFFFFF', marginLeft: '0rem' }}
                      viewBox="0 0 48 48"
                    >
                      <path d="M 16.5 5 C 10.159 5 5 10.159 5 16.5 L 5 31.5 C 5 37.841 10.159 43 16.5 43 L 31.5 43 C 37.841 43 43 37.841 43 31.5 L 43 16.5 C 43 10.159 37.841 5 31.5 5 L 16.5 5 z M 34 12 C 35.105 12 36 12.895 36 14 C 36 15.104 35.105 16 34 16 C 32.895 16 32 15.104 32 14 C 32 12.895 32.895 12 34 12 z M 24 14 C 29.514 14 34 18.486 34 24 C 34 29.514 29.514 34 24 34 C 18.486 34 14 29.514 14 24 C 14 18.486 18.486 14 24 14 z M 24 17 A 7 7 0 1 0 24 31 A 7 7 0 1 0 24 17 z"></path>
                    </svg> */}
                    {/* {footerData ? footerData?.footer_instagram : 'instagram'} */}
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <line
                          x1="4.37789"
                          y1="11.0219"
                          x2="10.9647"
                          y2="4.43515"
                          stroke="white"
                        />
                        <path
                          d="M4.15918 4.37793H11.0059V11.2247"
                          stroke="white"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noreferrer"
                    href={
                      footerData
                        ? footerData?.footer_linkedin_link
                        : 'https://www.linkedin.com/company/side-audio'
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="15"
                      height="15"
                      style={{ fill: '#FFFFFF', marginLeft: '0rem' }}
                      viewBox="0 0 26 26"
                    >
                      <path d="M 21.125 0 L 4.875 0 C 2.183594 0 0 2.183594 0 4.875 L 0 21.125 C 0 23.816406 2.183594 26 4.875 26 L 21.125 26 C 23.816406 26 26 23.816406 26 21.125 L 26 4.875 C 26 2.183594 23.816406 0 21.125 0 Z M 8.039063 22.070313 L 4 22.070313 L 3.976563 9.976563 L 8.015625 9.976563 Z M 5.917969 8.394531 L 5.894531 8.394531 C 4.574219 8.394531 3.722656 7.484375 3.722656 6.351563 C 3.722656 5.191406 4.601563 4.3125 5.945313 4.3125 C 7.289063 4.3125 8.113281 5.191406 8.140625 6.351563 C 8.140625 7.484375 7.285156 8.394531 5.917969 8.394531 Z M 22.042969 22.070313 L 17.96875 22.070313 L 17.96875 15.5 C 17.96875 13.910156 17.546875 12.828125 16.125 12.828125 C 15.039063 12.828125 14.453125 13.558594 14.171875 14.265625 C 14.066406 14.519531 14.039063 14.867188 14.039063 15.222656 L 14.039063 22.070313 L 9.945313 22.070313 L 9.921875 9.976563 L 14.015625 9.976563 L 14.039063 11.683594 C 14.5625 10.875 15.433594 9.730469 17.519531 9.730469 C 20.105469 9.730469 22.039063 11.417969 22.039063 15.046875 L 22.039063 22.070313 Z"></path>
                    </svg>
                    {/* {footerData ? footerData?.footer_linkedin : 'Linkedin'} */}
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <line
                          x1="4.37789"
                          y1="11.0219"
                          x2="10.9647"
                          y2="4.43515"
                          stroke="white"
                        />
                        <path
                          d="M4.15918 4.37793H11.0059V11.2247"
                          stroke="white"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noreferrer"
                    href={
                      footerData
                        ? footerData?.footer_youtube_link
                        : 'https://www.youtube.com/@sideglobal2237'
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="20"
                      height="20"
                      style={{ fill: '#FFFFFF', marginLeft: '-0.15rem' }}
                      viewBox="0 0 50 50"
                    >
                      <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
                    </svg>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="15"
                      height="15"
                      style={{ fill: '#FFFFFF', marginLeft: '0rem' }}
                      viewBox="0 0 50 50"
                    >
                      <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
                    </svg> */}
                    {/* {'Youtube'} */}
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <line
                          x1="4.37789"
                          y1="11.0219"
                          x2="10.9647"
                          y2="4.43515"
                          stroke="white"
                        />
                        <path
                          d="M4.15918 4.37793H11.0059V11.2247"
                          stroke="white"
                        />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>
    </>
  )
}

export default Navbar
